import _ from "lodash";
import React from "react";
import "./premiumpay.scoped.css";

const CustomerCardList = ({
  setSelectedCard,
  selectedCard,
  cardList,
  setIsAddCardShow,
  isAddCardShow,
}) => {
  return (
    <>
      {_.isArray(cardList) &&
        cardList.map((item) => {
          return (
            <React.Fragment key={item?.id}>
              <div
                className="col"
                onClick={() => {
                  setSelectedCard(item?.id);
                }}
              >
                <div
                  className={`card text-center h-100 py-5 ${
                    selectedCard === item?.id ? "active-card" : ""
                  }`}
                >
                  <form className="send">
                    <div style={{ textAlign: "left", margin: "-43px 68px" }}>
                      <img
                        src={item?.logo}
                        width={150}
                        height={50}
                        style={{ margin: "14px -12px 22px -35px" }}
                        alt={item?.cardType}
                      />
                      <span className="span1">
                        {_.capitalize(item?.card?.brand)} ***{" "}
                        {item?.card?.last4}
                      </span>
                    </div>
                  </form>
                </div>
              </div>
              <br />
            </React.Fragment>
          );
        })}
      <div
        className="col"
        onClick={() => {
          setSelectedCard(!isAddCardShow ? "AddCard" : "");
        }}
      >
        <div
          className={`card text-center h-100 py-5 ${
            selectedCard === "AddCard" ? "active-card" : ""
          }`}
          onClick={() => {
            setIsAddCardShow(!isAddCardShow);
          }}
        >
          <form className="send">
            <div style={{ textAlign: "left", margin: "-43px 68px" }}>
              <img
                src="/assets/customerImg/card1.svg"
                width={150}
                height={50}
                alt="card1"
                style={{ margin: "14px -12px 22px -35px" }}
              />
              <span className="span1">Add credit or debit card</span>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default CustomerCardList;
