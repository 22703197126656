import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { wheelerType } from "../../../../helpers/helper";
import {
  addVehicle,
  deleteVehicle,
  editVehicle,
  get2wheelerMake,
  get2wheelerModel,
  get4wheelerMake,
  get4wheelerModel,
} from "../../../../services/all.service";
import Form from "../../../common/Form";
import FormError from "../../../common/Form/FormError";
import CreateUserAccountFormSchema from "../../../common/Form/Schema/CreateUserAccountFormSchema";
import { createValidationObj } from "../../../common/Form/Validation/validation.const";
import MakeModel from "./MakeModel";
import "./makeVehicle.scoped.css";
const brandList = [
  { value: "Bmw", id: 1 },
  { value: "Audi", id: 2 },
  { value: "Tata", id: 3 },
  { value: "Tesla", id: 4 },
  { value: "Hyundai", id: 5 },
  { value: "Bmw", id: 1 },
  { value: "Audi", id: 2 },
  { value: "Tata", id: 3 },
  { value: "Tesla", id: 4 },
  { value: "Hyundai", id: 5 },
];

const uniqueId = () => {
  return "id" + new Date().getTime();
};

const { FourWheeler } = wheelerType;
const MakeVehicle = () => {
  const [addBrandValue, setAddBrandValue] = useState("");
  const [isAnyBrandSelected, setIsAnyBrandSelected] = useState(false);
  const [vehicleList, setVehicleList] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [refreshVehicleList, setRefreshVehicleList] = useState(false);
  const [currentSelectedItem, setCurrentSelectedItem] = useState({});

  const currentPageParam = useParams();
  const handleSelectedItem = (item) => {
    console.log(item, "itemId");
    setIsAnyBrandSelected(true);
    handleAddBrandInput(item?.mfl_title);
    setCurrentSelectedItem(item);
  };

  const handleCloseEditSelection = () => {
    setIsAnyBrandSelected(false);
    setAddBrandValue("");
  };

  const handleAddBrandInput = (value) => {
    setAddBrandValue(value);
  };

  const handleBrandSubmit = async (type) => {
    if (addBrandValue) {
      try {
        const vehicleType =
          currentPageParam.wheelerType === FourWheeler ? 1 : 0;
        let apiResponse = {};
        if (type === "Edit" || type === "Delete") {
          if (isAnyBrandSelected) {
            if (type === "Edit") {
              apiResponse = await editVehicle(
                currentSelectedItem.mfl_id,
                addBrandValue
              );
            } else if (type === "Delete") {
              apiResponse = await deleteVehicle(currentSelectedItem.mfl_id);
            }
          } else {
            toast.error("Something went wrong!");
          }
        } else {
          apiResponse = await addVehicle(addBrandValue, vehicleType);
        }

        if (apiResponse.status === 1) {
          setIsAnyBrandSelected(false);
          setRefreshVehicleList(uniqueId());
          setAddBrandValue("");
        }
      } catch (error) {}
    } else {
      toast.error("Please enter vehicle name");
    }

    console.log(addBrandValue, "addBrandValue");
  };
  const getVehcileList = async () => {
    const isFourWheeler = currentPageParam.wheelerType === FourWheeler ? 1 : 0;
    let getService;
    if (isFourWheeler) {
      getService = get4wheelerMake;
    } else {
      getService = get2wheelerMake;
    }
    const res = await getService();
    if (res?.status === 1) {
      setVehicleList(res?.data);
    }
  };

  const getModelList = async () => {
    const isFourWheeler = currentPageParam.wheelerType === FourWheeler ? 1 : 0;
    let getService;
    if (isFourWheeler) {
      getService = get4wheelerModel;
    } else {
      getService = get2wheelerModel;
    }
    const res = await getService();
    if (res?.status === 1) {
      setModelList(res?.data);
    }
  };

  const showError = () => {
    return (
      <>
        &nbsp;&nbsp;
        <span>
          <FormError msg={"This field is required"} />
        </span>
      </>
    );
  };

  useEffect(() => {
    getVehcileList();
    getModelList();
  }, [refreshVehicleList]);

  return (
    <>
      <div>
        <div className="row">
          <div className="col-lg-7">
            <div id="div2" className="card mb-4">
              <div id="div3" className="card-body1">
                {vehicleList.map((item) => {
                  return (
                    <div
                      className="item ml-2"
                      onClick={() => {
                        handleSelectedItem(item);
                      }}
                    >
                      {item?.mfl_title}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        {isAnyBrandSelected ? (
          <div>
            <span className="text22">Edit Brand:&nbsp;</span>{" "}
            <span className="">
              <input
                id="input1"
                type="text"
                placeholder="Make"
                value={addBrandValue}
                onChange={(e) => {
                  handleAddBrandInput(e.target.value);
                }}
              />
            </span>
            &nbsp;&nbsp;
            <span>
              <button
                id="btn11"
                onClick={() => {
                  handleBrandSubmit("Edit");
                }}
                className="ml-2 btn bg-warning"
              >
                Edit
              </button>
            </span>
            <span>
              <button
                id="btn11"
                onClick={() => {
                  handleBrandSubmit("Delete");
                }}
                className=" btn ml-2 bg-danger"
              >
                Delete
              </button>
            </span>
            <span>
              <button
                id="btn11"
                className="btn ml-2 bg-danger"
                onClick={handleCloseEditSelection}
              >
                <i className="fas fa-times" />
              </button>
            </span>
          </div>
        ) : (
          <div>
            <span className="text22">Add Make:&nbsp;</span>{" "}
            <span>
              <input
                id="input1"
                type="text"
                placeholder="Make"
                value={addBrandValue}
                onChange={(e) => {
                  handleAddBrandInput(e.target.value);
                }}
              />
            </span>
            &nbsp;&nbsp;
            <span>
              <button id="btn11" className="btn" onClick={handleBrandSubmit}>
                Add
              </button>
            </span>
          </div>
        )}
        <MakeModel />
      </div>
    </>
  );
};

export default MakeVehicle;
