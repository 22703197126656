import React from "react";
import CustomerHoc from "../../../Hoc/CustomerHoc";
import "./register.scoped.css";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import FormError from "../../common/Form/FormError";
import { passwordRegExp } from "../../common/Form/Validation/validation.const";

const schema = yup.object().shape({
  first_name: yup
    .string()
    .required()
    .label("First Name"),
  last_name: yup
    .string()
    .required()
    .label("Last Name"),
  dob: yup
    .string()
    .required()
    .label("Date of birth"),
  gender: yup
    .string()
    .required()
    .label("Gender"),
  email: yup
    .string()
    .required()
    .email()
    .label("Email"),
  password: yup
    .string()
    .required()
    .matches(
      passwordRegExp,
      "Password should be 8 character, one capital letters, one special symbol and one number"
    )
    .label("Password"),
});

const CustomerRegister = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleUpdate = (data) => {
    console.log(data, "data");
  };
  return (
    <div className="container">
      <div className="card py-5">
        <div className="top" />
        <div className="image1">
          <img
            className="img1"
            src="/assets/customerImg/Default Profile Picture.svg"
          />
        </div>
        <form onSubmit={handleSubmit(handleUpdate)}>
          <div className="wrapper px-3">
            <div className="title">First Name</div>
            <div className="input-data">
              <input
                type="text"
                placeholder="Dew Betty"
                {...register("first_name")}
              />
              <FormError msg={errors.first_name?.message} />
            </div>
            <div className="title">Last Name</div>
            <div className="input-data">
              <input
                type="text"
                placeholder="Dew Betty"
                {...register("last_name")}
              />
              <FormError msg={errors.last_name?.message} />
            </div>
            <div className="title">Date of birth</div>
            <div className="input-data">
              <input type="date" placeholder="Dew Betty" {...register("dob")} />
              <FormError msg={errors.dob?.message} />
            </div>
            <div className="title">Gender</div>
            <div className="input-data">
              <select {...register("gender")} style={{ background: "white" }}>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>

              <FormError msg={errors.gender?.message} />
            </div>
            <div className="title">Email</div>
            <div className="input-data">
              <input
                type="text"
                placeholder="dew@gmail.com"
                {...register("email")}
              />
              <FormError msg={errors.email?.message} />
            </div>

            <div className="title">Password</div>
            <div className="input-data">
              <input type="password" {...register("password")} />
              <FormError msg={errors.password?.message} />
            </div>
            <div className="title">Confirm Password</div>
            <div className="input-data">
              <input type="password" {...register("confirm_password")} />
              <FormError msg={errors.confirm_password?.message} />
            </div>
            <div className="">
              <button id="updateFormBtn" data-v-70f943e5="">
                Register
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CustomerHoc(CustomerRegister);
