import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getAllInvoices, getAllTrips } from "../../../services/all.service";
import { appendScript } from "../account/viewAccount";
import AdminSidebar from "../AdminSidebar";
import "./records.scoped.css";

const Records = ({ listData = ["test"] }) => {
  const [invoiceData, setInvoiceData] = useState([]);

  const getInvoiceList = async () => {
    try {
      // const invoiceData = await getAllInvoices();
      const tripData = await getAllTrips();
      console.log(tripData.data, "tripData");
      if (tripData.status === 1) {
        setInvoiceData(tripData?.data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    getInvoiceList();
  }, []);

  useEffect(() => {
    if (invoiceData.length > 0) {
      appendScript(
        "https://cdn.datatables.net/1.10.9/js/jquery.dataTables.min.js"
      );
      appendScript("/assets/js/record3.js");
    }
  }, [invoiceData]);
  console.log(invoiceData, "invoiceData");
  return (
    <AdminSidebar
      title={"Records"}
      childrenContainerClass="container-fluid flex-grow-1 container-p-y"
    >
      <div className="container">
        <div className="card-deck mb-3 text-center">
          <div className="card mb-4 box-shadow">
            <div className="card-body overflow-auto">
              <div id="div1">
                <div id="div2"> Service Invoice</div>
              </div>
              <br />
              <br />
              <table id="example" cellspacing="0" width="100%">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Trip ID</th>
                    <th>Log-in details</th>
                    <th>Name/Contact</th>
                    <th>Make/Model</th>
                    <th>Service Type</th>
                    <th>Service Provider</th>
                    <th>Driver</th>
                    <th>Price</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {invoiceData.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td />
                        <td>#{item?.trip_id}</td>
                        <td>{item?.user_email}</td>
                        <td>{`${item?.user_first_name} ${
                          item?.user_last_name
                        } /${item?.user_mobile}`}</td>
                        <td>{`${item?.vehicle_make}/${
                          item?.vehicle_model
                        }`}</td>
                        <td>{item?.service_type}</td>
                        <td>{item?.service_provider_name}</td>
                        <td>{item?.driver_name}</td>
                        <td>$ {parseInt(item?.trip_ammount).toFixed(2)}</td>
                        <td>
                          {} {moment(item?.trip_date).format("L")}
                        </td>
                      </tr>
                    );
                  })}

                  {/*

                   <tr>
                    <td />
                    <td>#082-260521</td>
                    <td>tcruise93@gmail.com</td>
                    <td>Tom cruise</td>
                    <td>Mazda</td>
                    <td>Towing</td>
                    <td />
                    <td />
                    <td>365 Day/s</td>
                  </tr>
                  <tr>
                    <td />
                    <td>#082-260521</td>
                    <td>tcruise93@gmail.com</td>
                    <td>Tom cruise</td>
                    <td>Mazda</td>
                    <td>Towing</td>
                    <td />
                    <td />
                    <td>365 Day/s</td>
                  </tr> */}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </AdminSidebar>
  );
};

export default Records;
