import React from "react";
import { Link } from "react-router-dom";
import { wheelerType } from "../../../helpers/helper";
import AdminSidebar from "../AdminSidebar";
import "./makeModel.scoped.css";

const MakeModel = () => {
  const { FourWheeler, TwoWheeler } = wheelerType;
  return (
    <AdminSidebar
      title="Make/Model"
      outerChildren={
        <div id="div1">
          <div class="text22">Select Vehicle Type</div>
        </div>
      }
    >
      <br />
      <div className="container">
        <div className="card-deck mb-3 text-center">
          <div className="card mb-4 box-shadow">
            <div className="card-body1" id="div2">
              <div className="card-deck mb-3 text-center">
                <div className="card mb-4 card11">
                  <div>
                    <br />
                    <br />
                    <img src="/assets/img/car1.svg" alt="car1" />
                    <Link to={`make-model/${FourWheeler}`}>
                      <h4>4-Wheelers</h4>
                    </Link>
                  </div>
                </div>
                <div className="card mb-4 card22">
                  <div>
                    {" "}
                    <br />
                    <br />
                    <img src="/assets/img/bike1.svg" alt="bike1" />
                    <br />
                    <br />
                    <br />
                    <Link to={`make-model/${TwoWheeler}`}>
                      <h4>2-Wheelers</h4>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminSidebar>
  );
};

export default MakeModel;
