import React, { useEffect, useState } from "react";
import AdminSidebar from "../../AdminSidebar";
import "./pricingPlan.scoped.css";
import { useParams } from "react-router-dom";
import {
  createBreadCrumb,
  pricingPlan,
  slugToTitle,
} from "../../../../helpers/helper";
import moment from "moment";
import {
  getPricingPlan,
  getServicePlan,
  updatePricingPlan,
  updateServicePlan,
} from "../../../../services/all.service";
import { History } from "../../../../helpers/history";

const PricingPlan = () => {
  const { pricingType } = useParams();
  // const [data, setData] = useState(dummyData);
  const [tableData, setTableData] = useState([]);
  // const [updatedId, setUpdatedId] = useState([]);
  // const [updateValue, setUpdatedValue] = useState([]);

  const updatePriceRow = (inputValue, rowItem) => {
    const copyData = [...tableData];
    const finalData = copyData.filter((item) => {
      if (item?.id === rowItem?.id) {
        item.value = parseInt(inputValue);
      }
      return true;
    });
    // setUpdatedId([...updatedId, rowItem.id]);
    // setUpdatedValue([...updateValue, rowItem.value]);
    setTableData(finalData);
  };

  const formatTableData = (data) => {
    let returnData = [];
    if (pricingType === pricingPlan.PremiumPlan) {
      returnData = data.map((item) => {
        const returnObj = {};
        returnObj["id"] = item?.priceDetails?.id;
        returnObj["title"] = item?.subscription_title;
        returnObj["value"] = item?.subscription_plan_price;
        returnObj["defaultValue"] = item?.subscription_plan_price;
        returnObj["productId"] = item?.subscription_plan_id;
        return returnObj;
      });
    } else {
      returnData = data.map((item) => {
        const returnObj = {};
        returnObj["id"] = item?.towing_id;
        returnObj["title"] = item?.towing_name;
        returnObj["value"] = item?.towing_base_price;
        returnObj["defaultValue"] = item?.towing_base_price;
        return returnObj;
      });
    }

    return returnData;
  };

  const fetchTableData = async () => {
    try {
      const res =
        pricingType === pricingPlan.PremiumPlan
          ? await getPricingPlan()
          : await getServicePlan();
      if (res?.status === 1) {
        const tblData = formatTableData(res?.data);
        setTableData(tblData);
      }
    } catch (error) {}
  };

  const handleUpdateSubmit = async () => {
    const updatedData = [];
    const updatedId = tableData.map((item) => item.id);
    const updatedValue = tableData.map((item) => item.value);
    tableData.map((item) => {
      if (item?.value !== item?.defaultValue) {
        updatedData.push(item);
      }
    });

    let reqData = {};
    let currentUpdatedService;
    if (pricingType === pricingPlan.PremiumPlan) {
      reqData = {
        updatedRow: updatedData,
      };
      currentUpdatedService = updatePricingPlan;
    } else {
      reqData = {
        service_charge_id_array: updatedId,
        service_charge_price_array: updatedValue,
      };
      // updateServicePlan(updatedId, updatedValue);
      currentUpdatedService = updateServicePlan;
    }
    console.log(reqData, "reqData");
    const res = await currentUpdatedService(reqData);
    if (res?.status === 1) {
      History.push("/dashboard/pricing");
    }
  };

  console.log(tableData, "tableData");

  useEffect(() => {
    fetchTableData();
  }, []);
  return (
    <AdminSidebar
      title={createBreadCrumb({
        currentPageTitle: slugToTitle(pricingType),
        title: "Pricing",
        href: "/dashboard/pricing",
      })}
    >
      <div className="container">
        <div className="card-deck mb-3 text-center">
          <div className="card mb-4 box-shadow">
            <div className="card-body">
              <div id="div1">
                <div className="text22">
                  {`${slugToTitle(pricingType)}`}
                  <span id="span1">
                    {pricingType === pricingPlan.PremiumPlan ? (
                      <img src="/assets/img/diamond 2.png" alt="premium" />
                    ) : (
                      <img src="/assets/img/towcar.png" alt="premium" />
                    )}
                  </span>
                </div>
              </div>
              <br />
              <table>
                <thead>
                  <tr>
                    <th id="th1">Plans</th>
                    <th>
                      <span id="span2">$</span>/Vehicle (
                      <span id="span3">Current</span>)
                    </th>
                    <th>
                      <span id="span4">$</span>/Vehicle (
                      <span id="span5">NEW</span>)
                    </th>
                  </tr>
                </thead>
                <thead />
                <tbody>
                  {tableData.map((item) => {
                    return (
                      <tr>
                        <td>{item?.title}</td>
                        <td>{item?.defaultValue}</td>
                        <td>
                          <input
                            className="priceInput"
                            type="number"
                            defaultValue={item?.value}
                            onChange={(e) => {
                              updatePriceRow(e.target.value, item);
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <br />
              <br />
              <div>
                <button id="nextbtn" onClick={handleUpdateSubmit}>
                  Update Prices
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminSidebar>
  );
};

export default PricingPlan;
