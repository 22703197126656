import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { pricingPlan, slugToTitle } from "../../../helpers/helper";
import { getPricingPlan, getServicePlan } from "../../../services/all.service";
import AdminSidebar from "../AdminSidebar";
import "./pricing.scoped.css";

const Pricing = () => {
  const { PremiumPlan, ServicePlan } = pricingPlan;
  const [pricingPlanData, setPricingPlanData] = useState([]);
  const [servicePlanData, setServicePlanData] = useState([]);
  const fetchPricingPlan = async () => {
    try {
      const res = await getPricingPlan();
      if (res?.status === 1) {
        setPricingPlanData(res?.data);
      }
    } catch (error) {}
  };

  const fetchServicePlan = async () => {
    try {
      const res = await getServicePlan();
      if (res?.status === 1) {
        setServicePlanData(res?.data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    fetchPricingPlan();
    fetchServicePlan();
  }, []);
  return (
    <AdminSidebar title={`Pricing`}>
      <div className="container">
        <div className="card-deck mb-3 text-center">
          <div className="card mb-4 box-shadow">
            <div className="card-body">
              <div className="displayflex">
                <h5>{"Pricing"}</h5>
                <Link to={`/dashboard/pricing/${PremiumPlan}`}>
                  <h6 id="h6">Adjust Pricing</h6>
                </Link>
              </div>
              <br />
              <br />
              <div className="displayflex">
                <div id="div1">Plans</div>
                <div>
                  <span id="span1">$</span>/Vehicle
                </div>
              </div>
              <hr />
              {pricingPlanData.map((item) => (
                <React.Fragment key={item?.subscription_plan_id}>
                  <div className="displayflex">
                    <div>{item?.subscription_title}</div>
                    <div>{item?.subscription_plan_price}</div>
                  </div>
                  <hr />
                </React.Fragment>
              ))}
            </div>
          </div>
          <div className="card mb-4 box-shadow">
            <div className="card-body">
              <div className="displayflex">
                <h5>Service Charges</h5>
                <Link to={`/dashboard/pricing/${ServicePlan}`}>
                  <h6 id="h6">Adjust Pricing</h6>
                </Link>
                {/* <h6 style="color: #765afb;">Adjust Pricing</h6> */}
              </div>
              <br />
              <br />
              <div className="displayflex">
                <div id="div1">Changes</div>
                <div>
                  <span id="span1">$</span>/Service
                </div>
              </div>
              <hr />
              {servicePlanData.map((item) => (
                <React.Fragment key={item?.towing_id}>
                  <div className="displayflex">
                    <div>{item?.towing_name}</div>
                    <div>{item?.towing_base_price}</div>
                  </div>
                  <hr />
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
    </AdminSidebar>
  );
};

export default Pricing;
