import React, { useEffect, useState } from "react";
import CustomerHoc from "../../../../Hoc/CustomerHoc";
import "./addvehicle.scoped.css";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import FormError from "../../../common/Form/FormError";
import {
  get2wheelerMake,
  get2wheelerModel,
  get4wheelerMake,
  get4wheelerModel,
} from "../../../../services/all.service";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../../../redux/reducers/LoaderSlice";
import { addVehicleRedux } from "../../../../redux/reducers/CustomerVehicleSlice";
import { History } from "../../../../helpers/history";
import { customerVehicleExist } from "../../../../services/customer.service";
import { checkVehicleTypeUsingVehicleName } from "../../../../helpers/helper";
import { toast } from "react-toastify";

const schema = yup.object().shape({
  vehicle_name: yup
    .string()
    .required()
    .label("Vehicle Name"),
  vehicle_make: yup
    .string()
    .required()
    .label("Vehicle make"),
  vehicle_model: yup
    .string()
    .required()
    .label("Vehicle model"),
});

const CustomerProfileEdit = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields, touchedFields },
    reset,
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [modelList, setModelList] = useState([]);
  const [makeList, setMakeList] = useState([]);
  const dispatch = useDispatch();

  const handleUpdate = async (data) => {
    try {
      const response = await customerVehicleExist(data?.vehicle_name);
      if (response?.is_exists === 0) {
        dispatch(addVehicleRedux(data));
        History.push("/customer/subscription/plan");
      }
    } catch (error) {}
  };

  // const getModelList = async (vehicleType) => {
  //   try {
  //     let getService;
  //     if (vehicleType === 2) {
  //       getService = get2wheelerModel;
  //     } else {
  //       getService = get4wheelerModel;
  //     }

  //     const getModel = await getService();
  //     if (getModel?.data) {
  //       setModelList(getModel?.data);
  //     }
  //     console.log(getModel, "getModel");
  //     return getModel;
  //   } catch (error) {}
  // };

  // const getMakeList = async (vehicleType) => {
  //   try {
  //     let getService;
  //     if (vehicleType === 2) {
  //       getService = get2wheelerMake;
  //     } else {
  //       getService = get4wheelerMake;
  //     }

  //     const getMake = await getService();
  //     if (getMake?.data) {
  //       setMakeList(getMake?.data);
  //     }
  //     return getMake;
  //   } catch (error) {}
  // };

  // const handleVehicleOnChange = async (vehicleType) => {
  //   dispatch(showLoader());
  //   const vehicleId = parseInt(vehicleType);
  //   const model = await getModelList(vehicleId);
  //   const make = await getMakeList(vehicleId);
  //   if (model?.status === 1 && make?.status === 1) {
  //     dispatch(hideLoader());
  //   }
  // };

  // const handleVehicleType = () => {
  //   const { vehicle_name } = getValues();
  //   const vehicleTypeId = checkVehicleTypeUsingVehicleName(vehicle_name);
  //   if (vehicleTypeId) {
  //     handleVehicleOnChange(vehicleTypeId);
  //   } else {
  //     toast.error("Vehicle number not valid!");
  //   }
  // };

  const { onBlur, ...restVehicle } = register("vehicle_name");
  const {  ...restVehicleMake } = register("vehicle_make");
  const {  ...restVehicleModel } = register("vehicle_model");
  return (

    
    
    <div className="container">
     
        <form onSubmit={handleSubmit(handleUpdate)}>
          <div className="wrapper px-3">
            <div className="image1">
              <div className="main">Add Vehicle</div>
              <h5 className="vehicleInfo text-center" style={{color:"coral"}}>1.  Vehicle Information</h5>
            </div>

            <form>
            <div className="title">
            <div className="input-data">
              <input
                type="text"
                placeholder="Vehicle Number"
                onBlur={(e) => {
                  onBlur(e);
                 // handleVehicleType();
                }}
                {...restVehicle}
              />
              <FormError msg={errors.vehicle_name?.message} />
            </div>
            </div>
            </form>
            {/*<div className="title">Select vehicle type</div>

            <div className="input-data">
              <select
                onChange={(e) => {
                  handleVehicleOnChange(e.target.value);
                }}
                className="form-control"
                defaultValue="0"
              >
                <option value="0">Select vehicle type</option>
                <option value="2">2 Wheeler</option>
                <option value="4">4 Wheeler</option>
              </select>
              <FormError msg={errors.email?.message} />
            </div> */}
          <form>
          <div className="title">
            <div className="input-data">
              {/* <select
                {...register("vehicle_make")}
                className="form-control "
                defaultValue="0"
              >
                <option disabled>Select make</option>
                {makeList.map((item) => {
                  return ()
                    <option key={item?.mfl_id} value={item?.mfl_title}>
                      {item?.mfl_title}
                    </option>
                  );
                })}
              </select> */}
              <input
                type="text"
                placeholder="Vehicle Make"
                onBlur={(e) => {
                  onBlur(e);
               
                }}
                {...restVehicleMake}
              />
              <FormError msg={errors.vehicle_make?.message} />
            </div>
            </div>
            </form>

            <form>
            <div className="title">
            <div className="input-data">
              {/* <select
                {...register("vehicle_model")}
                className="form-control"
                defaultValue="0"
              >
                <option disabled>Select Model</option>
                {modelList.map((item) => {
                  return (
                    <option key={item?.mfl_id} value={item?.mfl_title}>
                      {item?.mfl_title}
                    </option>
                  );
                })}
              </select> */}
              <input
                type="text"
                placeholder="Vehicle Model"
                onBlur={(e) => {
                 onBlur(e);
               
                }}
                {...restVehicleModel}
              />
              <FormError msg={errors.vehicle_model?.message} />
            </div>
            </div>
            </form>
            
            <div className="">
              <button id="updateFormBtn" data-v-70f943e5="">
                Next
              </button>
            </div>
          </div>
          
        </form>
      </div>


    
  );
};

export default CustomerHoc(CustomerProfileEdit);
