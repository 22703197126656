import { accountType } from "../helpers/helper";
import { GET, POST, Upload } from "./controller.service";

export const loginService = (data) => {
  return POST("/superusers/signin", data);
};

export const accountCountCards = () => {
  return GET("/superusers/getUsersCount");
};

export const accountListService = (type) => {
  const { FreeUser, PremiumUser } = accountType;
  let api;
  switch (type) {
    case FreeUser:
      api = "/superusers/geFreeuserList";
      break;
    case PremiumUser:
      api = "/superusers/getPremiumuserList";
      break;
    default:
      api = "/superusers/getServiceProviderList";
      break;
  }
  return GET(api);
};

export const userByUserIdService = (userId, is_premium_value) => {
  
  return GET("/superusers/getUserDetailsById", {
    user_id: userId,
    ...(is_premium_value ? { is_premium_value: 1 } : {}),
  });
};

export const deleteuser = (userId, is_premium_value) => {
  return GET("/superusers/deleteUser", {
    user_id: userId,
    ...(is_premium_value ? { is_premium_value: 1 } : {}),
  });
}


export const getVehicleSubscriptionDetailsService = (vehicleId) => {
  return GET("/payment/get-vehicle-information", {
    vehicle_number: vehicleId,
  });
};

export const uploadService = (file) => {
  return Upload("/superusers/filesupload", {
    tripFile: file,
    attachment_id: 0,
  });
};

export const dealsuploadService = (file) => {
  return Upload("/superusers/dealsfilesupload", {
    tripFile: file,
    attachment_id: 0,
  });
};

export const banneruploadService = (file) => {
  return Upload("/superusers/bannerfilesupload", {
    tripFile: file,
    attachment_id: 0,
  });
};

export const addAccountService = (data) => {
  return Upload("/superusers/createPartner", data);
};

export const getPricingPlan = (data) => {
  return GET("/superusers/getSubscriptionPlan");
};

export const getServicePlan = (data) => {
  return GET("/superusers/getServiceCharge");
};

export const updatePricingPlan = (data) => {
  return POST("/superusers/updateSubscribePlanPrice", data);
};

export const updateServicePlan = (data) => {
  return POST("/superusers/updateServiceChargePrice", data);
};

export const addVehicle = (make_name, vehicle_type) => {
  return POST("/superusers/addMake", {
    make_name,
    vehicle_type: vehicle_type.toString(),
  });
};

export const get4wheelerMake = () => {
  return GET("/superusers/get4wheelerMake");
};

export const get2wheelerMake = () => {
  return GET("/superusers/get2wheelerMake");
};

export const get4wheelerModel = () => {
  return GET("/superusers/get4wheelerModel");
};

export const get2wheelerModel = () => {
  return GET("superusers/get2wheelerModel");
};

export const editVehicle = (make_id, make_title) => {
  return POST("/superusers/updateMake", {
    make_id,
    make_title,
  });
};

export const deleteVehicle = (make_id) => {
  return POST("/superusers/deleteMake", {
    make_id,
  });
};

export const addModel = (model_name, vehicle_type) => {
  return POST("/superusers/addModel", {
    model_name,
    vehicle_type: vehicle_type.toString(),
  });
};

export const editModel = (model_id, model_title) => {
  return POST("/superusers/updateModel", {
    model_id,
    model_title,
  });
};

export const deleteModel = (model_id) => {
  return POST("/superusers/deleteModel", {
    model_id,
  });
};

export const getAllInvoices = () => {
  return GET("/payment/adminGetAllInvoice");
};

export const forgotPasswordService = (email_address) => {
  return POST("/superusers/forgotPassword", { email_address });
};

export const resetPasswordService = (data) => {
  return POST("/superusers/resetPassword", data);
};

export const getAllTrips = () => {
  return GET("/superusers/getTripRecordList");
};

//new upadates
export const EditGetDeals = (data) => {
  return POST("/superusers/get_deals_by_id",data);
};
export const EditDeals = (data) => {
  return POST("/superusers/update_deals",data);
};
export const UpdateDealsPreview = (data) => {
  return POST("/superusers/update_deals_for_preview",data);
};



//Banners or Carousel
export const getAllBanner = () => {
  return GET("superusers/get_all_banner");
}
export const DeleteCarousel = (data) => {
  return POST("superusers/delete_banner",data);
}
export const ActiveCarousel = (data) => {
  return POST("superusers/active_banner",data);
}
export const InactiveCarousel = (data) => {
  return POST("superusers/inactive_banner",data);
}
export const CheckEditBannerName= (data) => {
  return POST("/superusers/check_banner_name_while_editing",data);
};



export const getAllDeals = () => {
  return GET("superusers/get_all_deals");
}
export const DeleteDeals = (data) => {
  return POST("superusers/delete_deals",data);
}

export const ActiveDeals = (data) => {
  return POST("superusers/active_deals",data);
}
export const InactiveDeals = (data) => {
  return POST("/superusers/inactive_deals",data);
}
export const CheckDealName = (data) => {
  return POST("/superusers/check_deals_name",data);
}
export const CheckEditDealName = (data) => {
  return POST("/superusers/check_deals_name_while_editing",data);
}


export const DealPublish = (data) => {
  return POST("superusers/publish_deals",data);
}
export const EditedDealPublish = (data) => {
  return POST("superusers/update_deals_on_preview",data);
}

export const EditPreview = (data) => {
  return POST("superusers/get_deals_by_id_edit_preview",data);
}

export const deleteUser = (id) => {
  return GET(`superusers/deleteuser?user_id=${id}`);
}