import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getUserIdFromLocal } from "../../../../context/AppGlobalContext";
import { redirectWhenCustomerVehicleNotExist } from "../../../../helpers/helper";
import { History } from "../../../../helpers/history";
import { centToDollar } from "../../../../helpers/stripe";
import CustomerHoc from "../../../../Hoc/CustomerHoc";
import { tempLoggedOutUser } from "../../../../redux/reducers/TempUserSlice";
import { loggedInUser } from "../../../../redux/reducers/UserSlice";
import {
  addCustomerVehicle,
  customerUpdateMembershipStatus,
  getInvoiceByInvId,
  getSubscriptionData,
  savedOrderInvoice,
  saveInvoiceData,
} from "../../../../services/customer.service";
import { MainLoader } from "../../../common";
import "../style.scoped.css";
import "../summaryStyle.scoped.css";

const CustomerPaidSummary = () => {
  const param = useParams();
  // const [invoiceId, setInvoiceId] = useState(param?.invoiceId);
  const user = useSelector((state) => state.user.data);
  const tempUser = useSelector((state) => state.tempUser.data);
  const customerVehicle = useSelector((state) => state.customerVehicle.data);
  const dispatch = useDispatch();
  const [invoiceData, setInvoiceData] = useState({
    invoice: {},
    productDetails: {},
  });

  console.log(customerVehicle, "customerVehicle");

  redirectWhenCustomerVehicleNotExist(customerVehicle);

  const handleSaveUserVehicle = async ({ planStartDate, planEndDate }) => {
    try {
      const reqData = {
        user_id: user?.user_id,
        make: customerVehicle?.vehicle_make,
        model: customerVehicle?.vehicle_model,
        number_plate: customerVehicle?.vehicle_name,
        subscribe_type: 1,
        planStartDate,
        planEndDate,
      };
      const vehicle = await addCustomerVehicle(reqData);
      return vehicle?.insertedId;
    } catch (error) {}
  };

  const saveInvoiceDetails = async (invoice, productDetails, vehicleId) => {
    try {
      const insertInvoiceInDbReq = {
        mfl_user_id: user?.user_id,
        mfl_user_name: invoice?.customer_name,
        mfl_stripe_invoice_id: invoice?.id,
        mfl_stripe_plan_id: productDetails?.id,
        mfl_stripe_plan_price_id: productDetails?.default_price,
        mfl_payment_amount: centToDollar(invoice?.amount_paid),
        // mfl_cuppon_details:"",
        // mfl_cuppon_name:"",
        mfl_payment_type: invoice?.payment_method?.type,
        mfl_order_id: invoice?.number,
        mfl_card_details: invoice?.payment_method?.card?.last4,
        mfl_user_vehicle_number: customerVehicle?.vehicle_name,
        mfl_is_premium: 1,
        mfl_user_vehicle_id: vehicleId,
      };

      const save = await saveInvoiceData(insertInvoiceInDbReq);
    } catch (error) {}
  };

  const getInvoiceData = async (invoiceId) => {
    try {
      const res = await getInvoiceByInvId(invoiceId);
      const invoiceData = res?.invoice?.invoice;
      const planStartDate = invoiceData?.subscription?.current_period_start;
      const planEndDate = invoiceData?.subscription?.current_period_end;

      const payloadForInvoiceOrderTable = {
        user_id: user?.user_id,
        stripeInvId: invoiceData?.id,
        paymentAmt: centToDollar(invoiceData?.total),
        paymentType: invoiceData?.payment_intent?.payment_method?.type,
        cardNumber: invoiceData?.payment_intent?.payment_method?.card?.last4,
        cardHolderName:
          invoiceData?.payment_intent?.payment_method?.metadata?.customerName,
        paymentStatus: invoiceData?.status,
        vehicleNumber: invoiceData?.subscription?.metadata?.vehicle_name,
      };
      await savedOrderInvoice(payloadForInvoiceOrderTable);
      console.log(res, payloadForInvoiceOrderTable, "vikas debug 12");
      const vehicleId = await handleSaveUserVehicle({
        planStartDate: moment.unix(planStartDate).format("YYYY-MM-DD HH:mm:ss"),
        planEndDate: moment.unix(planEndDate).format("YYYY-MM-DD HH:mm:ss"),
      });

      if (!_.isEmpty(res?.invoice) && vehicleId) {
        setInvoiceData(res?.invoice);
        saveInvoiceDetails(
          invoiceData,
          res?.invoice?.productDetails,
          vehicleId
        );
      }
    } catch (error) {}
  };
  const getInvoiceId = async () => {
    try {
      const { subscriptions } = await getSubscriptionData();
      if (subscriptions?.data?.length) {
        // setInvoiceId(subscriptions?.data[0]?.latest_invoice)
        getInvoiceData(subscriptions?.data[0]?.latest_invoice?.id);
      }
    } catch (error) {}
  };

  const updateCustomerMembershipStatus = async () => {
    const { subscriptions } = await getSubscriptionData();
    let is_premium = 0;
    if (subscriptions?.data?.length > 0) {
      is_premium = 1;
    }
    const update = await customerUpdateMembershipStatus(
      is_premium,
      user?.user_id
    );

    return update?.status;
  };

  useEffect(() => {
    // if (_.isObject(user) && !_.isEmpty(user)) {
    //   getInvoiceId();
    //   updateCustomerMembershipStatus();
    // } else {
    //   if (_.isObject(user) && !_.isEmpty(user)) {
    //     dispatch(loggedInUser(tempUser));
    //     dispatch(tempLoggedOutUser());
    //   }
    // }

    getInvoiceId();
    updateCustomerMembershipStatus();
  }, []);

  const { invoice, productDetails } = invoiceData;

  if (_.isEmpty(invoice)) {
    return <MainLoader />;
  }
  // userEmail:invoice?.customer_email,
  // userStripeId:invoice?.customer,
  console.log(invoice, productDetails, "paid invoice");

  return (
    <>
      <div style={{ padding: 10 }}>
        <div className="text-center">
          <h5 style={{ color: "coral" }} className="mt-5">
            Payment Completed!
          </h5>
          {/* <div class="mt-2">
            <i
              class="fas fa-check"
              style="font-size:64px;background-color:green;color:white;padding:10px;border-radius:100%"
            />
          </div> */}
          <img
            src="/assets/customerImg/check 1.svg"
            style={{ width: 125 }}
            alt="check"
          />
          <div className="mt-4" style={{ fontSize: 15 }}>
            Thank you! Your payment is successful. A confirmation email has been
            sent to <b>{invoice?.customer_email}</b>
          </div>
          <h5 style={{ color: "coral" }} className="mt-5">
            Order Summary
          </h5>
        </div>
        <div
          className="card p-2 mt-3"
          style={{ backgroundColor: "#fff", color: "#000", fontSize: 15 }}
        >
          <div>
            <div style={{ fontSize: 10 }}>Name</div>
            <div style={{ fontSize: 15 }}>{invoice?.customer_name}</div>
          </div>
          <div className="mt-2">
            <div style={{ fontSize: 10 }}>Vehicle Plate Number</div>
            <div style={{ fontSize: 15 }}>{customerVehicle?.vehicle_name}</div>
          </div>
          <div className="mt-2">
            <div style={{ fontSize: 10 }}>Type of Membership</div>
            <div style={{ fontSize: 15 }}>{productDetails?.name}</div>
          </div>
          <div className="mt-2">
            <div style={{ fontSize: 10 }}>Payment Method</div>
            <div style={{ fontSize: 15 }}>
              {_.capitalize(
                invoice?.payment_intent?.payment_method?.card?.brand
              )}{" "}
              ....{invoice?.payment_intent?.payment_method?.card?.last4}
            </div>
          </div>
        </div>
        <div
          className="card p-2 mt-3"
          style={{ backgroundColor: "#fff", color: "#000", fontSize: 15 }}
        >
          <div>
            <div style={{ fontSize: 10 }}>Order ID</div>
            <div style={{ fontSize: 15 }}>{invoice?.number}</div>
          </div>
          <div className="mt-2">
            <div style={{ fontSize: 10 }}>Order Date and Time</div>
            <div style={{ fontSize: 15 }}>
              {/* 15-01-2021 &nbsp;&nbsp;&nbsp;&nbsp;15:05 */}
              {moment.unix(invoice?.created).format("LLL")}
            </div>
          </div>
        </div>
        <div
          className="card p-2 mt-3"
          style={{ backgroundColor: "#fff", color: "#000", fontSize: 15 }}
        >
          <div style={{ fontSize: 15 }}>
            {invoice?.account_name} {productDetails?.name}{" "}
            {invoice?.lines?.data?.length > 0
              ? _.capitalize(
                  `${invoice?.lines?.data[0]?.price?.recurring?.interval}ly`
                )
              : ""}{" "}
            Subscription
          </div>
          <div className="row mt-2">
            <div className="col">Subtotal</div>
            <div className="col text-right pr-4">
              ${centToDollar(invoice?.subtotal)}
            </div>
          </div>
          <div className="row" style={{ color: "coral" }}>
            <div className="col">Coupon Discount</div>
            <div className="col text-right pr-4">-${centToDollar(0)}</div>
          </div>
          <div className="row mt-4">
            <div className="col">
              <b>Total</b>
            </div>
            <div className="col text-right pr-4">
              <b>${centToDollar(invoice?.total)}</b>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <div style={{ overflow: "auto", marginTop: 20, marginBottom: 30 }}>
          <div>
            <button
              id="nextBtn"
              onClick={() => {
                History.push("/customer/dashboard");
              }}
            >
              Home
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerHoc(CustomerPaidSummary);
