import React from "react";
import LoadingIndicator from "./LoadingIndicator";

const MainLoader = () => {
  return (
    <div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        width: "100%",
        height: "100%",
        transform: " translate(-50%, -50%)",
        backgroundColor: "rgb(251,251,251,0.5)",
        zIndex: "9999",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <LoadingIndicator />
    </div>
  );
};

export default MainLoader;
