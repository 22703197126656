import _ from "lodash";
import moment from "moment";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { redirectWhenCustomerVehicleNotExist } from "../../../../helpers/helper";
import { History } from "../../../../helpers/history";
import CustomerHoc from "../../../../Hoc/CustomerHoc";
import {
  addCustomerVehicle,
  saveInvoiceData,
} from "../../../../services/customer.service";

import "../style.scoped.css";
import "../summaryStyle.scoped.css";
const CustomerFreeSummary = () => {
  const user = useSelector((state) => state.user.data);
  const customerVehicle = useSelector((state) => state.customerVehicle.data);
  const randomOrderId = _.random(10000000000000, 99999999999999);

  const handleSaveUserVehicle = async () => {
    try {
      const reqData = {
        user_id: user?.user_id,
        make: customerVehicle?.vehicle_make,
        model: customerVehicle?.vehicle_model,
        number_plate: customerVehicle?.vehicle_name,
        subscribe_type: 0,
      };
      const vehicle = await addCustomerVehicle(reqData);
      if (vehicle?.insertedId) {
        saveInvoiceDetails(vehicle?.insertedId);
      }
    } catch (error) {}
  };

  const saveInvoiceDetails = async (vehicleId) => {
    try {
      const insertInvoiceInDbReq = {
        mfl_user_id: user?.user_id,
        mfl_user_name: `${user?.first_name} ${user?.middle_name}`,
        mfl_stripe_invoice_id: "",
        mfl_stripe_plan_id: "",
        mfl_stripe_plan_price_id: "",
        mfl_payment_amount: 0,
        // mfl_cuppon_details:"",
        // mfl_cuppon_name:"",
        mfl_payment_type: "",
        mfl_order_id: randomOrderId,
        mfl_card_details: "",
        mfl_user_vehicle_number: customerVehicle?.vehicle_name,
        mfl_is_premium: 0,
        mfl_user_vehicle_id: vehicleId,
      };

      console.log(insertInvoiceInDbReq, "insertInvoiceInDbReq");
      const save = await saveInvoiceData(insertInvoiceInDbReq);
    } catch (error) {
      History.push("/customer/dashboard");
    }
  };
  useEffect(() => {
    handleSaveUserVehicle();
  }, []);

  redirectWhenCustomerVehicleNotExist(customerVehicle);
  return (
    <>
      <div className="tab d-block">
        <div>
          <div className="text-center">
            {/* <h5 style={{ color: "coral" }} className="mt-5">
              Free Plan Activated!
            </h5> */}

            <div className="mt-2">
              <i
                className="fas fa-check"
                style={{
                  fontSize: 64,
                  backgroundColor: "green",
                  color: "white",
                  padding: 10,
                  borderRadius: "100%",
                }}
              />
            </div>
            <div className="mt-4" style={{ fontSize: 15 }}>
              Thank you! Your free plan has been activated.
            </div>

            <h5 style={{ color: "coral" }} className="mt-5">
              Order Summary
            </h5>
          </div>
          <div
            className="card p-2 mt-3"
            style={{ backgroundColor: "#fff", color: "#000", fontSize: 15 }}
          >
            <div>
              <div style={{ fontSize: 10 }}>Name</div>
              <div style={{ fontSize: 15 }}>
                {user?.first_name} {user?.middle_name}
              </div>
            </div>
            <div className="mt-2">
              <div style={{ fontSize: 10 }}>Vehicle Plate Number</div>
              <div style={{ fontSize: 15 }}>
                {customerVehicle?.vehicle_name}
              </div>
            </div>
            <div className="mt-2">
              <div style={{ fontSize: 10 }}>Type of Membership</div>
              <div style={{ fontSize: 15 }}>Free Member</div>
            </div>
            {/* <div className="mt-2">
              <div style={{ fontSize: 10 }}>Payment Method</div>
              <div style={{ fontSize: 15 }}>Mastercard ....9302</div>
            </div> */}
          </div>

          <div
            className="card p-2 mt-3"
            style={{ backgroundColor: "#fff", color: "#000", fontSize: 15 }}
          >
            <div>
              <div style={{ fontSize: 10 }}>Order ID</div>
              <div style={{ fontSize: 15 }}>{randomOrderId}</div>
            </div>
            <div className="mt-2">
              <div style={{ fontSize: 10 }}>Order Date and Time</div>
              <div style={{ fontSize: 15 }}>
                {/* 15-01-2021 &nbsp;&nbsp;&nbsp;&nbsp;15:05 */}
                {moment().format("LLL")}
              </div>
            </div>
          </div>

          <div
            className="card p-2 mt-3"
            style={{ backgroundColor: "#fff", color: "#000", fontSize: 15 }}
          >
            <div style={{ fontSize: 15 }}>eTow Free Subscription</div>
            <div className="row mt-2">
              <div className="col">Subtotal</div>
              <div className="col text-right pr-4">$0.00</div>
            </div>
            <div className="row" style={{ color: "coral" }}>
              <div className="col">Coupon Discount</div>
              <div className="col text-right pr-4">$0.00</div>
            </div>
            <div className="row mt-4">
              <div className="col">
                <b>Total</b>
              </div>
              <div className="col text-right pr-4">
                <b>$0.00</b>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{ overflow: "auto", marginTop: 20, marginBottom: 30 }}
        onClick={() => {
          History.push("/customer/dashboard");
        }}
      >
        <div>
          <button type="button" id="nextBtn">
            Home
          </button>
        </div>
      </div>
    </>
  );
};

export default CustomerHoc(CustomerFreeSummary);
