import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { accountType, createBreadCrumb } from "../../../helpers/helper";
import { accountCountCards } from "../../../services/all.service";
import AdminSidebar from "../AdminSidebar";
import styles from "./account.module.css";

const Account = () => {
  const { FreeUser, PremiumUser, ServiceProvider } = accountType;
  const [cardData, setCardData] = useState({});

  const getCardsData = async () => {
    try {
      const res = await accountCountCards();

      if (res?.data) {
        setCardData(res?.data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    getCardsData();
  }, []);
  return (
    <AdminSidebar title={createBreadCrumb({ currentPageTitle: "Accounts" })}>
      <div className="container">
        <div className="card-deck mb-3 text-center">
          <div className="card mb-4 box-shadow">
            <div className="card-body">
              <h1 className="card-title pricing-card-title">
                {cardData?.premiumuser}
              </h1>
              <h4 id={styles.h1}>Premium Users</h4>
              <img
                className="img-box"
                src="/assets/img/diamond 2.svg"
                alt="Card cap"
              />
              <br />
              <br />
              <br />
              <br />
              <Link to={`/dashboard/accounts/view/${PremiumUser}`}>
                <button
                  type="button"
                  className="btn btn-lg btn-block btn-primary"
                >
                  Accounts
                </button>
                <br />
              </Link>
              {/* <Link to={`/dashboard/accounts/add/${PremiumUser}`}>
                <button
                  type="button"
                  className="btn btn-lg btn-block btn-primary"
                >
                  Create PB2B Account
                </button>
              </Link> */}
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </div>
          </div>
          <div className="card mb-4 box-shadow">
            <div className="card-body">
              <h1 className="card-title pricing-card-title">
                {cardData?.freeuser}
              </h1>
              <h4 id={styles.h1}>Free Users</h4>
              <img
                className="img-box"
                src="/assets/img/2. tickets.svg"
                alt="Card cap"
              />
              <br />
              <br />
              <br />
              <br />
              <Link to={`/dashboard/accounts/view/${FreeUser}`}>
                <button
                  type="button"
                  className="btn btn-lg btn-block btn-primary"
                >
                  Accounts
                </button>
                <br />
              </Link>
              {/* <aLink to="accounts8.html" >
          <button type="button" class="btn btn-lg btn-block btn-primary">Create PB2B Account</button>
      </aLink> */}
              {/* <button type="button" class="btn btn-lg btn-block btn-primary">Accounts</button><br>
    <button type="button" class="btn btn-lg btn-block btn-primary">Create PB2B Account</button> */}
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </div>
          </div>
          <div className="card mb-4 box-shadow">
            <div className="card-body">
              <h1 className="card-title pricing-card-title">
                {cardData?.serviceprovider}
              </h1>
              <h4 id={styles.h1}>Service Providers</h4>
              <img
                className="img-box"
                src="/assets/img/Group.svg"
                alt="Card cap"
              />
              <br />
              <br />
              <br />
              <br />
              <Link to={`/dashboard/accounts/view/${ServiceProvider}`}>
                <button
                  type="button"
                  className="btn btn-lg btn-block btn-primary"
                >
                  Accounts
                </button>
                <br />
              </Link>
              <Link to={`/dashboard/accounts/add/${ServiceProvider}`}>
                <button
                  type="button"
                  className="btn btn-lg btn-block btn-primary"
                >
                  Create partner Account
                </button>
              </Link>
              {/* <button type="button" class="btn btn-lg btn-block btn-primary">Accounts</button>
    <button type="button" class="btn btn-lg btn-block btn-primary">Create Partner Account</button> */}
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
    </AdminSidebar>
  );
};

export default Account;
