import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import moment from "moment";
import { ActiveCarousel, ActiveDeals, DeleteCarousel, DeleteDeals, InactiveCarousel, InactiveDeals, getAllBanner, getAllDeals, userByUserIdService } from "../../../services/all.service";
import AdminSidebar from "../AdminSidebar";
import "./marketplace.scoped.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchUser } from "../../../redux/reducers/Marketplace";
import { getCarousel } from "../../../redux/reducers/carousel";
import axios from 'axios';
import { History } from "../../../helpers/history";
const url = process.env.REACT_APP_BACKEND_URL;


const Records = () => {
  const [invoiceData, setInvoiceData] = useState([]);
  const [dealsData, setDealsData] = useState([]);
  const [listactive, setListactive] = useState([])
  const [bannerPage, setBannerPage] = useState(1);
  const [dealsPage, setDealsPage] = useState(1);
  const itemsPerPage = 5; // Number of items to display per page

  const [active, setActive] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [refresh1, setRefresh1] = useState(false);

  const dispatch = useDispatch();

  const reduxData = useSelector((state) => state);
  const userData = reduxData.marketplace;
  const carouselData = reduxData.getcarousel;



  useEffect(() => {
    dispatch(fetchUser());
  }, [userData.data !== null, refresh]);

  useEffect(() => {
    dispatch(getCarousel());
  }, [carouselData.data !== null, refresh1]);

  useEffect(() => {
    // console.log(carouselData.data, invoiceData, 'fffffffffffffffffffffffffffff')
    if (carouselData.data != null) {
      setInvoiceData(carouselData.data);
      
    }

    if (userData.data != null) {
      setDealsData(userData.data);
      

    }


    // setDealsData(userData.data);
  }, [carouselData, userData, refresh, refresh1]);


  useEffect(() => {
    // console.log(carouselData.data, invoiceData, 'fffffffffffffffffffffffffffff')
    if (carouselData.data != null) {
      setInvoiceData(carouselData.data);

    }

    if (userData.data != null) {
      setDealsData(userData.data);
     

    }


    // setDealsData(userData.data);
  }, [carouselData,userData]);


  useEffect(() => {
    // console.log(carouselData.data, invoiceData, 'fffffffffffffffffffffffffffff')
    // if (invoiceData != null) {
    //   invoiceData.forEach(item => {
    //     if (item.mfl_is_active === 1) {
    //       if (!active.includes(item.mfl_id)) {
    //         setActive([...active, item.mfl_id]);
    //         console.log(item.mfl_id, 'adsfasfadsfs');
    //       }
    //     }
    //   });
    // }

    // if (dealsData != null) {
    //   dealsData.forEach(item => {
    //     if (item.mfl_deals_is_active === 1) {

    //       if (!listactive.includes(item.mfl_id)) {
    //         setListactive([...listactive, item.mfl_id]);
    //         console.log(item.mfl_id, 'listactive');
    //       }
    //     }
    //   });

    // }

    if (invoiceData !== null) {
      invoiceData.forEach(item => {
        if (item.mfl_is_active === 1 && !active.includes(item.mfl_id)) {
          setActive(prevActive => [...prevActive, item.mfl_id]);
          // console.log(item.mfl_id, 'adsfasfadsfs');
        }
      });
    }

    if (dealsData !== null) {
      dealsData.forEach(item => {
        if (item.mfl_deals_is_active === 1 && !listactive.includes(item.mfl_id)) {
          setListactive(prevListActive => [...prevListActive, item.mfl_id]);
          // console.log(item.mfl_id, 'listactive');
        }
      });
    }


    // setDealsData(userData.data);
  }, [dealsData,invoiceData]);

  // console.log(active, 'vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv')

  const Activehandler = async (e) => {
    try {
      
      if (e.target.name === "inactive") {
        const response = await ActiveCarousel({ "banner_id": parseInt(e.target.value) });
        // console.log(response, 'inactive')
        if (active.includes(parseInt(e.target.value)))
          setActive(active.filter(item => item !== parseInt(e.target.value)));
        else
          setActive([...active, parseInt(e.target.value)])
      }
      else {
        const response = await InactiveCarousel({ "banner_id":parseInt(e.target.value) });
        // console.log(response, 'active')
        if (active.includes(parseInt(e.target.value)))
          setActive(active.filter(item => item !== parseInt(e.target.value)));
        else
          setActive([...active,parseInt(e.target.value)])

      }

    } catch (error) {
      console.log(error, 'Error')
      if (error.response != undefined) {
        console.log('fail', error)

      }
      else {
        console.log(error)
      }
    }

  }



  const ListActivehandler = async (e) => {
    try {
      if (e.target.name === "inactive") {
        // console.log(listactive,parseInt(e.target.value))
        if (listactive.includes(parseInt(e.target.value)))
          setListactive(listactive.filter(item => item !== parseInt(e.target.value)));
        else
          setListactive([...listactive, parseInt(e.target.value)])


        const response = await ActiveDeals({ "deal_id": e.target.value });
        // console.log(response, 'inactive')
        
      }
      else {
        const response = await InactiveDeals({ "deal_id": e.target.value });
        // console.log(response, 'active')
        if (listactive.includes(parseInt(e.target.value)))
          setListactive(listactive.filter(item => item !== parseInt(e.target.value)));
        else
          setListactive([...listactive, parseInt(e.target.value)])

      }

    } catch (error) {
      console.log(error, 'Error')
      if (error.response != undefined) {
        console.log('fail', error)

      }
      else {
        console.log(error)
      }
    }

  }





  const Deletehandler = async (id) => {
    try {
      // console.log(id, 'delete')
      const response = await DeleteDeals({ "deal_id": id });
      // console.log(response, 'delete')
      setRefresh(!refresh)


    } catch (error) {
      console.log(error, 'Error')
      if (error.response != undefined) {
        console.log('fail', error)

      }
      else {
        console.log(error)
      }
    }

  }


  const Deletebannerhandler = async (id) => {
    try {
      // console.log(id, 'delete')
      const response = await DeleteCarousel({ "banner_id": id });
      // console.log(response, 'delete')
      setRefresh1(!refresh1)


    } catch (error) {
      console.log(error, 'Error')
      if (error.response != undefined) {
        console.log('fail', error)

      }
      else {
        console.log(error)
      }
    }

  }


  const renderBannerTable = () => {
    const startIndex = (bannerPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentPageData = invoiceData.slice(startIndex, endIndex);

    return (
      <>
        <table className="table" id="" width="100%">
          <thead>
            <tr>
              <th>Title</th>
              <th>Date Posted</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          {invoiceData.length != 0 && <tbody >
            {currentPageData.map((item, index) => (<>
              <tr key={index}>
                <td className="bannerTd">{item?.mfl_banner_name}</td>
                <td>{moment(item?.mfl_created_on).format("L")}</td>
                <td><button
                  style={{ zIndex: "34" }}
                  className={`btn btn-sm ${active.includes(item.mfl_id)
                  
                      ? "btn-primary"
                      : "btn-light"
                    }`}
                  value={item.mfl_id}
                  name={
                    active.includes(item.mfl_id)
                        ? "active"
                        : "inactive"
                  }
                  onClick={Activehandler}


                >
                  {active.includes(item.mfl_id)
                      ? "Active"
                      : "Inactive"}

                </button></td>
                <td>
                  <Link
                    onClick={() => {
                      History.push({
                        pathname: "/dashboard/deals/addnewcarosel",
                        state: { id: item.mfl_id },
                      });
                    }}
                    className="btn btn-primary"
                  >
                    Edit
                  </Link>
                  <button
                    onClick={() => Deletebannerhandler(item.mfl_id)}
                    className="btn btn-danger ml-2"
                  >
                    Delete Listing
                  </button>
                </td>
              </tr>
            </>
            ))}
          </tbody>}


        </table>
        {invoiceData.length == 0 && <div className="Nodatafound">Data not found :(</div>}
        {invoiceData.length != 0 && <div className="pagination">
          <button
            disabled={bannerPage === 1}
            onClick={() => setBannerPage(bannerPage - 1)}
            className="btn-light"
          >
            Prev
          </button>
          <span>{bannerPage}</span>
          <button
          className="btn-light"
            disabled={endIndex >= invoiceData.length}
            onClick={() => setBannerPage(bannerPage + 1)}
          >
            Next
          </button>
        </div>}
      </>
    );
  };

  const renderDealsTable = () => {
    const startIndex = (dealsPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentPageData = dealsData.slice(startIndex, endIndex);

    return (
      <><div className="justify-content-between">
        <table className="table" width="100%">
          <thead>
            <tr>
              <th>Title/Contact</th>
              <th>Claims</th>
              <th>Date Posted</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          {currentPageData.length != 0 && <tbody >
            {currentPageData.map((item, index) => (
              <tr key={index}>
                <td>{item?.mfl_deals_title}</td>
                <td>{item?.mfl_deals_claim_count}</td>
                <td>{moment(item?.mfl_created_on).format("L")}</td>
                <td><button
                  style={{ zIndex: "34" }}
                  className={`btn btn-sm ${listactive.includes(item.mfl_id)
                    ? "btn-primary"
                    : "btn-light"
                    }`}
                  value={item.mfl_id}
                  name={
                    listactive.includes(item.mfl_id)

                      ? "active"
                      : "inactive"
                  }
                  onClick={ListActivehandler}


                >
                  {listactive.includes(item.mfl_id)
                      ? "Active"
                      : "Inactive"}

                </button></td>
                <td>
                  <Link
                    onClick={() => {
                      History.push({
                        pathname: "/dashboard/deals",
                        state: { id: item.mfl_id },
                      });
                    }}
                    className="btn btn-primary"
                  >
                    Edit
                  </Link>
                  <Link
                    // to={`/delete-deal/${item?.mfl_deal_id}`}
                    className="btn btn-danger ml-2"
                    onClick={() => Deletehandler(item.mfl_id)}
                  >
                    Delete Listing
                  </Link>
                </td>
              </tr>
            ))}

          </tbody>}

        </table>
        {currentPageData.length == 0 && <div className="Nodatafound">Data not found :(</div>}
        {currentPageData.length != 0 && <div className="pagination">
          <button
            disabled={dealsPage === 1}
            onClick={() => setDealsPage(dealsPage - 1)}
            className="btn-light"
          >
            Prev
          </button>
          <span>{dealsPage}</span>
          <button
          className="btn-light"
            disabled={endIndex >= dealsData.length}
            onClick={() => setDealsPage(dealsPage + 1)}
          >
            Next
          </button>
        </div>}
      </div>
      </>
    );
  };

  return (
    <AdminSidebar
      title="Deals"
      path="/dashboard"
      childrenContainerClass="container-fluid flex-grow-1 container-p-y"
    >
      <div className="container">
        {/* Carousel Banner Table */}
        <div className="card-deck mb-3 text-center">
          <div className="card mb-4 box-shadow">
            <div className="card-body overflow-auto tablebody">
              <div className="d-flex align-items-center justify-content-between">
                <div className="headingandIcon">
                  <img src="../assets/img/bookicon.png" alt="icon" />
                  <h3>Carousel Banner Listings</h3>
                </div>
                {/* <Link
                  to="/dashboard/deals/addnewcarosel"
                  className="btn x btn-primary"
                >
                  Add New Carousel +
                </Link> */}
                 <button
                  type="button"
                  className="btn x btn-primary"
                  onClick={() => History.push("/dashboard/deals/addnewcarosel")}
                >
                  Add New Carousel +
                </button>
              </div>
              <br />
              {renderBannerTable()}
            </div>
          </div>
        </div>

        {/* Deals Table */}
        <div className="card-deck mb-3 text-center">
          <div className="card mb-4 box-shadow">
            <div className="card-body overflow-auto tablebody">
              <div className="d-flex align-items-center justify-content-between">
                <div className="headingandIcon">
                  <img src="../assets/img/bookicon.png" alt="icon" />
                  <h3>Deals Listings</h3>
                </div>
                <button
                  type="button"
                  className="btn x btn-primary"
                  onClick={() => History.push("/dashboard/deals")}
                >
                  Add New Deal +
                </button>
              </div>
              <br />
              {renderDealsTable()}
            </div>
          </div>
        </div>
      </div>
    </AdminSidebar>
  );
};

export default Records;
