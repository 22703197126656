export const UserRoles = {
  Admin: 1,
  Customer: 4,
};

export const getUserTypeByRole = (user_group_id) => {
  if (user_group_id === 4) {
    return "Customer";
  }
};
