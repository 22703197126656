import { createContext } from "react";
import {
  setLocalStorage,
  getLocalStorage,
  removeLocalStorage,
} from "../helpers/localstorage";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import { MainLoader } from "../components/common";

export const userLocalStorageKey = "etow_web";

export const setUserInLocalStorage = (data) => {
  return setLocalStorage(userLocalStorageKey, data);
};

export const getUserInLocalStorage = () => {
  return getLocalStorage(userLocalStorageKey);
};

export const getUserAccessTokenFromLocal = () => {
  const userData = getLocalStorage(userLocalStorageKey);
  return userData?.token;
};

export const getUserIdFromLocal = () => {
  const userData = getLocalStorage(userLocalStorageKey);
  return userData?.user_id;
};

export const getUserEmailFromLocal = () => {
  const userData = getLocalStorage(userLocalStorageKey);
  return userData?.email_address;
};

export const removeUserInLocalStorage = () => {
  return removeLocalStorage(userLocalStorageKey);
};

export const AppGlobalContext = createContext();

const value = {
  setUserInLocalStorage,
  getUserInLocalStorage,
  removeUserInLocalStorage,
};

export function AppGlobalProvider({ children }) {
  const isLoader = useSelector((state) => state.loader.isShowLoader);

  return (
    <AppGlobalContext.Provider value={value}>
      <ToastContainer />
      {isLoader && <MainLoader />}
      <div className="mb-5">{children}</div>
    </AppGlobalContext.Provider>
  );
}
