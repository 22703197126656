import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { History } from "../../../helpers/history";
import CustomerHoc from "../../../Hoc/CustomerHoc";
import { loggedInUser } from "../../../redux/reducers/UserSlice";
import { loginService } from "../../../services/all.service";
import { customerLoginService } from "../../../services/customer.service";
import Form from "../../common/Form";
import { createValidationObj } from "../../common/Form/Validation/validation.const";
import "./custLogin.scoped.css";

const loginFields = [
  {
    type: "text",
    name: `email_address`,
    inputWrapperClass: "col-12",
    validate: true,
    validationsProps: {
      validationType: "string",
      validations: [
        ...createValidationObj("required", ["Email Address is required"]),
        ...createValidationObj("email", ["Enter Valid Email Address"]),
      ],
    },
  },
  {
    type: "password",
    name: `password`,
    inputWrapperClass: "col-12",
    validate: true,
    validationsProps: {
      validationType: "string",
      validations: [
        ...createValidationObj("required", ["Password is required"]),
      ],
    },
  },
];

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const handleLogin = async (data) => {
    
    const res = await customerLoginService({
      ...data,
      logon_type: "CS",
      device_type: "WEB",
    });

    if (res?.status === 1) {
      History.push("/customer/dashboard");
      console.log(res, "res");
      dispatch(loggedInUser(res));
    }
  };
  return (
    <div className="login-page">
      <div className="form">
        <div style={{ margin: "-30px 0px 50px -1px" }}>
          <div className="title">Log In</div> <hr />
        </div>
        {/* <Form fields={loginFields} /> */}
        <form className="login-form" onSubmit={handleSubmit(handleLogin)}>
          <input
            type="email"
            {...register("email_address")}
            required
            placeholder="Email"
          />
          <input
            type="password"
            required
            placeholder="Password"
            {...register("password")}
          />
          <button style={{ textTransform: "unset !important" }}>Login</button>
          <small className="message d-block text-right">
            <Link to="/forgot-password">Forgot password?</Link>
          </small>
          <p className="message">
            <Link to="/customer/register">You don't have account?</Link>
          </p>
        </form>
      </div>
    </div>
  );
};

export default CustomerHoc(Login);
