import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { withRouter } from "react-router-dom";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Redirect } from "react-router-dom";

const Subscribe = ({ location }) => {
  const [clientSecret] = useState(location.state.clientSecret);
  const [subscriptionId] = useState(location.state.subscriptionId);
  const [name, setName] = useState("Jenny Rosen");
  const [messages, _setMessages] = useState("");
  const [paymentIntent, setPaymentIntent] = useState();

  const setMessage = (message) => {
    _setMessages(`${messages}\n\n${message}`);
  };

  const stripe = useStripe();
  const elements = useElements();

  if (!stripe || !elements) {
    return "";
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);
    console.log(cardElement, "cardElement");

    // Use card Element to tokenize payment details
    // let { error, paymentIntent } = await stripe.confirmCardPayment(
    //   clientSecret,
    //   {
    //     payment_method: {
    //       card: cardElement,
    //       billing_details: {
    //         name: name,
    //       },
    //     },
    //   }
    // );

    let { error, paymentIntent } = await stripe.confirmCardPayment(
      clientSecret,
      {
        payment_method: "pm_1LGkm2HoMNPpIDlJ2O8SvyF7",
      }
    );

    if (error) {
      // show error and collect new card details.
      setMessage(error.message);
      return;
    }
    setPaymentIntent(paymentIntent);
  };

  if (paymentIntent && paymentIntent.status === "succeeded") {
    return <Redirect to={{ pathname: "/customer/subscription/summary" }} />;
  }

  return (
    <>
      <h1>Subscribe</h1>

      <p>
        Try the successful test card: <span>4242424242424242</span>.
      </p>

      <p>
        Try the test card that requires SCA: <span>4000002500003155</span>.
      </p>

      <p>
        Use any <i>future</i> expiry date, CVC,5 digit postal code
      </p>

      <hr />

      <form onSubmit={handleSubmit}>
        <label>
          Full name
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </label>

        <CardElement />

        <button>Subscribe</button>

        <div>{messages}</div>
      </form>
    </>
  );
};

export default withRouter(Subscribe);
