import { Route } from "react-router-dom";
import React from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { RoleBasedLoginRedirect } from "./helper";
const RoleBasedRoute = ({ component: Component, routeType, ...rest }) => {
  const user = useSelector((state) => state.user.data);
  let authorized;
  if (_.isArray(rest?.role) && !_.isEmpty(rest?.role)) {
    authorized = _.includes(rest?.role, user?.role_id);
  } else {
    authorized = rest?.role === user?.role_id ? true : false;
  }

  console.log(rest?.role, "rest?.role");

  return (
    <Route
      {...rest}
      render={(props) =>
        authorized === true ? (
          <Component {...props} />
        ) : (
          <>{RoleBasedLoginRedirect(user?.role_id)}</>
        )
      }
    />
  );
};

export default RoleBasedRoute;
