import { createSlice } from "@reduxjs/toolkit";
import { getUserInLocalStorage } from "../../context/AppGlobalContext";

const userInitData =
  getUserInLocalStorage() === null ? {} : getUserInLocalStorage();

const initialState = {
  data: userInitData,
};

export const TempUserSlice = createSlice({
  name: "TempUser",
  initialState,
  reducers: {
    tempLoggedInUser: (state, { payload }) => {
      state.data = payload;
      // setUserInLocalStorage(payload);
    },
    tempLoggedOutUser: (state) => {
      state.data = {};
    },
  },
});

export const { tempLoggedInUser, tempLoggedOutUser } = TempUserSlice.actions;

export default TempUserSlice.reducer;
