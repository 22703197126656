import React from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  handleLogout,
  removeDashboardStrFromRoute,
} from "../../helpers/helper";
import { authUserRoutes } from "../../routes/AuthRoutes";
import { Icon } from "@iconify/react/dist/iconify.js";

const AdminSidebar = ({
  children,
  title,
  outerChildren,
  childrenContainerClass = "container-fluid flex-grow-1 container-p-y",
}) => {
  const history = useHistory();
  const currentPage = history.location.pathname;
  return (
    <div className="layout-wrapper layout-2">
      <div className="layout-inner">
        <div className="page-loader">
          <div className="bg-primary" />
        </div>

        <div
          id="layout-sidenav"
          className="layout-sidenav sidenav sidenav-vertical bg-dark"
        >
          <div className="app-brand demo">
            <span className="app-brand-logo demo">
              <img
                src="/assets/img/logo.png"
                alt="Brand Logo"
                className="img-fluid"
              />
            </span>
            <a
              href="/"
              className="app-brand-text demo sidenav-text font-weight-normal ml-2"
            >
              eTow Backend
            </a>
          </div>
          <div className="sidenav-divider mt-0" />
          <ul className="sidenav-inner py-1">
            {authUserRoutes.map((item, i) => {
              let isActive = undefined;
              if (item?.path === "/dashboard") {
                isActive = currentPage === item?.path;
                return (
                  <li className={`sidenav-item ${isActive ? "active" : ""}`}>
                    <a href={item?.path} className="sidenav-link">
                      <Icon
                        icon={item?.iconClass}
                        className="sidenav-icon"
                        width={20}
                        height={25}
                      />
                      <div>{item?.title}</div>
                    </a>
                  </li>
                );
              } else {
                isActive = currentPage.includes(
                  removeDashboardStrFromRoute(item?.path)
                );
              }

              return (
                <li
                  className={`sidenav-item ${
                    isActive ? "active" : ""
                  }`}
                  key={i}
                >
                  <Link to={item?.path} className="sidenav-link">
                    <Icon
                      icon={item?.iconClass}
                      className="sidenav-icon"
                      width={20}
                      height={25}
                    />
                    <div>{item?.title}</div>
                  </Link>
                </li>
              );
            })}

            {/* Pages */}
            <li className="sidenav-divider mb-1" />
            {/* <li className="sidenav-header small font-weight-semibold">Pages</li> */}
            <li
              className={`sidenav-item ${
                currentPage === "/dashboard/setting" ? "active" : ""
              }`}
            >
              <Link to="/dashboard/setting" className="sidenav-link">
                <i className="sidenav-icon feather icon-settings" />
                <div>Settings</div>
              </Link>
            </li>

            <li className="sidenav-item ">
              <Link to="#" className="sidenav-link" onClick={handleLogout}>
                <i className="sidenav-icon feather icon-power" />
                <div>Logout</div>
              </Link>
            </li>
          </ul>
        </div>

        <div className="layout-container">
          <nav
            className="layout-navbar navbar navbar-expand-lg align-items-lg-center container-p-x"
            id="layout-navbar"
          >
            <div className="layout-sidenav-toggle navbar-nav d-lg-none align-items-lg-center mr-auto">
              <a className="nav-item nav-link px-0 mr-lg-4" href="javascript:">
                <i className="ion ion-md-menu text-large align-middle" />
              </a>
            </div>
            <h4 className="font-weight-bold py-3 mb-0">{title}</h4>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#layout-navbar-collapse"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div
              className="navbar-collapse collapse"
              id="layout-navbar-collapse"
            >
              {/* Divider */}
              <hr className="d-lg-none w-100 my-2" />
              <div className="navbar-nav align-items-lg-center" />
              <div className="navbar-nav align-items-lg-center ml-auto">
                <label className="nav-item navbar-text navbar-search-box p-0 active">
                  {/* <i className="feather icon-search navbar-icon align-middle"></i> */}
                  <span className="navbar-search-input pl-2">
                    <input
                      type="text"
                      className="form-control navbar-text mx-2"
                      placeholder="Search..."
                    />
                  </span>
                  <i className="feather icon-search navbar-icon align-middle" />
                </label>
                <div className="demo-navbar-notifications nav-item dropdown mr-lg-3">
                  <a
                    className="nav-link dropdown-toggle hide-arrow"
                    href="#"
                    data-toggle="dropdown"
                  >
                    <i className="feather icon-bell navbar-icon align-middle" />
                    <span className="badge badge-danger badge-dot indicator" />
                    <span className="d-lg-none align-middle">
                      &nbsp; Notifications
                    </span>
                  </a>
                  <div className="dropdown-menu dropdown-menu-right">
                    <div className="bg-primary text-center text-white font-weight-bold p-3">
                      4 New Notifications
                    </div>
                    <div className="list-group list-group-flush">
                      <a
                        href="javascript:"
                        className="list-group-item list-group-item-action media d-flex align-items-center"
                      >
                        <div className="ui-icon ui-icon-sm feather icon-home bg-secondary border-0 text-white" />
                        <div className="media-body line-height-condenced ml-3">
                          <div className="text-dark">
                            Login from 192.168.1.1
                          </div>
                          <div className="text-light small mt-1">
                            Aliquam ex eros, imperdiet vulputate hendrerit et.
                          </div>
                          <div className="text-light small mt-1">12h ago</div>
                        </div>
                      </a>
                      <a
                        href="javascript:"
                        className="list-group-item list-group-item-action media d-flex align-items-center"
                      >
                        <div className="ui-icon ui-icon-sm feather icon-user-plus bg-info border-0 text-white" />
                        <div className="media-body line-height-condenced ml-3">
                          <div className="text-dark">
                            You have
                            <strong>4</strong> new followers
                          </div>
                          <div className="text-light small mt-1">
                            Phasellus nunc nisl, posuere cursus pretium nec,
                            dictum vehicula tellus.
                          </div>
                        </div>
                      </a>
                      <a
                        href="javascript:"
                        className="list-group-item list-group-item-action media d-flex align-items-center"
                      >
                        <div className="ui-icon ui-icon-sm feather icon-power bg-danger border-0 text-white" />
                        <div className="media-body line-height-condenced ml-3">
                          <div className="text-dark">Server restarted</div>
                          <div className="text-light small mt-1">19h ago</div>
                        </div>
                      </a>
                      <a
                        href="javascript:"
                        className="list-group-item list-group-item-action media d-flex align-items-center"
                      >
                        <div className="ui-icon ui-icon-sm feather icon-alert-triangle bg-warning border-0 text-dark" />
                        <div className="media-body line-height-condenced ml-3">
                          <div className="text-dark">99% server load</div>
                          <div className="text-light small mt-1">
                            Etiam nec fringilla magna. Donec mi metus.
                          </div>
                          <div className="text-light small mt-1">20h ago</div>
                        </div>
                      </a>
                    </div>
                    <a
                      href="javascript:"
                      className="d-block text-center text-light small p-2 my-1"
                    >
                      Show all notifications
                    </a>
                  </div>
                </div>
                {/* Divider */}
                <div className="nav-item d-none d-lg-block text-big font-weight-light line-height-1 opacity-25 mr-3 ml-1">
                  |
                </div>
                <div className="demo-navbar-user nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    data-toggle="dropdown"
                  >
                    <span className="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
                      <img
                        src="/assets/img/avatars/1.png"
                        alt
                        className="d-block ui-w-30 rounded-circle"
                      />
                      <span className="px-1 mr-lg-2 ml-2 ml-lg-0">
                        eTow Admin
                      </span>
                    </span>
                  </a>
                  <div className="dropdown-menu dropdown-menu-right">
                    <Link to="/dashboard/accounts" className="dropdown-item">
                      <i className="feather icon-user text-muted" /> &nbsp;
                      Accounts
                    </Link>
                    {/* <Link to="/dashboard/accounts" className="dropdown-item">
                      <i className="feather icon-mail text-muted" /> &nbsp;
                      Messages
                    </Link> */}
                    <Link to="/dashboard/setting" className="dropdown-item">
                      <i className="feather icon-settings text-muted" /> &nbsp;
                      Settings
                    </Link>
                    <div className="dropdown-divider" />
                    <a
                      href="javascript:"
                      className="dropdown-item"
                      onClick={handleLogout}
                    >
                      <i className="feather icon-power text-danger" /> &nbsp;
                      Log Out
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </nav>
          {outerChildren}
          <div className="layout-content">
            <div className={childrenContainerClass}>{children}</div>
          </div>
        </div>
      </div>
      <div className="layout-overlay layout-sidenav-toggle" />
    </div>
  );
};

export default AdminSidebar;
