import { passwordRegExp } from "../components/common/Form/Validation/validation.const";
import {
  AddVehicle,
  BikePremium,
  BuyPlanPremium,
  CarPremium,
  CustomerFreeSummary,
  CustomerLogin,
  CustomerPaidSummary,
  CustomerProfileEdit,
  CustomerProfileView,
  PlanList,
  RegisterStep1,
  RegisterStep2,
  SubscriptionSummary,
  RemoveSubscription
} from "../components/customer";
import Subscribe from "../components/customer/Subscription/Subscribe";
import { UserRoles } from "../helpers/Constant";

export const customerRoutes = [
  {
    path: "/customer",
    Component: CustomerLogin,
    routeCompType: "AuthRoute",
    routeType: "NoAuth",
  },
  {
    path: "/",
    Component: CustomerLogin,
    routeCompType: "AuthRoute",
    routeType: "NoAuth",
  },
  {
    path: "/customer/register",
    Component: RegisterStep1,
    routeCompType: "AuthRoute",
    routeType: "NoAuth",
  },
  {
    path: "/customer/dashboard",
    Component: CustomerProfileView,
    routeCompType: "RoleBasedRoute",

    role: UserRoles.Customer,
  },
  {
    path: "/customer/bike/premium",
    Component: BikePremium,
    routeCompType: "RoleBasedRoute",
    role: UserRoles.Customer,
  },
  {
    path: "/customer/car/premium",
    Component: CarPremium,
    routeCompType: "RoleBasedRoute",
    role: UserRoles.Customer,
  },
  {
    path: "/customer/profile",
    Component: CustomerProfileView,
    routeCompType: "RoleBasedRoute",
    role: UserRoles.Customer,
  },
  {
    path: "/customer/profile/edit",
    Component: CustomerProfileEdit,
    routeCompType: "RoleBasedRoute",
    role: UserRoles.Customer,
  },

  {
    path: "/customer/free/summary",
    Component: CustomerFreeSummary,
    routeCompType: "RoleBasedRoute",
    role: UserRoles.Customer,
  },
  {
    path: "/customer/paid/summary",
    Component: CustomerPaidSummary,
    routeCompType: "RoleBasedRoute",
    role: UserRoles.Customer,
  },
  {
    path: "/customer/subscription/plan",
    Component: PlanList,
    routeCompType: "RoleBasedRoute",
    role: UserRoles.Customer,
  },
  {
    path: "/customer/subscription/subscribe",
    Component: Subscribe,
    routeCompType: "RoleBasedRoute",
    role: UserRoles.Customer,
  },
  {
    path: "/customer/subscription/summary",
    Component: SubscriptionSummary,
    routeCompType: "RoleBasedRoute",
    role: UserRoles.Customer,
  },
  {
    path: "/customer/buy/subscription/:planId",
    Component: BuyPlanPremium,
    routeCompType: "RoleBasedRoute",
    role: UserRoles.Customer,
  },
  {
    path: "/remove/subscription/:vehicleId/:subscriptionID/:productID/:orderId",
    Component: RemoveSubscription,
    routeCompType: "RoleBasedRoute",
    role: UserRoles.Customer,
  },
  {
    path: "/customer/vehicle/add",
    Component: AddVehicle,
    routeCompType: "RoleBasedRoute",
    role: UserRoles.Customer,
  },

  {
    path: "/customer/register/step2",
    Component: RegisterStep2,
    routeCompType: "RoleBasedRoute",
    role: UserRoles.Customer,
  },
];

export const CustomerRoutes = [...customerRoutes];
