import React from "react";
import AdminSidebar from "../AdminSidebar";
import "./promotion.scoped.css";

const Promotion = () => {
  return (
    <AdminSidebar title="Promotions">
      <div className="container">
        <div className="card-deck mb-3 text-center">
          <div className="card mb-4 box-shadow">
            <div className="card-body">
              <h4 id="h4">Current page Preview</h4>
              <div className="displayflex">
                <div id="div1">
                  <img id="img1" src="/assets/img/iphone-12--product-red.svg" />
                </div>
                {/* <div style="border: 2px solid #d7d7d7;height: min-content;"> */}
                <div id="border">
                  <div className="div2">
                    <div className="text1">Current Promotions</div>
                    <div className="text2">5</div>
                  </div>
                  <hr />
                  <div className="div2">
                    <div className="text1">Current Promotions</div>
                    <div className="text2">426</div>
                  </div>
                  <hr />
                  <div className="div2">
                    <div className="text1">Average first response timw</div>
                    <div className="text2">33m</div>
                  </div>
                  <hr />
                  <div className="div2">
                    <div className="text1">Average response time</div>
                    <div className="text2">3h 8m</div>
                  </div>
                  <hr />
                  <div className="div2">
                    <div className="text1">Resolution within SLA</div>
                    <div className="text2">34%</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminSidebar>
  );
};

export default Promotion;
