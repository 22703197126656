import React, { useEffect, useState } from "react";
import { createBreadCrumb } from "../../../helpers/helper";
import { accountCountCards } from "../../../services/all.service";
import { Breadcrumb } from "../../common";
import { appendScript } from "../account/viewAccount";
import AdminSidebar from "../AdminSidebar";
import "./overview.scoped.css";
import { Helmet } from "react-helmet";

const Overview = () => {
  const [cardData, setCardData] = useState({});

  const getCardsData = async () => {
    try {
      const res = await accountCountCards();

      if (res?.data) {
        setCardData(res?.data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    getCardsData();
  }, []);
  useEffect(() => {
    // appendScript("https://cdn2.hubspot.net/hubfs/476360/Chart.js");
    // appendScript(
    //   "http://www.koolchart.com/demo/LicenseKey/KoolChartLicense.js"
    // );
    // appendScript("http://www.koolchart.com/demo/KoolChart/JS/KoolChart.js");
  }, []);

  return (
    <>
      {/* <Helmet>
        <script src="http://www.koolchart.com/demo/LicenseKey/KoolChartLicense.js" />
        <script src="http://www.koolchart.com/demo/KoolChart/JS/KoolChart.js" />
        <script src="/assets/libs/chart-am4/charts.js" />
        <script src="/assets/libs/chart-am4/animated.js" />
        <script src="./chartjs/1.js" />
      </Helmet> */}
      <AdminSidebar
        title={createBreadCrumb({
          currentPageTitle: "Overview",
          // title: "Overview",
          // href: "/accounts",
        })}
      >
        <div className="row">
          <div className="col-12 col-sm-6 col-md-3">
            <div className="info-box">
              <h5>Premium Users</h5>
              <span className="infobox1">
                <h1>{cardData?.premiumuser}</h1>
              </span>
            </div>
          </div>
          {/* /.col */}
          <div className="col-12 col-sm-6 col-md-3">
            <div className="info-box">
              <h5>Total Users</h5>
              <span className="infobox1">
                <h1>{cardData?.freeuser}</h1>
              </span>
            </div>
          </div>
          {/* /.col */}
          <div className="col-12 col-sm-6 col-md-3">
            <div className="info-box">
              <h5>Service Providers</h5>
              <span className="infobox1">
                <h1>{cardData?.serviceprovider}</h1>
              </span>
            </div>
          </div>
          {/* /.col */}
          <div className="col-12 col-sm-6 col-md-3">
            <div className="info-box">
              <h5>Transactions</h5>
              <span className="infobox1">
                <h1>64</h1>
              </span>
            </div>
          </div>
          {/* /.col */}
        </div>
        <div className="row">
          <div className="col-lg-7">
            <div className="card mb-4">
              <div className="card-body">
                <div id="div1">
                  {/* <div><h4 class="card-header-title mb-0" style="color: darkgray;">Jan</h4></div> */}
                  <input
                    type="text"
                    id="datepicker"
                    className="input1"
                    placeholder="Jan"
                  />
                  {/* <button class="button1">Jan</button> */}
                  <div>
                    <h4 className="card-header-title mb-0">User Rate</h4>
                    <p className="p1">as of 25 May 2019,09.41 PM</p>
                  </div>
                </div>
                <div className="chartjs-size-monitor-expand">
                  <div className />
                </div>
                <div className="chartjs-size-monitor-shrink">
                  <div className />
                </div>
                <canvas id="canvas" className="chartjs-render-monitor-canvas" />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-7">
            <div className="card mb-4">
              <div className="card-body">
                <div id="div2">
                  {/* <div><h4 class="card-header-title mb-0" style="color: darkgray;">Jan</h4></div> */}
                  {/* <button class="button1">Jan</button> */}
                  <input
                    type="text"
                    id="datepicker1"
                    className="input1"
                    placeholder="Jan"
                  />
                  <div>
                    <h4 className="card-header-title mb-0">Revenue</h4>
                    <div id="position">
                      <div id="text11">$</div>
                      <div id="text12">36.23</div>
                      <div id="text13">&nbsp;↑&nbsp;+6.4&nbsp;</div>
                    </div>
                  </div>
                </div>
                <div id="chartHolder1" className="div3" />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-7">
            <div className="card mb-4">
              <div className="card-body">
                <div id="div4">
                  <input
                    type="text"
                    id="datepicker2"
                    className="input1"
                    placeholder="Jan"
                  />
                  {/* <button class="button1">Jan</button> */}
                  <div>
                    <h4 className="card-header-title mb-0">
                      Average Transaction
                    </h4>
                    <div id="position">
                      <div id="text11">$</div>
                      <div id="text12">75.23</div>
                      <div id="text13">&nbsp;↑&nbsp;+6.4&nbsp;</div>
                    </div>
                  </div>
                </div>
                <div id="chartHolder2" className="div5" />
              </div>
            </div>
          </div>
        </div>
      </AdminSidebar>
    </>
  );
};

export default Overview;
