import React from "react";
import AdminSidebar from "../AdminSidebar";
import "./setting.scoped.css";

const Setting = () => {
  return (
    <AdminSidebar title="Setting">
      <div className="container">
        <div id="div1" className="card-deck mb-3 text-center">
          <div className="card mb-4 box-shadow">
            <div className="card-body">
              <div className="displayflex">
                <h5>Unresolved tickets</h5>
                <h6 id="h6">View Details</h6>
              </div>
              <div id="div2">
                <h6 id="h2">
                  Group:<span id="span1">Support</span>
                </h6>
              </div>
              <hr />
              <div className="displayflex">
                <div className="text1">Waiting on Feature Request</div>
                <div className="text2">4238</div>
              </div>
              <hr />
              <div className="displayflex">
                <div className="text1">Awaiting on Customer Response</div>
                <div className="text2">1005</div>
              </div>
              <hr />
              <div className="displayflex">
                <div className="text1">Awaiting Developer Fix</div>
                <div className="text2">914</div>
              </div>
              <hr />
              <div className="displayflex">
                <div className="text1">Pending</div>
                <div className="text2">281</div>
              </div>
            </div>
          </div>
          <div className="card mb-4 box-shadow">
            <div className="card-body">
              <div className="displayflex">
                <h5>Task</h5>
                <h6 id="h1">View all</h6>
              </div>
              <div id="div2">
                <h6 id="h2">Today</h6>
              </div>
              <div className="displayflex">
                <div className="text2" id="h2">
                  Create new Task
                </div>
                {/* <div class="text2">4238</div> */}
                <img src="/assets/img/inactive.png" />
              </div>
              <hr />
              <div className="displayflex">
                <div className="card1 card1-left">
                  <label className="label">
                    <span>Finish ticket update</span>
                    <input
                      type="checkbox"
                      defaultChecked="checked"
                      className="checkbox"
                    />
                    <span className="check-mark" />
                  </label>
                </div>
                <div id="div3">URGENT</div>
              </div>
              <hr />
              <div className="displayflex">
                <div className="card1 card1-left">
                  <label className="label">
                    <span>Create new ticket example</span>
                    <input type="checkbox" className="checkbox" />
                    <span className="check-mark" />
                  </label>
                </div>
                <div id="div4">NEW</div>
              </div>
              <hr />
              <div className="displayflex">
                <div className="card1 card1-left">
                  <label className="label">
                    <span>Update ticket report</span>
                    <input type="checkbox" className="checkbox" />
                    <span className="check-mark" />
                  </label>
                </div>
                <div id="div5">DEFAULT</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminSidebar>
  );
};

export default Setting;
