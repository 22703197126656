import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CustomerHoc from "../../../../Hoc/CustomerHoc";
import "../../Summary/style.scoped.css";
import { yupResolver } from "@hookform/resolvers/yup";
import PhoneCode from "react-phone-code";

import "../../Summary/summaryStyle.scoped.css";

import {
  addCustomerNewCard,
  createSubscriptionService,
  customerLoginService,
  customerRegisterChangeMobile,
  customerRegisterFinalUserCreate,
  customerRegisterResendOtp,
  customerRegisterStep1Service,
  customerRegisterVerifyOtp,
  customerVehicleExist,
  getAllPlanService,
} from "../../../../services/customer.service";
import _, { isInteger } from "lodash";
import { centToDollar } from "../../../../helpers/stripe";
import { addVehicleRedux } from "../../../../redux/reducers/CustomerVehicleSlice";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../../../redux/reducers/LoaderSlice";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import "../register.scoped.css";
import {
  get2wheelerMake,
  get2wheelerModel,
  get4wheelerMake,
  get4wheelerModel,
} from "../../../../services/all.service";
import {
  step1Schema,
  step1Schema2,
  step1Schema3,
  step1Schema3ForFreeUser,
} from "../register.const";
import { loggedInUser } from "../../../../redux/reducers/UserSlice";
import { History } from "../../../../helpers/history";
import countryCodeArray from "../../../../helpers/countryCode";
import { checkVehicleTypeUsingVehicleName } from "../../../../helpers/helper";
import { setUserInLocalStorage } from "../../../../context/AppGlobalContext";
import { tempLoggedInUser } from "../../../../redux/reducers/TempUserSlice";

const getSchemaByStep = (step, user) => {
  if (step === 1) {
    return step1Schema;
  } else if (step === 2) {
    return step1Schema2;
  } else if (step === 3) {
    if (user === "free") {
      return step1Schema3ForFreeUser;
    }
    return step1Schema3;
  }
};

const RegisterStep1 = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [originalPlanList, setOriginalPlanList] = useState([]);
  const [planList, setPlanList] = useState([]);
  const [selectedPlanPriceId, setSelectedPlanPriceId] = useState({
    id: "0",
    value: 0,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(
      getSchemaByStep(
        currentStep,
        selectedPlanPriceId.id === "0" ? "free" : "paid"
      )
    ),
  });
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const [paymentIntent, setPaymentIntent] = useState();
  const [modelList, setModelList] = useState([]);
  const [makeList, setMakeList] = useState([]);
  const formValue = getValues();
  const [resendOtpTime, setResendOtpTime] = useState(false);
  const [allStepApiResponse, setAllStepApiResponse] = useState({});

  const handleFirstStep = async (data) => {
    console.log(data, "handleFirstStep");
    const reqData = {
      country_code: data?.country_code,
      contact_number: data?.contact_number,
      device_type: "web",
      user_id: allStepApiResponse?.user_id,
    };
    let res;
    if (allStepApiResponse?.user_id) {
      res = await customerRegisterChangeMobile(reqData);
    } else {
      res = await customerRegisterStep1Service(reqData);
    }

    if (res?.status === 1) {
      const { user_id, mobile_otp, country_code, mobile_number } = res;
      setCurrentStep(currentStep + 1);
      setAllStepApiResponse({
        ...allStepApiResponse,
        user_id,
        mobile_otp,
        country_code,
        mobile_number,
      });
    }
  };

  const handleStep2 = async (data) => {
    console.log(data, "handleStep2");

    try {
      console.log(data, "handleStep2");
      const { first, second, third, fourth, fifth, sixth } = data;
      const otp = parseInt(
        `${first}${second}${third}${fourth}${fifth}${sixth}`
      );
      const reqData = {
        mobile_otp: otp,
        user_id: allStepApiResponse?.user_id,
      };
      const res = await customerRegisterVerifyOtp(reqData);
      if (res?.status === 1) {
        setCurrentStep(currentStep + 1);
      }
      console.log(res, "otp res");
    } catch (error) { }
  };

  const handleResetOtp = async () => {
    try {
      const data = await customerRegisterResendOtp(allStepApiResponse?.user_id);
      if (data.status === 1) {
        setAllStepApiResponse({
          ...allStepApiResponse,

          mobile_otp: data?.otp,
        });
      }
    } catch (error) { }
  };

  const addCustomerProfile = async (data) => {
    try {
      const reqData = {
        user_id: allStepApiResponse?.user_id,
        make: data?.vehicle_make,
        model: data?.vehicle_model,
        number_plate: data?.vehicle_name,
        email_address: data?.email,
        first_name: data?.first_name,
        last_name: data?.last_name,
        dob: data?.dob,
        gender: data?.gender === "1" ? "M" : data?.gender === "2" ? "F" : "O",
        password: data?.password,
        is_premium: selectedPlanPriceId.id === "0" ? 0 : 1,
      };
      const res = await customerRegisterFinalUserCreate(reqData);
      console.log(res, "customer final create");
      return res;
    } catch (error) { }
  };

  const handleLogin = async (data) => {
    const res = await customerLoginService({
      email_address: data?.email,
      password: data?.password,
      logon_type: "CS",
      device_type: "WEB",
    });

    if (res?.status === 1) {
      // dispatch(tempLoggedInUser(res));
      // setUserInLocalStorage(res);
      dispatch(loggedInUser(res));
      return res;
    } else {
      return false;
    }
  };

  const handleStep3 = async (data) => {
    try {
      const vehicleExist = await customerVehicleExist(data?.vehicle_name);

      if (vehicleExist?.is_exists === 0) {
        const customerAdded = await addCustomerProfile(data);
        if (customerAdded?.user_id) {
          const customerLogin = await handleLogin(data);
          if (customerLogin) {
            if (selectedPlanPriceId.id) {
              const email_address = data?.email;
              // register api call
              if (email_address) {
                const cardExpiry = data.cardExpiry;
                const cardData = {
                  cardNumber: data?.cardNumber,
                  cardExpMonth: cardExpiry[0] + cardExpiry[1],
                  cardExpYear: cardExpiry[3] + cardExpiry[4],
                  cardCVC: data?.cvv,
                  cardName: data?.cardHolderName,
                  email: email_address,
                  isDefault: 1,
                };
                const cardAdded = await handleAddCard(cardData, data, {
                  email_address,
                });
              }
            } else {
              toast.error("Please choose plan");
            }
          }
        }
      } else {
        toast.error("Vehicle already exists!");
      }
    } catch (error) { }
  };

  const getAllSubsPlan = async () => {
    try {
      const res = await getAllPlanService();
      if (_.isArray(res?.data?.data)) {
        setPlanList(res?.data?.data);
        setOriginalPlanList(res?.data?.data);
      }
    } catch (error) { }
  };

  const saveVehicleInRedux = (formData) => {
    const data = {
      vehicle_name: formData?.vehicle_name,
      vehicle_make: formData?.vehicle_make,
      vehicle_model: formData?.vehicle_model,
    };
    dispatch(addVehicleRedux(data));
    return data;
  };

  const handleAddCard = async (cardData, formData, otherData) => {
    try {
      const customerVehicle = saveVehicleInRedux(formData);
      if (selectedPlanPriceId.id === "0") {
        History.push("/customer/free/summary");
        return;
      }
      if (cardData?.cardNumber && cardData?.cardCVC) {
        const res = await addCustomerNewCard(cardData);
        if (res?.cardId?.id) {
          confirmPayment(res?.cardId?.id, customerVehicle, otherData);
        }
        return res?.cardId?.id;
      }
    } catch (error) { }
  };

  const confirmPayment = async (
    paymentMethodId,
    customerVehicle,
    { email_address }
  ) => {
    const { clientSecret } = await createSubscriptionService(
      selectedPlanPriceId.id,
      customerVehicle,
      { email: email_address }
    );
    dispatch(showLoader());
    let { error, paymentIntent } = await stripe.confirmCardPayment(
      clientSecret,
      {
        payment_method: paymentMethodId,
      }
    );

    if (error) {
      // show error and collect new card details.
      return;
    }
    dispatch(hideLoader());
    console.log(paymentIntent, "paymentIntent");
    setPaymentIntent(paymentIntent);
  };

  const getModelList = async (vehicleType) => {
    try {
      let getService;
      if (vehicleType === 2) {
        getService = get2wheelerModel;
      } else {
        getService = get4wheelerModel;
      }

      const getModel = await getService();
      if (getModel?.data) {
        setModelList(getModel?.data);
      }
      console.log(getModel, "getModel");
      return getModel;
    } catch (error) { }
  };

  const getMakeList = async (vehicleType) => {
    try {
      let getService;
      if (vehicleType === 2) {
        getService = get2wheelerMake;
      } else {
        getService = get4wheelerMake;
      }

      const getMake = await getService();
      if (getMake?.data) {
        setMakeList(getMake?.data);
      }
      return getMake;
    } catch (error) { }
  };

  const handleVehicleOnChange = async (vehicleType) => {
    dispatch(showLoader());
    const vehicleId = parseInt(vehicleType);
    const model = await getModelList(vehicleId);
    const make = await getMakeList(vehicleId);
    if (model?.status === 1 && make?.status === 1) {
      dispatch(hideLoader());
    }
  };

  useEffect(() => {
    if (currentStep === 3) {
      getAllSubsPlan();
    }
  }, [currentStep]);

  useEffect(() => {
    if (isInteger(resendOtpTime)) {
      let myInterval = setInterval(() => {
        if (resendOtpTime > 0) {
          setResendOtpTime(resendOtpTime - 1);
        }
      }, 1000);
      return () => {
        clearInterval(myInterval);
      };
    }
  });

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "cardExpiry" && type === "change") {
        let expiryDateData = value[name];
        console.log(
          "debug",
          expiryDateData.length,
          expiryDateData,
          expiryDateData.charAt(2)
        );
        if (expiryDateData.length === 2) {
          expiryDateData = expiryDateData + "/";
          console.log(expiryDateData, "debug3");
          setValue(name, expiryDateData);
        } else {
          if (expiryDateData.length === 3 && expiryDateData.charAt(2) === "/") {
            expiryDateData = expiryDateData.replace("/", "");
            console.log(expiryDateData, "debug2");
            setValue(name, expiryDateData);
          }
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const handleVehicleType = () => {
    const { vehicle_name } = getValues();
    const vehicleTypeId = checkVehicleTypeUsingVehicleName(vehicle_name);
    if (vehicleTypeId) {
      const x = originalPlanList.filter(item => {
        if (item?.product?.name?.toLowerCase().includes("bike") && vehicleTypeId === 2) {
          return item;
        }
        if (item?.product?.name?.toLowerCase().includes("car") && vehicleTypeId === 4) {
          return item;
        }
        return false;
      });
      setPlanList(x);
    } else {
      setPlanList([]);
      toast.error("Vehicle number not valid!");
    }
  };

  const otpJumpToPreviousOnBackspaceKey = (event) => {
    const pressKey = event.key;
    if (pressKey === "Backspace") {
      console.log(pressKey, "pressKey");
      event.currentTarget.previousSibling.focus();
    }
  };

  const otpOnPress = (event) => {
    const pressKey = event.key;
    const isNumber = isFinite(pressKey);

    if (isNumber) {
      event.currentTarget.nextSibling.focus();
    }

    otpJumpToPreviousOnBackspaceKey(event);
  };

  const { onBlur, ...restVehicle } = register("vehicle_name");
  const { ...restVehicleMake } = register("vehicle_make");
  const { ...restVehicleModel } = register("vehicle_model");

  if (paymentIntent && paymentIntent.status === "succeeded") {
    // return <Redirect to={{ pathname: "/customer/paid/summary" }} />;
    History.push("/customer/paid/summary");
  }

  return (
    // <form className="container mt-5" id="regform">
    <div className="container mt-5" id="regform">
      <h4 className="mt-4">
        <span id="contentchange">Welcome1</span>{" "}
        <span style={{ float: "right" }} id="number">
          1/3
        </span>
      </h4>
      <div style={{ marginTop: 10 }} id="steps">
        <span className={`step ${currentStep === 1 && "active"}`} />
        <span className={`step ${currentStep === 2 && "active"}`} />
        <span className={`step ${currentStep === 3 && "active"}`} />
      </div>
      <div className="tab" style={{ marginTop: 60 }}>
        {currentStep === 1 && (
          <form onSubmit={handleSubmit(handleFirstStep)}>
            <div id="contact">
              <div style={{ fontSize: 15, marginTop: 10 }}>
                Your roadside assistance needs covered for as low as $5 per
                month. Or join us for free without the perks today.{" "}
              </div>
              <div style={{ fontSize: 15, marginTop: 40, color: "coral" }}>
                Continue with your mobile number{" "}
              </div>

              <div
                className="floating-label"
                style={{ width: "50%", maxWidth: "200px" }}
              >
                <select
                  className="floating-select user"
                  // onclick="this.setAttribute('value', this.value);"
                  defaultValue={"65"}
                  style={{
                    background: "#fff",
                    color: "#000",
                    opacity: 1,
                    marginTop: 7,
                    padding: 21,
                  }}
                  placeholder="Country Code"
                  {...register("country_code")}
                >
                  {/* <option disabled> Gender</option> */}
                  {countryCodeArray.map((item) => {
                    return (
                      <option value={item?.code}>{`${item?.name} (+${item?.code
                        })`}</option>
                    );
                  })}
                </select>
                <span id="errorfname" style={{ color: "crimson" }}>
                  {errors.country_code?.message}
                </span>
              </div>

              <div className="floating-form">
                <div className="floating-label">
                  <input
                    placeholder="Contact Number"
                    id="contact_number"
                    className="floating-input user"
                    type="text"
                    maxLength={10}
                    name="contact_number"
                    style={{ background: "#fff", color: "#000", opacity: 1 }}
                    onkeypress="return event.charCode >= 48 && event.charCode<=57"
                    {...register("contact_number")}
                  />
                </div>
                <div id="error" style={{ color: "coral" }}>
                  {errors.contact_number?.message}
                </div>
              </div>
            </div>
            <div style={{ overflow: "auto", marginTop: 20, marginBottom: 30 }}>
              <div>
                <button type="submit">Send otp</button>
              </div>
            </div>
          </form>
        )}

        {currentStep === 2 && (
          <form onSubmit={handleSubmit(handleStep2)}>
            {/* <div className="mt-5" style={{ width: "100%" }}>
              <div className="m-3">
                <button
                  className="validate"
                  id="otpshow"
                  type="button"
                  // onclick="otp('otptab', 'contact','nextBtn' ,this); return false"
                  onClick={(e) => {
                    otp("otptab", "contact", "nextBtn", e.target);
                  }}
                >
                  <b>Send OTP</b>
                </button>
              </div>
            </div> */}
            <div className="mt-5" id="otptab">
              <div className="text-left ">
                <div style={{ fontSize: 15 }}>
                  Hang in there. We sent you a code to verify your mobile
                  number,
                  <br />{" "}
                  {`${allStepApiResponse?.country_code} ${allStepApiResponse?.mobile_number
                    }`}
                </div>
                <div
                  style={{
                    color: "coral",
                    fontSize: 15,
                    marginTop: 20,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setCurrentStep(1);
                  }}
                >
                  Change number
                </div>
                <div
                  id="otp"
                  className="inputs d-flex justify-content-center mt-2"
                >
                  <input
                    className="m-1 text-center form-control rounded numeric user"
                    type="tel"
                    id="first"
                    maxLength={1}
                    onkeyup="return event.charCode >= 48 && event.charCode<=57"
                    onKeyUp={otpOnPress}
                    {...register("first")}
                  />
                  <input
                    className="m-1 text-center form-control rounded numeric user"
                    type="tel"
                    id="second"
                    maxLength={1}
                    onkeyup="return event.charCode >= 48 && event.charCode<=57"
                    onKeyUp={otpOnPress}
                    {...register("second")}
                  />
                  <input
                    className="m-1 text-center form-control rounded numeric user"
                    type="tel"
                    id="third"
                    maxLength={1}
                    onkeyup="return event.charCode >= 48 && event.charCode<=57"
                    onKeyUp={otpOnPress}
                    {...register("third")}
                  />
                  <input
                    className="m-1 text-center form-control rounded numeric user"
                    type="tel"
                    id="fourth"
                    maxLength={1}
                    onkeyup="return event.charCode >= 48 && event.charCode<=57"
                    onKeyUp={otpOnPress}
                    {...register("fourth")}
                  />
                  <input
                    className="m-1 text-center form-control rounded numeric user"
                    type="tel"
                    id="fifth"
                    maxLength={1}
                    onkeyup="return event.charCode >= 48 && event.charCode<=57"
                    onKeyUp={otpOnPress}
                    {...register("fifth")}
                  />
                  <input
                    className="m-1 text-center form-control rounded numeric user"
                    type="tel"
                    id="sixth"
                    maxLength={1}
                    onkeyup="return event.charCode >= 48 && event.charCode<=57"
                    onKeyUp={otpJumpToPreviousOnBackspaceKey}
                    {...register("sixth")}
                  />
                </div>
                <span
                  id="errorotp"
                  style={{ display: "none", color: "crimson" }}
                >
                  Enter numbers only
                </span>
              </div>
              <div>
                <div
                  className="content mt-5"
                  style={{ fontSize: 14, textAlign: "center" }}
                >
                  <span>Didn't receive code?</span>{" "}
                  <a href="#" className="text-decoration-none ms-3 text-light">
                    <b>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          if (!resendOtpTime) {
                            handleResetOtp();
                            setResendOtpTime(59);
                          }
                        }}
                      >
                        Send again
                      </span>
                      {resendOtpTime ? <span>({resendOtpTime})</span> : <></>}
                    </b>
                  </a>
                </div>
              </div>
            </div>
            <div style={{ overflow: "auto", marginTop: 20, marginBottom: 30 }}>
              <div>
                <button type="submit">Confirm</button>
              </div>
            </div>
          </form>
        )}
      </div>
      {currentStep === 3 && (
        <form onSubmit={handleSubmit(handleStep3)}>
          <div className="tab">
            <h6
              style={{ textAlign: "center", color: "coral" }}
              className="mt-5"
            >
              1. Account Information
            </h6>
            <div className="floating-form">
              <div className="floating-label">
                <input
                  className="floating-input user"
                  type="text"
                  placeholder=" "
                  name="fname"
                  style={{ background: "#fff", color: "#000", opacity: 1 }}
                  id="fname"
                  {...register("first_name")}
                />
                <span className="highlight" />
                <label style={{ color: "#000" }}>First name</label>
                <span id="errorfname" style={{ color: "crimson" }}>
                  {errors.first_name?.message}
                </span>
              </div>
              <div className="floating-label">
                <input
                  className="floating-input user"
                  type="text"
                  placeholder=" "
                  name="lname"
                  style={{ background: "#fff", color: "#000", opacity: 1 }}
                  id="lname"
                  {...register("last_name")}
                />
                <span className="highlight" />
                <label style={{ color: "#000" }}>Last name</label>
                <span id="errorfname" style={{ color: "crimson" }}>
                  {errors.last_name?.message}
                </span>
              </div>
              <div style={{ display: "flex" }}>
                <div
                  className="floating-label "
                  style={{ width: "50%", marginRight: 10 }}
                >
                  <input
                    className="floating-input user"
                    type="date"
                    // onclick="this.type = 'date'"
                    placeholder=" "
                    style={{ background: "#fff", color: "#000", opacity: 1 }}
                    id="datefield"
                    {...register("dob")}
                  />
                  <span className="highlight" />
                  <label style={{ color: "#000" }}>Date of Birth</label>
                  <span id="errorfname" style={{ color: "crimson" }}>
                    {errors.dob?.message}
                  </span>
                </div>
                <div
                  className="floating-label"
                  style={{ width: "50%", marginLeft: 10 }}
                >
                  <select
                    className="floating-select user"
                    // onclick="this.setAttribute('value', this.value);"

                    style={{
                      background: "#fff",
                      color: "#000",
                      opacity: 1,
                      marginTop: 7,
                      padding: 21,
                    }}
                    placeholder="Gender"
                    {...register("gender")}
                  >
                    {/* <option disabled> Gender</option> */}
                    <option value={1}>Male</option>
                    <option value={2}>Female</option>
                    <option value={3}>Others</option>
                  </select>
                  <span id="errorfname" style={{ color: "crimson" }}>
                    {errors.gender?.message}
                  </span>
                </div>
              </div>
              <div className="floating-label">
                <input
                  className="floating-input user"
                  type="email"
                  placeholder=" "
                  name="email"
                  style={{ background: "#fff", color: "#000", opacity: 1 }}
                  id="email_address"
                  {...register("email")}
                />
                <span className="highlight" />
                <label style={{ color: "#000" }}>Email</label>
                <span id="errorfname" style={{ color: "crimson" }}>
                  {errors.email?.message}
                </span>
              </div>
              <div className="floating-label">
                <input
                  className="floating-input user"
                  type="password"
                  placeholder=" "
                  name="password"
                  id="password"
                  style={{ opacity: 1, background: "#fff", color: "#000" }}
                  {...register("password")}
                />
                <i
                  className="bi bi-eye-slash"
                  style={{
                    cursor: "pointer",
                    marginLeft: "-30px",
                    color: "black",
                  }}
                  id="togglePassword"
                />
                <span className="highlight" />
                <label style={{ color: "#000" }}>Password</label>
                <span id="errorfname" style={{ color: "crimson" }}>
                  {errors.password?.message}
                </span>
              </div>
              <div className="floating-label">
                <input
                  className="floating-input user"
                  type="password"
                  placeholder=" "
                  name="password"
                  id="password_confirm"
                  style={{ opacity: 1, background: "#fff", color: "#000" }}
                  {...register("confirmPassword")}
                />
                <i
                  className="bi bi-eye-slash"
                  style={{
                    cursor: "pointer",
                    marginLeft: "-30px",
                    color: "black",
                  }}
                  id="confirmPassword"
                />
                <span className="highlight" />
                <label style={{ color: "#000" }}>Confirm Password</label>
                <span id="errorfname" style={{ color: "crimson" }}>
                  {errors.confirmPassword?.message}
                </span>
              </div>
              <h6
                style={{ textAlign: "center", color: "coral", marginTop: 40 }}
              >
                2. Vehicle Information
              </h6>
              <div className="floating-label">
                <input
                  placeholder=" "
                  className="floating-input user"
                  type="text"
                  name="vehicle"
                  style={{ background: "#fff", color: "#000", opacity: 1 }}
                  maxLength={10}
                  // {...register("vehicle_name")}
                  {...restVehicle}
                  onBlur={(e) => {
                    onBlur(e);
                    handleVehicleType();
                  }}
                />
                <span className="highlight" />
                <label style={{ color: "#000" }}>Vehicle plate number</label>
                <span id="errorfname" style={{ color: "crimson" }}>
                  {errors.vehicle_name?.message}
                </span>
              </div>


              <div className="floating-label">
                <input
                  type="text"
                  style={{ background: "#fff", color: "#000", opacity: 1 }}
                  className="floating-input user"
                  placeholder="Vehicle Make"
                  onBlur={(e) => {
                    onBlur(e);
                  }}
                  {...restVehicleMake}
                />
                <span id="errorfname" style={{ color: "crimson" }}>
                  {errors.vehicle_make?.message}
                </span>
              </div>

              <div className="floating-label">
                <input
                  type="text"
                  className="floating-input user"
                  style={{ background: "#fff", color: "#000", opacity: 1 }}
                  placeholder="Vehicle Model"
                  onBlur={(e) => {
                    onBlur(e);

                  }}
                  {...restVehicleModel}
                />
                <span id="errorfname" style={{ color: "crimson" }}>
                  {errors.vehicle_model?.message}
                </span>
              </div>
              <h6
                style={{ textAlign: "center", color: "coral" }}
                className="mt-5"
              >
                3.Select Membership
              </h6>
              {/* <a href="#"  style="text-decoration: none;">  */}
              {/* <div
                className="card p-2 mt-3 textdata"
                style={{
                  backgroundColor: "#fff",
                  color: "#000",
                  fontSize: 15,
                  borderRadius: 10,
                }}
                id="premium1"
              >
                <div className="row mt-2">
                  <div className="col-4 text-center">
                    <img
                      src="/assets/customerImg/d1.JPG"
                      style={{ width: 100, height: 100 }}
                    />
                  </div>
                  <div className="col-8 text-center" style={{ margin: "auto" }}>
                    <div>Car Premium</div>
                    <h4>
                      <b>$50</b>
                    </h4>
                    <div>per year</div>
                  </div>
                </div>
              </div> */}
              {/* </a>  */}
              {/* <a href="#" style="text-decoration: none;"> */}

              {planList.map((item) => {
                return (
                  <div
                    className={`card d-none d-md-flex p-2 mt-3 textdata ${selectedPlanPriceId.id ===
                      item?.id && "planCardActive"}`}
                    key={item?.id}
                    style={{
                      backgroundColor: "#fff",
                      color: "#000",
                      fontSize: 15,
                      borderRadius: 10,
                    }}
                    onClick={() => {
                      setSelectedPlanPriceId({
                        id: item?.id,
                        value: centToDollar(item?.unit_amount_decimal),
                      });
                    }}
                    id="premium2"
                  >
                    <div className="row mt-2">
                      <div className="col-4 text-center">
                        <img
                          src={item?.product?.images[0]}
                          style={{ width: 100, height: 100 }}
                          alt="check"
                        />
                      </div>
                      <div
                        className="col-8 text-center"
                        style={{ margin: "auto" }}
                      >
                        <div>{item?.product?.name}</div>
                        <h4>
                          <b>${centToDollar(item?.unit_amount_decimal)}</b>
                        </h4>
                        <div>per {item?.recurring?.interval}</div>
                      </div>
                    </div>
                  </div>
                );
              })}

              <div className="row m-0 d-flex flex-row d-md-none">
                {planList.map((item) => {
                  return (
                    <div className="p-1 col-6">
                      <div
                        className={`card  p-2 mt-3 textdata ${selectedPlanPriceId.id ===
                          item?.id && "planCardActive"}`}
                        key={item?.id}
                        style={{
                          backgroundColor: "#fff",
                          color: "#000",
                          fontSize: 15,
                          borderRadius: 10,
                        }}
                        onClick={() => {
                          setSelectedPlanPriceId({
                            id: item?.id,
                            value: centToDollar(item?.unit_amount_decimal),
                          });
                        }}
                        id="premium2"
                      >
                        <div className="mt-2">
                          <div className="text-center">
                            <div className="mb-1">{item?.recurring?.interval === "month" ? "Monthly Premium" : "Yearly Premium"}</div>
                            <h3 style={{fontWeight:700}}>
                              ${centToDollar(item?.unit_amount_decimal)}/{item?.recurring?.interval === "month" ? "mth" : "yr"}
                            </h3>
                            <img
                              src={item?.product?.images[0]}
                              style={{ width: 50, height: 50 }}
                              alt="check"
                            />
                          </div>
                          {/* <div>{item?.product?.name}</div> */}

                          {/* <div>per {item?.recurring?.interval}</div> */}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>

              {/* <div
                className="card p-2 mt-3 textdata"
                style={{
                  backgroundColor: "#fff",
                  color: "#000",
                  fontSize: 15,
                  borderRadius: 10,
                }}
                id="premium2"
              >
                <div className="row mt-2">
                  <div className="col-4 text-center">
                    <img
                      src="/assets/customerImg/dd.JPG"
                      style={{ width: 100, height: 100 }}
                    />
                  </div>
                  <div className="col-8 text-center" style={{ margin: "auto" }}>
                    <div>Bike Premium</div>
                    <h4>
                      <b>$50</b>
                    </h4>
                    <div>per year</div>
                  </div>
                </div>
              </div> */}
              {/* </a> */}
              {/* <a href="#"  style="text-decoration: none;">  */}
              <div
                className={`card p-2 mt-3 text-center textdata ${selectedPlanPriceId.id ===
                  "0" && "planCardActive"}`}
                style={{
                  backgroundColor: "#fff",
                  color: "#000",
                  fontSize: 15,
                  borderRadius: 10,
                }}
                onClick={() => {
                  setSelectedPlanPriceId({ id: "0", value: centToDollar(0) });
                }}
                id="freepremium"
              >
                <div>
                  <b>Try it for free, get Premium later!</b>
                </div>
              </div>
              {/* </a> */}
              <h6
                style={{ textAlign: "center", color: "coral" }}
                className="mt-5"
              >
                4. Billing Information
              </h6>
              {/* <div class="floating-form"> */}
              <div className="floating-label">
                <input
                  placeholder=" "
                  className="floating-input text user"
                  type="text"
                  id="card_number"
                  name="card_number"
                  style={{ background: "#fff", color: "#000", opacity: 1 }}
                  maxLength={19}
                  {...register("cardNumber")}
                />
                <span className="highlight" />
                <label style={{ color: "#000" }}>Card number</label>
                <span id="errorfname" style={{ color: "crimson" }}>
                  {errors.cardNumber?.message}
                </span>
              </div>
              <div id="error_card" />
              <div style={{ display: "flex" }}>
                <div
                  className="floating-label"
                  style={{ width: "50%", marginRight: 10 }}
                >
                  <input
                    placeholder="mm/yy"
                    className="floating-input user"
                    type="text"
                    name="expire"
                    style={{ background: "#fff", color: "#000", opacity: 1 }}
                    id="txtDate"
                    maxLength={5}
                    {...register("cardExpiry")}
                  />
                  <i
                    className="bi bi-calendar-range date-picker"
                    style={{
                      marginLeft: "-29px",
                      cursor: "pointer",
                      color: "#000",
                    }}
                  />
                  <span className="highlight" />
                  <label style={{ color: "#000", fontSize: 12 }}>
                    Card expiry date
                  </label>
                  <span id="errorfname" style={{ color: "crimson" }}>
                    {errors.cardExpiry?.message}
                  </span>
                </div>
                <div
                  className="floating-label"
                  style={{ width: "50%", marginLeft: 10 }}
                >
                  <input
                    placeholder=" "
                    className="floating-input user"
                    type="password"
                    name="cvv"
                    style={{ background: "#fff", color: "#000", opacity: 1 }}
                    maxLength={3}
                    id="cvv"
                    {...register("cvv")}
                  />
                  <i
                    className="bi bi-credit-card"
                    style={{
                      marginLeft: "-29px",
                      cursor: "pointer",
                      color: "#000",
                    }}
                  />
                  <span className="highlight" />
                  <label style={{ color: "#000", fontSize: 12 }}>CVV</label>
                  <span id="errorfname" style={{ color: "crimson" }}>
                    {errors.cvv?.message}
                  </span>
                </div>
              </div>
              <div className="floating-label">
                <input
                  placeholder=" "
                  className="floating-input user"
                  type="text"
                  name="name"
                  style={{ background: "#fff", color: "#000", opacity: 1 }}
                  id="card_name"
                  {...register("cardHolderName")}
                />
                <i
                  className="bi bi-person"
                  style={{
                    marginLeft: "-29px",
                    cursor: "pointer",
                    color: "#000",
                  }}
                />
                <span className="highlight" />
                <label style={{ color: "#000" }}>Name</label>
                <span id="errorfname" style={{ color: "crimson" }}>
                  {errors.cardHolderName?.message}
                </span>
              </div>
              <span>*No charges will be made when you try it for free!</span>
              {/* <div class="mt-5">*No charges will be made when you try it for free!</div> */}
              {/* </div>  */}
              <h6
                style={{ textAlign: "center", color: "coral" }}
                className="mt-5"
              >
                5. Order Summary
              </h6>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control "
                  placeholder="Add A Promo Code"
                  aria-label="Add A Promo Code"
                  aria-describedby="promo_apply"
                  style={{
                    backgroundColor: "#fff",
                    color: "#000",
                    opacity: 1,
                    margin: 0,
                    padding: 30,
                    border: "none",
                    borderRadius: "10px 0 0 10px",
                  }}
                />
                <div className="input-group-append">
                  <span
                    className="input-group-text"
                    id
                    style={{
                      color: "black",
                      backgroundColor: "coral",
                      border: "none",
                      borderRadius: "0px 10px 10px 0px",
                    }}
                  >
                    <button style={{ marginTop: 0, padding: 0 }}>Apply</button>
                  </span>
                </div>
              </div>
              <div
                className="card p-2"
                style={{ backgroundColor: "#fff", color: "#000", fontSize: 15 }}
              >
                <div>eTow premium Yearly Subscription</div>
                <div className="row mt-2">
                  <div className="col">Subtotal</div>
                  <div className="col text-right pr-4">
                    ${selectedPlanPriceId.value}
                  </div>
                </div>
                <div className="row" style={{ color: "coral" }}>
                  <div className="col">Coupon Discount</div>
                  <div className="col text-right pr-4">-$0</div>
                </div>
                <div className="row mt-4">
                  <div className="col">
                    <b>Total</b>
                  </div>
                  <div className="col text-right pr-4">
                    <b>${selectedPlanPriceId.value}</b>
                  </div>
                </div>
              </div>
              <div className="mt-5">
                By continuing, you agree to eTow's Terms and Conditions and
                Privacy Policy.
              </div>
            </div>
          </div>
          {/* <label for="ice-cream-choice">Choose a flavor:</label>
          <input
            list="ice-cream-flavors"
            id="ice-cream-choice"
            name="ice-cream-choice"
            style={{
              backgroundColor: "#fff",
              color: "#000",
              opacity: 1,
              margin: 0,
              padding: 30,
              border: "none",
              borderRadius: "10px 0 0 10px",
              height: "30px",
            }}
          /> */}

          <div style={{ overflow: "auto", marginTop: 20, marginBottom: 30 }}>
            <div>
              <button type="submit">Confirm</button>
            </div>
          </div>
        </form>
      )}

      {currentStep === 4 && (
        <div className="tab">
          <div>
            <div className="text-center">
              <h5 style={{ color: "coral" }} className="mt-5">
                Payment Completed!
              </h5>
              <div className="mt-2">
                <i
                  className="fas fa-check"
                  style={{
                    fontSize: 64,
                    backgroundColor: "green",
                    color: "white",
                    padding: 10,
                    borderRadius: "100%",
                  }}
                />
              </div>
              <div className="mt-4" style={{ fontSize: 15 }}>
                Thank you! Your payment is successful. A confirmation email has
                been sent to <b>Customer@email.com</b>
              </div>
              <h5 style={{ color: "coral" }} className="mt-5">
                Order Summary
              </h5>
            </div>
            <div
              className="card p-2 mt-3"
              style={{ backgroundColor: "#fff", color: "#000", fontSize: 15 }}
            >
              <div>
                <div style={{ fontSize: 10 }}>Name</div>
                <div style={{ fontSize: 15 }}>First &amp; Last Name</div>
              </div>
              <div className="mt-2">
                <div style={{ fontSize: 10 }}>Vehicle Plate Number</div>
                <div style={{ fontSize: 15 }}>SGH 9912 U</div>
              </div>
              <div className="mt-2">
                <div style={{ fontSize: 10 }}>Type of Membership</div>
                <div style={{ fontSize: 15 }}>Car Premium</div>
              </div>
              <div className="mt-2">
                <div style={{ fontSize: 10 }}>Payment Method</div>
                <div style={{ fontSize: 15 }}>Mastercard ....9302</div>
              </div>
            </div>
            <div
              className="card p-2 mt-3"
              style={{ backgroundColor: "#fff", color: "#000", fontSize: 15 }}
            >
              <div>
                <div style={{ fontSize: 10 }}>Order ID</div>
                <div style={{ fontSize: 15 }}>20210115001</div>
              </div>
              <div className="mt-2">
                <div style={{ fontSize: 10 }}>Order Date and Time</div>
                <div style={{ fontSize: 15 }}>
                  15-01-2021 &nbsp;&nbsp;&nbsp;&nbsp;15:05
                </div>
              </div>
            </div>
            <div
              className="card p-2 mt-3"
              style={{ backgroundColor: "#fff", color: "#000", fontSize: 15 }}
            >
              <div style={{ fontSize: 15 }}>
                eTow Car/Bike Premium Yearly Subscription
              </div>
              <div className="row mt-2">
                <div className="col">Subtotal</div>
                <div className="col text-right pr-4">$50.00</div>
              </div>
              <div className="row" style={{ color: "coral" }}>
                <div className="col">Coupon Discount</div>
                <div className="col text-right pr-4">-$10.00</div>
              </div>
              <div className="row mt-4">
                <div className="col">
                  <b>Total</b>
                </div>
                <div className="col text-right pr-4">
                  <b>$40.00</b>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* <div style={{ overflow: "auto", marginTop: 20, marginBottom: 30 }}>
        <div>
          <button type="button" id="nextBtn" onclick="nextPrev(1);">
            Confirm111
          </button>
        </div>
      </div> */}
      {/* Circles which indicates the steps of the form: */}
    </div>
  );
};

export default CustomerHoc(RegisterStep1);
