import { getUserInLocalStorage } from "../context/AppGlobalContext";
import { loggedInUser } from "../redux/reducers/UserSlice";

export class History {
  static historyInstance = null;
  static dispatchInstance = null;

  static push(page) {
    History.historyInstance.push(page);
  }

  static dispatchUserData = (data) => {
    const userPrevData = getUserInLocalStorage();
    History.dispatchInstance(loggedInUser({ ...userPrevData, ...data }));
  };
}
