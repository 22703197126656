import { createSlice } from "@reduxjs/toolkit";
import {
  getUserInLocalStorage,
  setUserInLocalStorage,
  removeUserInLocalStorage,
} from "../../context/AppGlobalContext";

import { toast } from "react-toastify";
import { RoleBasedLoginRedirect } from "../../helpers/helper";

const userInitData =
  getUserInLocalStorage() === null ? {} : getUserInLocalStorage();

const initialState = {
  data: userInitData,
};

export const UserSlice = createSlice({
  name: "User",
  initialState,
  reducers: {
    loggedInUser: (state, { payload }) => {
      state.data = payload;
      setUserInLocalStorage(payload);
    },
    loggedOutUser: (state) => {
      let role = state?.data?.role_id;
      state.data = {};
      removeUserInLocalStorage();
      toast.success("Logout Successfully!");
      // RoleBasedLoginRedirect(role);
    },
  },
});

export const { loggedInUser, loggedOutUser } = UserSlice.actions;

export default UserSlice.reducer;
