import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {},
};

export const CustomerVehicleSlice = createSlice({
  name: "CustomerVehicleSlice",
  initialState,
  reducers: {
    addVehicleRedux: (state, { payload }) => {
      state.data = payload;
    },
    deleteVehicleRedux: (state) => {
      state.data = {};
    },
  },
});

export const {
  addVehicleRedux,
  deleteVehicleRedux,
} = CustomerVehicleSlice.actions;

export default CustomerVehicleSlice.reducer;
