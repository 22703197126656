import _ from "lodash";
import { serialize } from "object-to-formdata";
import { Link, Redirect } from "react-router-dom";
import { getUserInLocalStorage } from "../context/AppGlobalContext";
import { loggedOutUser } from "../redux/reducers/UserSlice";
import { UserRoles } from "./Constant";
import { History } from "./history";

export const convertJsonToFormData = (data) => {
  const formData = serialize(data, { indices: true });

  return formData;
};

export const accountType = {
  PremiumUser: "premium-user",
  FreeUser: "free-user",
  ServiceProvider: "service-provider",
};

export const wheelerType = {
  FourWheeler: "four-wheeler",
  TwoWheeler: "two-wheeler",
};

export const pricingPlan = {
  PremiumPlan: "premium-plan",
  ServicePlan: "service-plan",
};

export const slugToTitle = (slug) => {
  var words = slug.split("-");

  for (var i = 0; i < words.length; i++) {
    var word = words[i];
    words[i] = word.charAt(0).toUpperCase() + word.slice(1);
  }

  return words.join(" ");
};

export const removeDashboardStrFromRoute = (str) => {
  return str.replace("/dashboard", "");
};

export const handleLogout = () => {
  const { role_id } = getUserInLocalStorage();
  if (role_id === UserRoles.Customer) {
    History.push("/");
  } else {
    History.push("/superadmin");
  }
  console.log(role_id, "data");
  History.dispatchInstance(loggedOutUser());
};

export const createBreadCrumb = ({
  title,
  href,
  currentPageTitle,
  linkData,
}) => {
  return (
    <>
      {Array.isArray(linkData) && linkData.length > 0 ? (
        linkData.map((item) => (
          <>
            <Link to={item?.href}>{item?.title} &nbsp; / &nbsp;</Link>{" "}
          </>
        ))
      ) : title ? (
        <>
          <Link to={href}>{title} &nbsp; / &nbsp;</Link>
        </>
      ) : null}
      {`${currentPageTitle ? currentPageTitle : ""}`}
    </>
  );
};

export const RoleBasedLoginRedirect = (role) => {
  let link = "/";
  if (role === UserRoles.Admin) {
    link = "/";
  } else if (role === UserRoles.Customer) {
    link = "/customer/dashboard";
  }
  History.push(link);
};

export const uniqueId = () => {
  return "id" + new Date().getTime();
};

export const redirectWhenCustomerVehicleNotExist = (customerVehicle) => {
  if (_.isEmpty(customerVehicle)) {
    History.push("/customer/dashboard");
  }
};

export const checkVehicleTypeUsingVehicleName = (vehicleNumber) => {
  let vehicleName = vehicleNumber.toUpperCase();
  const vehicleFirstLetter = vehicleName.split("");
  if (vehicleFirstLetter.length) {
    if (vehicleFirstLetter[0] === "F" || vehicleFirstLetter[0] === "A") {
      return 2;
    } else if (vehicleFirstLetter[0] === "S" || vehicleFirstLetter[0] === "E") {
      return 4;
    }
  }

  return false;
};
