// In your Redux slice file, e.g., userSlice.js

import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
const url = process.env.REACT_APP_BACKEND_URL;

const initialState = {
  loading: false,
  error: null,
  userData: null,
};

export const Getcategory=createAsyncThunk('Getcategory',async()=>{
        try {
           
            // const headers = {
            //     'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
            //     'Content-Type': 'application/json', // Adjust content type as needed
            //   };
    
            const response = await axios.get(url+"/superusers/get_category");
            console.log(response.data)
            return response.data
           
          } catch (error) {
            console.log(error,'Error')
              if (error.response!=undefined){
                    console.log('fail',error)
                    throw error.response
                  }
              else{
                    console.log(error)
                  throw error.response
              }
          } 
    })

export const GetcategorySlice = createSlice({
    name:'api',
    initialState:{
        isLoading:false,
        data:null,
        isError:false
    },
    extraReducers:(builder)=>{
        builder.addCase(Getcategory.fulfilled,(state,action)=>{
            state.isLoading = false;
            state.data= action.payload;
        });
        builder.addCase(Getcategory.pending,(state,action)=>{
            state.isLoading = true
        });
        builder.addCase(Getcategory.rejected,(state,action)=>{
            console.log(action.state)
            state.isError=true;
            console.log(action.payload);

        });
    }
});


export default GetcategorySlice.reducer;

