import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CheckEditBannerName, banneruploadService, getAllInvoices, getAllTrips } from "../../../../services/all.service";
// import { appendScript } from " ../../../utils/appendScript";
import { appendScript } from "../../account/viewAccount";
// import AdminSidebar from "../../layouts/AdminSidebar";
import AdminSidebar from "../../AdminSidebar";
import { toast } from "react-toastify";
import "./deals.scoped.css";
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { Icon } from "@iconify/react/dist/iconify.js";
import { History } from "../../../../helpers/history";
import { createBreadCrumb } from "../../../../helpers/helper";
const url = process.env.REACT_APP_BACKEND_URL;

const Addnewcarosel = () => {
  const edit = useLocation()
  const [invoiceData, setInvoiceData] = useState([]);
  const [images, setImages] = useState(null)
  const [updateimage, setupdateimage] = useState(null)
  const [banner_image_url2, setbanner_image_url2] = useState(null)
  const [imagename, setImagename] = useState('')
  const history = useHistory();

  useEffect(() => {
    if (invoiceData.length > 0) {
      appendScript(
        "https://cdn.datatables.net/1.10.9/js/jquery.dataTables.min.js"
      );
      appendScript("/assets/js/record3.js");
    }
  }, [invoiceData]);
  // console.log(invoiceData, "invoiceData");


  useEffect(() => {
    // console.log(edit.state, "__________________________________________check____________________________________");
    const EditDetails=async()=>{
    if (edit.state != null) {
      // console.log('editable', edit.state.id)
      try {

        let jsond = { id: edit.state.id }
        // console.log("image_url_1", jsond)

        const response = await axios.post(url + "/superusers/get_banner_by_id", jsond);
        if (response.status = 1) {
          setformdata({
            "mlf_id": response.data[0].mfl_id,
            "mfl_banner_name": response.data[0].mfl_banner_name,
            "mfl_banner_mobile": response.data[0].mfl_banner_mobile,
            "mfl_deals_email": response.data[0].mfl_banner_email,
            "mfl_banner_pro_details": response.data[0].mfl_banner_pro_details,
            "mfl_banner_service": response.data[0].mfl_banner_service
          })

          setupdateimage(response.data[0].mfl_banner_url)
          const parts = response.data[0].mfl_banner_url.split("/");
          const lastIndex = parts.length - 1;
          setImagename(parts[lastIndex])
        }

      } catch (error) {
        // console.log(error, 'Error')
        if (error.response != undefined) {
          console.log('fail', error)

        }
        else {
          console.log(error)
        }
      }
    }}
    EditDetails()

  }, []);


  const [formdata, setformdata] = useState({
    "mlf_id": "",
    "mfl_banner_name": "",
    "mfl_banner_mobile": "",
    "mfl_deals_email": "",
    "mfl_banner_pro_details": "",
    "mfl_banner_service": "",
  })

  const [error, seterror] = useState({
    "mfl_banner_name": "",
    "images": ""
  })


  const ValueHandler = (e) => {
    const fieldName = e.target.name;
    const value = e.target.value;
    seterror({
      "mfl_banner_name": "",
      "images": ""
    })
    // console.log(fieldName, value)
    setformdata((prevFormData) => ({
      ...prevFormData,
      [fieldName]: value
    }));
  }


  const handleCompanyLogo = async (file) => {
    try {
      const res = await banneruploadService(file[0]);
      if (res?.status === 1) {
        // setCompanyLogo(res?.absolute_path);
        return res?.absolute_path
      }
    } catch (error) { }
  };


  const Fileupload = (e) => {
    // Access the uploaded file(s) from the event object
    setImagename(null)
    setImages(null)
    setupdateimage(null)
    seterror({
      "mfl_banner_name": "",
      "images": ""
    })
    const files = e.target.files;
    setImages(files);

  };


  const Submithandler = async (val) => {
    // console.log('--------------------------submit------------------------------')
    try {
      if (formdata.mfl_banner_name == '' || images == null) {
        seterror({
          mfl_banner_name: formdata.mfl_banner_name == "" && "Title name is requied",
          images: images == null && "Banner image is requied"
        })
      }
      else {
        const response1 = await axios.post(url + "/superusers/check_banner_name", { banner_name: formdata.mfl_banner_name });
        if (response1.status == 1) {
          const headers = {
            'Content-Type': 'multipart/form-data',
          };
          const jsondata = new FormData()

          // console.log("12345678909876543234567890", images)
          let banner_image_url = await handleCompanyLogo(images);
          // console.log("image_url_1", banner_image_url)

          jsondata.append("banner_name", formdata.mfl_banner_name)
          jsondata.append("banner_image_url", banner_image_url)
          jsondata.append("banner_pro_details", formdata.mfl_banner_pro_details)
          jsondata.append("banner_service", formdata.mfl_banner_service)
          jsondata.append("banner_mobile", formdata.mfl_banner_mobile)
          jsondata.append("banner_email", formdata.mfl_deals_email)
          jsondata.append("banner_is_publish", val)

          const response = await axios.post(url + "/superusers/add_banner", jsondata, { headers });
          if (response.status = 1) {
            // window.location.href="/dashboard/marketplace"
            if (val == 1) {
              // history.push("/dashboard/marketplace")
              window.location.href="/dashboard/marketplace"
            }
            else {
              let vvl = response.banner_id
              History.push({ pathname: "/dashboard/deals/viewnewcarosel", state: { id: vvl } })
            }

          }
          // console.log(response)

        }
        else {
          seterror({
            mfl_banner_name: "Name already exits",
            images: images == null && ""
          })
          toast.error("Banner title already exits")
        }

      }

    } catch (error) {
      // console.log(error, 'Error')
      if (error.response != undefined) {
        console.log('fail', error)

      }
      else {
        console.log(error)
      }
    }
  }

  const Edithandler = async (val) => {
    // console.log("______________________________________________edit_____________________________________")
    try {
      if (formdata.mfl_banner_name == '' || (images == null && updateimage == null)) {
        seterror({
          mfl_banner_name: formdata.mfl_banner_name == "" && "Title name is requied",
          images: images != null|| updateimage!=null? "": "Banner image is requied"
        })
      }
      else {
        const response = await CheckEditBannerName({ banner_name: formdata.mfl_banner_name, banner_id: formdata.mlf_id })
        if (response.status == 1) {
          const headers = {
            'Content-Type': 'multipart/form-data',
          };
          const jsondata = new FormData()

          if (images != '') {
            if (images != null) {
              let banner_image_url = await handleCompanyLogo(images);
              console.log(banner_image_url)
              jsondata.append("banner_image_url", banner_image_url)
              setbanner_image_url2(banner_image_url)
            }
          }

          // console.log(updateimage == null ? banner_image_url2 : updateimage, "________________________________-", banner_image_url2, updateimage)
          jsondata.append("banner_id", formdata.mlf_id)
          jsondata.append("banner_name", formdata.mfl_banner_name)
          jsondata.append("banner_service", formdata.mfl_banner_service)
          jsondata.append("banner_pro_details", formdata.mfl_banner_pro_details)
          jsondata.append("banner_email", formdata.mfl_deals_email)
          jsondata.append("banner_mobile", formdata.mfl_banner_mobile)
          updateimage != null && jsondata.append("banner_image_url", updateimage)
          let endpoint = val == 1 ? "/superusers/update_banner" : "/superusers/update_banner_for_preview"
          const response = await axios.post(url + endpoint, jsondata, { headers });
          // console.log(response)
          if (response.status == 1 && val == 1) {
            // history.push("/dashboard/marketplace")
            window.location.href="/dashboard/marketplace"
          } else {
            History.push({ pathname: "/dashboard/deals/viewnewcarosel", state: { id: response.banner_id, type: "edit" } })
          }
        }

      }
    } catch (error) {
      // console.log(error, 'Error')
      if (error.response != undefined) {
        console.log('fail', error)

      }
      else {
        console.log(error)
      }
    }
  }
  const removeImage = (index) => {
    setImages(null);
  };
  const removeImage2 = (index) => {
    setupdateimage(null);
  };

  return (
    <AdminSidebar
    title={createBreadCrumb({
      currentPageTitle: edit.state===undefined?`Add Carousel`:"Edit Carousel",
      title: "Deals",
      href: "/dashboard/marketplace",
    })}
      childrenContainerClass="container-fluid flex-grow-1 container-p-y"
    >
      <div className="container">
        <div className="card-deck mb-3 text-center">
          <div className="card mb-4 box-shadow">
            <div className="card-body overflow-auto">
              <div id="div1">
                <div id="div2">Add New Carousel</div>
              </div>
              <br />
              <br />
              {/* Two grid for the input fields  */}
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label for="exampleInputEmail1">Title</label>
                    <input
                      type="email"
                      name="mfl_banner_name"
                      value={formdata.mfl_banner_name}
                      onChange={ValueHandler}
                      className="custom-input"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      style={error.mfl_banner_name != "" ? { border: "2px solid red" } : {}}
                    // placeholder="Enter Title"
                    />
                    {error.mfl_banner_name != "" && <span style={{ color: 'red' }}>{error.mfl_banner_name}</span>}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label for="exampleInputPassword1">Service</label>
                    <input
                      type="text"
                      name="mfl_banner_service"
                      value={formdata.mfl_banner_service}
                      onChange={ValueHandler}
                      className="custom-input"
                      id="exampleInputPassword1"
                    // placeholder="Enter Service"
                    />
                  </div>
                </div>

                <div className="col-md-6 image-uploadBlock">
                  <div className="form-group">
                    <label for="exampleInputEmail1">images</label>
                    <div className="image-upload" style={error.images != "" ? { border: "2px solid red" } : {}}>
                      <div className="imageUploadElements">
                        <label for="file-input">
                          <img
                            src="/assets/img/imageUpload.png"
                            alt="upload"
                            className="upload-icon"
                          />
                        </label>
                        <input
                          id="file-input"
                          type="file"
                          className="hidden"
                          onChange={Fileupload}
                        />
                        <label
                          type="button"
                          htmlFor="file-input"
                          class="btn btn-block btn-success"
                        >
                          Upload Image
                        </label>
                      </div>
                      <div className="p-2">
                        {(images != null) && <span className="btn btn-light w-100 d-flex justify-content-between">{images[0].name} <Icon onClick={() => removeImage()} icon="streamline:delete-1" /> </span>}
                        {(updateimage != null) && <span className="btn btn-light w-100 d-flex justify-content-between">{imagename} <Icon onClick={() => removeImage2()} icon="streamline:delete-1" /> </span>}
                        {error.images != "" && <span style={{ color: 'red' }}>{error.images}</span>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />

              <br />
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label for="exampleInputEmail1">Promotion Details</label>
                    <textarea
                      type="address"
                      name="mfl_banner_pro_details"
                      value={formdata.mfl_banner_pro_details}
                      onChange={ValueHandler}
                      className="custom-input"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                    // placeholder="Enter Promotion Details"
                    />
                  </div>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label for="exampleInputEmail1">Phone Number</label>
                    <input
                      type="number"
                      name="mfl_banner_mobile"
                      value={formdata.mfl_banner_mobile}
                      onChange={ValueHandler}
                      className="custom-input"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      pattern="\d{10}"
                    // placeholder="Enter Phone Number"
                    />
                  </div>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label for="exampleInputEmail1">Email</label>
                    <input
                      type="email"
                      name="mfl_deals_email"
                      value={formdata.mfl_deals_email}
                      onChange={ValueHandler}
                      className="custom-input"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                    // placeholder="Enter Email"
                    />
                  </div>
                </div>
              </div>
              <br />
              {/* Submit button */}
              <div className="row">
                <div className="col-md-12">
                  <div className="actionBtns">
                    <button type="submit" onClick={() => edit.state == undefined ? Submithandler(1) : Edithandler(1)} className="btn btn-success">
                      Publish
                    </button>
                    <button
                      type="submit"
                      className="btn btn-secondary"
                      onClick={() => edit.state == undefined ? Submithandler(0) : Edithandler(0)}
                    >
                      Preview
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminSidebar>
  );
};

export default Addnewcarosel;

