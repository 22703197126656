// import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CheckDealName, CheckEditDealName, EditDeals, EditGetDeals, UpdateDealsPreview, dealsuploadService, getAllInvoices, getAllTrips } from "../../../services/all.service";
import { appendScript } from "../account/viewAccount";
import AdminSidebar from "../AdminSidebar";
import "./deals.scoped.css";
import { useDispatch, useSelector } from "react-redux";
import { Getcategory } from "../../../redux/reducers/GetCategory";
import axios from 'axios';
import { useHistory } from "react-router-dom";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { History } from "../../../helpers/history";
import { createBreadCrumb } from "../../../helpers/helper";
const url = process.env.REACT_APP_BACKEND_URL;

const Deals = ({ listData = ["test"] }) => {
  const dealsdata = useLocation()
  const [invoiceData, setInvoiceData] = useState([]);
  const [categorydata, setcategorydata] = useState({})
  const [images, setImages] = useState([])
  const [imagename, setImagename] = useState([])
  const [updateimage, setupdateimage] = useState([])

  const dispatch = useDispatch()
  const apidata = useSelector((state) => state.getcategory);
  // console.log(apidata, 'category')

  useEffect(() => {
    dispatch(Getcategory());
  }, []);


  useEffect(() => {
    if (invoiceData.length > 0) {
      appendScript(
        "https://cdn.datatables.net/1.10.9/js/jquery.dataTables.min.js"
      );
      appendScript("/assets/js/record3.js");
    }
  }, [invoiceData]);
  // console.log(invoiceData, "invoiceData");


  useEffect(() => {
    const fetchData = async () => {
    if (dealsdata.state != undefined) {
      try {
        let jsond = { deal_id: dealsdata.state.id }
        // console.log("image_url_1", jsond)

        const response = await EditGetDeals(jsond)
        // console.log(response)
        if (response.status = 1) {


          setformdata({
            "mfl_deals_id": response.data[0].mfl_id,
            "mfl_deals_title": response.data[0].mfl_deals_title,
            "mfl_deals_service": response.data[0].mfl_deals_service,
            "mfl_deals_address_1": response.data[0].mfl_deals_address_1,
            "mfl_deals_address_2": response.data[0].mfl_deals_address_2,
            "mfl_deals_listing_details": response.data[0].mfl_deals_listing_details,
            "mfl_deals_phone_no": response.data[0].mfl_deals_phone_no,
            "mfl_deals_email": response.data[0].mfl_deals_email,
            "mfl_deals_promotion_details": response.data[0].mfl_deals_promotion_details,
            "mfl_deals_about_details": response.data[0].mfl_deals_about_details,
          })
          setcategorydata(JSON.parse(response.data[0].mfl_deals_tags))
          setupdateimage([response.data[0].mfl_deals_images_url_1,
          response.data[0].mfl_deals_images_url_2,
          response.data[0].mfl_deals_images_url_3])

          const imgs = [response.data[0].mfl_deals_images_url_1.split("/")[response.data[0].mfl_deals_images_url_1.split("/").length - 1],
          response.data[0].mfl_deals_images_url_2.split("/")[response.data[0].mfl_deals_images_url_2.split("/").length - 1],
          response.data[0].mfl_deals_images_url_3.split("/")[response.data[0].mfl_deals_images_url_3.split("/").length - 1],
          ]
          // console.log("__________________________________", imgs)
          setImagename(imgs)

        }

      } catch (error) {
        // console.log(error, 'Error')
        if (error.response != undefined) {
          console.log('fail', error)

        }
        else {
          console.log(error)
        }
      }
    }
  }
  fetchData()
  }, []);

  // console.log(imagename, "+++++++++++++++++++++++++++++=")

  const Submithandler = async (val) => {
    try {


      if (formdata["mfl_deals_title"] == "" ||
        formdata["mfl_deals_service"] == "" ||
        formdata["mfl_deals_address_1"] == "" ||
        formdata["mfl_deals_address_2"] == "" ||
        formdata["mfl_deals_listing_details"] == "" ||
        formdata["mfl_deals_phone_no"] == "" ||
        formdata["mfl_deals_email"] == "" ||
        formdata["mfl_deals_promotion_details"] == "" ||
        formdata["mfl_deals_about_details"] == "" ||
        Object.keys(categorydata).length == 0 ||
        images.length == 0) {
        seterror({
          "mfl_deals_title": formdata["mfl_deals_title"] == "" ? "Title is required" : "",
          "mfl_deals_service": formdata["mfl_deals_service"] == "" ? "Service is required" : "",
          "mfl_deals_address_1": formdata["mfl_deals_address_1"] == "" ? "address 1 is required" : "",
          "mfl_deals_address_2": formdata["mfl_deals_address_2"] == "" ? "address 2 is required" : "",
          "mfl_deals_listing_details": formdata["mfl_deals_listing_details"] == "" ? "List details is required" : "",
          "mfl_deals_phone_no": formdata["mfl_deals_phone_no"] == "" ? "Phone number is required" : "",
          "images": images.length == 0 ? "Images is required" : "",
          "mfl_deals_email": formdata["mfl_deals_email"] == "" ? "Email is required" : "",
          "mfl_deals_promotion_details": formdata["mfl_deals_promotion_details"] == "" ? "Promotion is required" : "",
          "mfl_deals_about_details": formdata["mfl_deals_about_details"] == "" ? "About deals is required" : "",
          "category": Object.keys(categorydata).length == 0 ? "Catgory tags is required" : ""
        })
      }
      else {
        const response = await CheckDealName({ deals_name: formdata["mfl_deals_title"] });
        // console.log(response.status == 1, { deals_name: formdata["mfl_deals_title"] })
        if (response.status === 1) {
          const headers = {
            'Content-Type': 'multipart/form-data',
          };
          const jsondata = new FormData()

          // console.log("12345678909876543234567890", images[0])
          let image_url_1 = await handleCompanyLogo(images[0]);
          let image_url_2 = await handleCompanyLogo(images[1]);
          let image_url_3 = await handleCompanyLogo(images[2]);
          // console.log("image_url_1", image_url_1)

          let tag_data = JSON.stringify(categorydata)
          // console.log("1234567890",tag_data)
          // console.log("1234567890",typeof(tag_data))
          // tag_data = JSON.parse(tag_data)
          // console.log("1234567890",tag_data)
          // console.log("1234567890",typeof(tag_data))
          jsondata.append("mfl_deals_title", formdata.mfl_deals_title)
          jsondata.append("mfl_deals_service", formdata.mfl_deals_service)
          jsondata.append("mfl_deals_tags", tag_data)
          jsondata.append("mfl_deals_images_url_1", image_url_1)
          jsondata.append("mfl_deals_images_url_2", image_url_2)
          jsondata.append("mfl_deals_images_url_3", image_url_3)
          jsondata.append("mfl_deals_address_1", formdata.mfl_deals_address_1)
          jsondata.append("mfl_deals_address_2", formdata.mfl_deals_address_2)
          jsondata.append("mfl_deals_listing_details", formdata.mfl_deals_listing_details)
          jsondata.append("mfl_deals_promotion_details", formdata.mfl_deals_promotion_details)
          jsondata.append("mfl_deals_about_details", formdata.mfl_deals_about_details)
          jsondata.append("mfl_deals_phone_no", formdata.mfl_deals_phone_no)
          jsondata.append("mfl_deals_email", formdata.mfl_deals_email)
          jsondata.append("mfl_is_publish", val)

          const response = await axios.post(url + "/superusers/add_deals", jsondata, { headers });
          // console.log(response)
          if (response.status == 1) {
            if (val == 1) {
              // History.push("/dashboard/marketplace")
              window.location.href="/dashboard/marketplace"
            }
            else {
              history.push({ pathname: "/dashboard/deals/mobilenewcarosel", state: { id: response.deal_id } })
            }
          }
        }
      }
    } catch (error) {
      // console.log(error, 'Error')
      if (error.response != undefined) {
        console.log('fail', error)

      }
      else {
        console.log(error)
      }
    }
  }

  const [formdata, setformdata] = useState({
    "mfl_deals_title": "",
    "mfl_deals_service": "",
    "mfl_deals_address_1": "",
    "mfl_deals_address_2": "",
    "mfl_deals_listing_details": "",
    "mfl_deals_phone_no": "",
    "mfl_deals_email": "",
    "mfl_deals_promotion_details": "",
    "mfl_deals_about_details": "",
  })

  const [error, seterror] = useState({
    "mfl_deals_title": "",
    "mfl_deals_service": "",
    "mfl_deals_address_1": "",
    "mfl_deals_address_2": "",
    "mfl_deals_listing_details": "",
    "mfl_deals_phone_no": "",
    "mfl_deals_email": "",
    "images": "",
    "mfl_deals_promotion_details": "",
    "mfl_deals_about_details": "",
    "category": "",
  })
  const history = useHistory();

  const ValueHandler = (e) => {
    const fieldName = e.target.name;
    const value = e.target.value;
    seterror({
      "mfl_deals_title": "",
      "mfl_deals_service": "",
      "mfl_deals_address_1": "",
      "mfl_deals_address_2": "",
      "mfl_deals_listing_details": "",
      "mfl_deals_phone_no": "",
      "mfl_deals_email": "",
      "images": "",
      "mfl_deals_promotion_details": "",
      "mfl_deals_about_details": "",
      "category": "",
    })
    // console.log(fieldName, value)
    setformdata((prevFormData) => ({
      ...prevFormData,
      [fieldName]: value
    }));
  }
  console.log(error)
  const handleCompanyLogo = async (file) => {
    try {
      const res = await dealsuploadService(file[0]);
      if (res?.status === 1) {
        // setCompanyLogo(res?.absolute_path);
        return res?.absolute_path
      }
    } catch (error) { }
  };


  const Fileupload = (e) => {
    // Access the uploaded file(s) from the event object
    seterror({
      "mfl_deals_title": "",
      "mfl_deals_service": "",
      "mfl_deals_address_1": "",
      "mfl_deals_address_2": "",
      "mfl_deals_listing_details": "",
      "mfl_deals_phone_no": "",
      "mfl_deals_email": "",
      "images": "",
      "mfl_deals_promotion_details": "",
      "mfl_deals_about_details": "",
      "category": "",
    })
    const files = e.target.files;
    // console.log("fffffffffffffffff:", files);
    // setImages([files]);
    if (images.length < 3 && updateimage.length == 0) {
      const newImages = [...images];
      newImages.push(files);
      // for (let i = 0; i < files.length; i++) {
      //   const file = files[i];
      //   console.log("Uploaded file:", file);


      // }
      setImages(newImages);
      // console.log("setImages", setImages)
    }
  };

  const removeImage = (index) => {
    setImages([]);
  };


  const removeImage2 = (index) => {
    setupdateimage([]);
    setImagename([]);
    
  };


  const CategorycheckboxHandler = (e) => {
    const fieldName = e.target.id;
    const value = e.target.value;
    seterror({
      "mfl_deals_title": "",
      "mfl_deals_service": "",
      "mfl_deals_address_1": "",
      "mfl_deals_address_2": "",
      "mfl_deals_listing_details": "",
      "mfl_deals_phone_no": "",
      "mfl_deals_email": "",
      "images": "",
      "mfl_deals_promotion_details": "",
      "mfl_deals_about_details": "",
      "category": "",
    })

    if (e.target.checked) {
      setcategorydata((prevFormData) => ({
        ...prevFormData,
        [fieldName]: value
      }));
    } else {
      setcategorydata((prevFormData) => {
        const updatedFormData = { ...prevFormData };
        delete updatedFormData[fieldName];
        return updatedFormData;
      });
    }
  };

  const Edithandler = async (val) => {
    try {
      if (formdata.mfl_deals_title == '' || (imagename.length == 0 && images.length==0) ||
        formdata.mfl_deals_title == "" ||
        formdata.mfl_deals_service == "" ||
        formdata.mfl_deals_address_1 == "" ||
        formdata.mfl_deals_address_2 == "" ||
        formdata.mfl_deals_listing_details == "" ||
        formdata.mfl_deals_phone_no == "" ||
        formdata.mfl_deals_email == "" ||
        formdata.mfl_deals_promotion_details == "" ||
        Object.keys(categorydata).length == 0 ||
        formdata.mfl_deals_about_details == ""
      ) {
        seterror({
          "mfl_deals_title": formdata["mfl_deals_title"] == "" ? "Title is required" : "",
          "mfl_deals_service": formdata["mfl_deals_service"] == "" ? "Service is required" : "",
          "mfl_deals_address_1": formdata["mfl_deals_address_1"] == "" ? "address 1 is required" : "",
          "mfl_deals_address_2": formdata["mfl_deals_address_2"] == "" ? "address 2 is required" : "",
          "mfl_deals_listing_details": formdata["mfl_deals_listing_details"] == "" ? "List details is required" : "",
          "mfl_deals_phone_no": formdata["mfl_deals_phone_no"] == "" ? "Phone number is required" : "",
          "images": images.length != 0 ||updateimage.length!=0 ? "" : "Images is required",
          "mfl_deals_email": formdata["mfl_deals_email"] == "" ? "Email is required" : "",
          "mfl_deals_promotion_details": formdata["mfl_deals_promotion_details"] == "" ? "Promotion is required" : "",
          "mfl_deals_about_details": formdata["mfl_deals_about_details"] == "" ? "About deals is required" : "",
          "category": Object.keys(categorydata).length == 0 ? "Catgory tags is required" : ""
        })
      }
      else {
        const response = await CheckEditDealName({ deals_name: formdata["mfl_deals_title"], deals_id: formdata.mfl_deals_id });
        if (response.status === 1) {
          const headers = {
            'Content-Type': 'multipart/form-data',
          };
          const jsondata = new FormData()

          if (images != null) {
            if (images.length!=0) {
              let image_url_1 = await handleCompanyLogo(images[0]);
              let image_url_12 = await handleCompanyLogo(images[1]);
              let image_url_13 = await handleCompanyLogo(images[2]);
              setupdateimage([image_url_1, image_url_12, image_url_13])
              jsondata.append("mfl_deals_images_url_1", image_url_1)
              jsondata.append("mfl_deals_images_url_2", image_url_12)
              jsondata.append("mfl_deals_images_url_3", image_url_13)
            }
          }

          // console.log(updateimage, '------------------------------------------')
          let tags_data2 = JSON.stringify(categorydata)
          // console.log("1234567890",tags_data2)
          // console.log("1234567890",typeof(tags_data2))
          tags_data2 = JSON.parse(tags_data2)
          // console.log("1234567890",JSON.stringify(categorydata))
          // console.log("1234567890",typeof(categorydata))

          jsondata.append("mfl_deals_id", formdata.mfl_deals_id)
          jsondata.append("mfl_deals_title", formdata.mfl_deals_title)
          jsondata.append("mfl_deals_service", formdata.mfl_deals_service)
          jsondata.append("mfl_deals_tags", JSON.stringify(categorydata))
          images.length == 0 && jsondata.append("mfl_deals_images_url_1", updateimage[0])
          images.length == 0 && jsondata.append("mfl_deals_images_url_2", updateimage[1])
          images.length == 0 && jsondata.append("mfl_deals_images_url_3", updateimage[2])
          jsondata.append("mfl_deals_address_1", formdata.mfl_deals_address_1)
          jsondata.append("mfl_deals_address_2", formdata.mfl_deals_address_2)
          jsondata.append("mfl_deals_listing_details", formdata.mfl_deals_listing_details)
          jsondata.append("mfl_deals_promotion_details", formdata.mfl_deals_promotion_details)
          jsondata.append("mfl_deals_about_details", formdata.mfl_deals_about_details)
          jsondata.append("mfl_deals_phone_no", formdata.mfl_deals_phone_no)
          jsondata.append("mfl_deals_email", formdata.mfl_deals_email)

          const response = dealsdata.state !== undefined ? await EditDeals(jsondata) : await UpdateDealsPreview(jsondata);
          if (response.status == 1 && val==1) {
            history.push("/dashboard/marketplace")
          } else {
            History.push({ pathname: "/dashboard/deals/mobilenewcarosel", state: { id: response.deal_id, type: "edit" } })
          }
        }

      }
    } catch (error) {
      // console.log(error, 'Error')
      if (error.response != undefined) {
        console.log('fail', error)

      }
      else {
        console.log(error)
      }
    }
  }


  return (
    <AdminSidebar
      title={createBreadCrumb({
        currentPageTitle: dealsdata.state===undefined?`Add deal`:"Edit deal",
        title: "Deals",
        href: "/dashboard/marketplace",
      })}
      childrenContainerClass="container-fluid flex-grow-1 container-p-y"
    >
      <div className="container">
        <div className="card-deck mb-3 text-center">
          <div className="card mb-4 box-shadow">
            <div className="card-body overflow-auto">
              <div id="div1">
                <div id="div2">Add New Listing</div>
              </div>
              <br />
              <br />
              {/* Two grid for the input fields  */}
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label for="exampleInputEmail1">Title</label>
                    <input
                      type="text"
                      className="custom-input"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      name="mfl_deals_title"
                      value={formdata.mfl_deals_title}
                      onChange={ValueHandler}
                    />
                    {error.mfl_deals_title != "" && <span style={{ color: 'red' }}>{error.mfl_deals_title}</span>}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label for="exampleInputPassword1">Service</label>
                    <input
                      type="text"
                      className="custom-input"
                      id="exampleInputPassword1"
                      name="mfl_deals_service"
                      value={formdata.mfl_deals_service}
                      onChange={ValueHandler}
                    // placeholder="Enter Service"
                    />
                    {error.mfl_deals_service != "" && <span style={{ color: 'red' }}>{error.mfl_deals_service}</span>}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="tags">Tags</label>
                    {/* Column-based checkbox" buttons */}
                    <div className="row" id="tags">
                      <div className="col-md-12">
                        {apidata.data != null && apidata.data !=undefined &&
                          apidata.data.map((data) => (
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="tags"
                                id={data.mfl_id}
                                value={data.mfl_category_name}
                                onChange={CategorycheckboxHandler}
                                checked={Object.keys(categorydata).includes(`${data.mfl_id}`)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={data.mfl_id}
                              >
                                {data.mfl_category_name}
                              </label>
                            </div>
                          ))}
                        {error.category != "" && <span style={{ color: 'red' }}>{error.category}</span>}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 image-uploadBlock">
                  <div className="form-group">
                    <label for="exampleInputEmail1">Images (3 Max)</label>
                    <div className="image-upload">
                      <div className="imageUploadElements">
                        <label for="file-input">
                          <img
                            src="/assets/img/imageUpload.png"
                            alt="upload"
                            className="upload-icon"
                          />
                        </label>
                        <input
                          id="file-input"
                          type="file"
                          onChange={Fileupload}
                          className="hidden"
                        />
                        <label
                          type="button"
                          class="btn btn-block btn-success"
                          htmlFor="file-input"
                        >
                          Upload Image
                        </label>
                      </div>
                      <div className="p-1 w-100">
                        {images.length!=0&&<div className="row m-0 justify-content-end w-100"> <Icon onClick={() => removeImage()} icon="streamline:delete-1" />  </div>}
                        {images.map((data, i) => <><span className="btn btn-light w-100 d-flex justify-content-between">{data[0].name} </span></>)}
                      
                        {imagename.length!=0&&<div className="row m-0 justify-content-end w-100"> <Icon onClick={() => removeImage2()} icon="streamline:delete-1" />  </div>}
                        {imagename.map((data, i) => <><span className="btn btn-light w-100 d-flex justify-content-between">{data} </span></>)}
                      </div>
                    </div>
                    {error.images != "" && <span style={{ color: 'red' }}>{error.images}</span>}

                  </div>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label for="exampleInputEmail1">Address</label>
                    <input
                      type="address"
                      className="custom-input"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Address Line 1"
                      name="mfl_deals_address_1"
                      value={formdata.mfl_deals_address_1}
                      onChange={ValueHandler}
                    />
                    {error.mfl_deals_address_1 != "" && <span style={{ color: 'red' }}>{error.mfl_deals_address_1}</span>}
                    <br />
                    <input
                      type="address"
                      className="custom-input"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Address Line 2"
                      name="mfl_deals_address_2"
                      value={formdata.mfl_deals_address_2}
                      onChange={ValueHandler}
                    />
                    {error.mfl_deals_address_2 != "" && <span style={{ color: 'red' }}>{error.mfl_deals_address_2}</span>}
                  </div>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label for="exampleInputEmail1">Listing Details</label>
                    <textarea
                      type="address"
                      className="custom-input"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      name="mfl_deals_listing_details"
                      value={formdata.mfl_deals_listing_details}
                      onChange={ValueHandler}
                    // placeholder="Enter Listing Details"
                    />
                    {error.mfl_deals_listing_details != "" && <span style={{ color: 'red' }}>{error.mfl_deals_listing_details}</span>}
                  </div>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label for="exampleInputEmail1">Promotion Details</label>
                    <textarea
                      type="text"
                      className="custom-input"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      name="mfl_deals_promotion_details"
                      value={formdata.mfl_deals_promotion_details}
                      onChange={ValueHandler}
                    // placeholder="Enter Promotion Details"
                    />
                    {error.mfl_deals_promotion_details != "" && <span style={{ color: 'red' }}>{error.mfl_deals_promotion_details}</span>}
                  </div>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label for="exampleInputEmail1">About Details</label>
                    <textarea
                      type="address"
                      className="custom-input"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      name="mfl_deals_about_details"
                      value={formdata.mfl_deals_about_details}
                      onChange={ValueHandler}
                    // placeholder="Enter About Details"
                    />
                    {error.mfl_deals_about_details != "" && <span style={{ color: 'red' }}>{error.mfl_deals_about_details}</span>}
                  </div>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label for="exampleInputEmail1">Phone Number</label>
                    <input
                      type="number"
                      className="custom-input"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      name="mfl_deals_phone_no"
                      value={formdata.mfl_deals_phone_no}
                      onChange={ValueHandler}
                    // placeholder="Enter Phone Number"
                    />
                    {error.mfl_deals_phone_no != "" && <span style={{ color: 'red' }}>{error.mfl_deals_phone_no}</span>}
                  </div>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label for="exampleInputEmail1">Email</label>
                    <input
                      type="email"
                      className="custom-input"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      name="mfl_deals_email"
                      value={formdata.mfl_deals_email}
                      onChange={ValueHandler}
                    // placeholder="Enter Email"
                    />
                    {error.mfl_deals_email != "" && <span style={{ color: 'red' }}>{error.mfl_deals_email}</span>}
                  </div>
                </div>
              </div>
              <br />
              {/* Submit button */}
              <div className="row">
                <div className="col-md-12">
                  <div className="actionBtns">
                  {/* {console.log(dealsdata.state,'----------------------------------------------------')} */}
                    <button type="submit" className="btn btn-success" onClick={() => dealsdata.state != undefined ? Edithandler(1) : Submithandler(1)}>
                      Publish
                    </button>
                    <button
                      type="button"
                      className="btn x btn-primary"
                      onClick={() => { dealsdata.state == undefined ? Submithandler(0) : Edithandler(0) }
                        // history.push("/dashboard/deals/viewnewcarosel")
                      }>
                      Preview
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminSidebar>
  );
};

export default Deals;

