import React, { useEffect, useRef, useState } from "react";
import CustomerHoc from "../../../../Hoc/CustomerHoc";
import { History } from "../../../../helpers/history";
import {
    getVehicleSubscriptionDetailsService,
} from "../../../../services/all.service";
import {
    cancelSubscription,
    getProductById,
    removeVehicle
  } from "../../../../services/customer.service";
import _ from "lodash";
import { toast } from "react-toastify";
import "./peofileView.scoped.css";
import { useParams } from "react-router-dom";
import moment from "moment";


const RemoveSubscription = () => {
    const { vehicleId, subscriptionID, productID, orderId } = useParams();
    const [subscriptionData, setSubscriptionData] = useState(null);
    const [productData, setProductData] = useState(null);
    const  [openSuccessModal, setOpenSuccessModal] = useState(false);

    useEffect(() => {
        if (vehicleId) {
            getVehicleSubscriptionDetails();
        }
    }, [vehicleId])

    useEffect(() => {
        if (productID) {
            getVehicleSubscriptionPlanDetails();
        }
    }, [productID]);

    const getVehicleSubscriptionPlanDetails =  async () => {
        try {
            const res = await getProductById(productID);
            if (!_.isEmpty(res?.data?.productDetails)) {
              setProductData(res?.data?.productDetails);
            }
        } catch(error) {
            toast.error(error?.response?.message || error?.response?.data?.message || error?.message);
        }
    }

    const getVehicleSubscriptionDetails = async () => {
        try {
            const res = await getVehicleSubscriptionDetailsService(vehicleId);
              if (!_.isEmpty(res?.data)) {
                setSubscriptionData(res?.data[0]);
              }
        } catch (error) { 
            toast.error(error?.response?.message || error?.response?.data?.message || error?.message);
        }
    };

    const handleCancelSubscription = async () => {
        try {
            if(!subscriptionID || !orderId || !vehicleId) {
                toast.error('Invalid subscription');
            }
         await cancelSubscription(subscriptionID, vehicleId, orderId);
         setOpenSuccessModal(true);
          
        } catch (error) {
            toast.error(error?.response?.message || error?.response?.data?.message || error?.message);
        }
      };

    return (
        <div className="container">
            <div className="card py-5">
                <div className="top" />
                <div style={{ textAlign: "center", padding: 16 }}>
                    {!_.isEmpty(productData?.images) && (
                        <img
                            className="img1"
                            src={productData?.images[0]}
                            alt="ima"
                        />
                    )}
                </div>
                <div className="title1">
                    <div>Type of Membership</div>
                    <p className="p12">{productData?.name}</p>
                </div>
                <div style={{ padding: 16 }}>
                    <h2 style={{ color: '#000' }}>Current Vehicle</h2>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <div style={{ flex: 1, color: '#000' }}>
                            <div>
                                <div style={{ color: '#000' }}>Vehicle Plate Number</div>
                                <h5>{subscriptionData?.mfl_user_vehicle_number}</h5>
                            </div>
                            <div>
                                <div style={{ color: '#000' }}>Member Since</div>
                                <h5>{subscriptionData?.mfl_vehicle_subscription_start ? moment(subscriptionData?.mfl_vehicle_subscription_start).format('DD MMM YYYY') : ''}</h5>
                            </div>
                        </div>
                        <div style={{ width: 1, borderLeft: '1px solid #000', height: 54, paddingRight: 16 }}></div>
                        <div style={{ flex: 1, color: '#000' }}>
                            <div>
                                <div style={{ color: '#000' }}>Vehicle Make/Model</div>
                                <h5>{subscriptionData?.mfl_vehicle_make}/{subscriptionData?.mfl_vehicle_model}</h5>
                            </div>
                            <div>
                            <div style={{ color: '#000' }}>Member Expiry</div>
                                <h5>{subscriptionData?.mfl_vehicle_subscription_end ? moment(subscriptionData?.mfl_vehicle_subscription_end).format('DD MMM YYYY') : ''}</h5>
                            </div>
                        </div>
                    </div>
                    <div style={{display:'flex', flexDirection: 'column', color:'#000', alignItems:'flex-start'}}>
                    <h4>Cancellation Process; </h4>
                    <ul style={{textAlign:'left', paddingLeft: 16}}>
                        <li>
                        We will reach out to you via email within 5 working days.
                        </li>
                        <li>
                        Please note that we have a non-refundable premium policy, however, if you have sold your vehicle or got a new vehicle, we will process your request accordingly.
                        </li>
                        <li>
                        We appreciate your valuable feedback and strive to serve you better.
                        </li>
                    </ul>
                    </div>
                    <div>
                    <button id="updateFormBtn"  className="boldText" style={{ textTransform: "unset !important" }} onClick={()=>handleCancelSubscription()}>Request Cancellation</button>
                    </div>
                </div>
            </div>
            {openSuccessModal ?<div style={{position:'absolute', background:'#ffffff80' ,top:0, bottom:0, right:0, left:0, zIndex: 9999, display: 'flex', alignItems:'center', justifyContent:'center'}}>
               <div style={{borderRadius:4, background:'#fff', overflow:'hidden'}}>
                <h2 style={{padding:16, paddingTop:32, color:'#4A4A4A'}}>
                Your request is being processed. 
                </h2>
                <button id="proceedFormBtn" className="borderRadiusZero" style={{ textTransform: "unset !important" }} onClick={()=>{setOpenSuccessModal(false); History.push('/customer/dashboard')}}>Continue</button>
               </div>
            </div>: null}
        </div>
    );
};

export default CustomerHoc(RemoveSubscription);
