import React from "react";
import MakeVehicle from "../MakeVehicle";
import AdminSidebar from "../../AdminSidebar";
import { useHistory } from "react-router-dom";
import { createBreadCrumb, slugToTitle } from "../../../../helpers/helper";
import { useParams } from "react-router-dom";

const AddModel = () => {
  const { wheelerType } = useParams();
  return (
    <>
      <AdminSidebar
        title={createBreadCrumb({
          currentPageTitle: slugToTitle(wheelerType),
          title: "Make/Model",
          href: "/dashboard/make-model",
        })}
      >
        <MakeVehicle />
      </AdminSidebar>
    </>
  );
};

export default AddModel;
