import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { centToDollar } from "../../../../helpers/stripe";
import {
  getPlanDataById,
  getSubscriptionData,
} from "../../../../services/customer.service";
import PremiumPayment from "../../Payment/PremiumPayment";
const premiumPrice = 60;
const BuyPlanPremium = () => {
  const { planId } = useParams();
  const [currentPlanData, setCurrentPlanData] = useState({
    priceData: {},
    productDetails: {},
  });
  const getPlanData = async () => {
    try {
      const data = await getPlanDataById(planId);
      if (
        !_.isEmpty(data?.data) &&
        !_.isEmpty(data?.data?.priceData) &&
        !_.isEmpty(data?.data?.productDetails)
      ) {
        setCurrentPlanData(data?.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getPlanData();
  }, []);
  const { priceData, productDetails } = currentPlanData;
  return (
    <PremiumPayment
      planName={productDetails?.name}
      planPrice={priceData?.unit_amount_decimal}
      planDescription={productDetails?.description}
      planImage={
        _.isEmpty(productDetails?.images)
          ? "/assets/customerImg//d1.JPG"
          : productDetails?.images[0]
      }
      recurringTime={priceData?.recurring?.interval}
    />
  );
};

export default BuyPlanPremium;
