import {
  getUserEmailFromLocal,
  getUserIdFromLocal,
} from "../context/AppGlobalContext";
import { accountType } from "../helpers/helper";
import { GET, POST, Upload } from "./controller.service";

export const getCustomerAllCards = () => {
  const userEmail = getUserEmailFromLocal();
  const userId = getUserIdFromLocal();
  return GET("/payment/viewAllCards", { email: userEmail, user_id: userId });
};

export const addCustomerNewCard = (data) => {
  return POST("/payment/addNewCard", data);
};

export const customerLoginService = (data) => {
  return POST("/users/signin", data);
};

export const getAllPlanService = () => {
  return GET("/payment/getCustomerAllSubscription");
};

export const createSubscriptionService = (priceId, vehicleData, other = {}) => {
  const userEmail = getUserEmailFromLocal();
  return POST("/payment/create-subscription", {
    priceId,
    email: userEmail,
    vehicleMetaData: vehicleData,
    ...other,
  });
};

export const getSubscriptionData = () => {
  const userEmail = getUserEmailFromLocal();
  return GET("/payment/subscriptions", { email: userEmail });
};

export const getInvoiceByInvId = (invoiceId) => {
  return GET("/payment/getInvoiceById", { invoiceId });
};

export const getPlanDataById = (productId) => {
  return GET("/payment/getProductById", { productId });
};

export const getProductById = (productId) => {
  return GET("/payment/getProductByProductId", { productId });
};

export const cancelSubscription = (subscriptionId, vehicleNumber, orderNumber) => {
  const userEmail = getUserEmailFromLocal();
  return POST("/payment/cancel-subscription", { subscriptionId, userEmail, vehicle_number: vehicleNumber, order_number: orderNumber });
};

export const saveInvoiceData = (data) => {
  return POST("/payment/save-invoice", data);
};

export const getFreeSubscriptionService = (userId) => {
  return GET("/payment/get-free-subscription", { userId });
};

export const removeSubscriptionService = (orderId, vehicle_number) => {
  return POST("/payment/remove-subscription", { orderId, vehicle_number });
};

export const removeVehicle = (orderId, vehicle_number) => {
  return POST("/payment/remove-vehicle", { orderId, vehicle_number });
};

export const addCustomerVehicle = (data) => {
  return POST("/superusers/addUserVehicle", data);
};

export const savedOrderInvoice = (data) => {
  return POST("/payment/saveOrderInvoice", data);
};

export const customerVehicleExist = (vehicle_number) => {
  return GET("/payment/vehicle-exist", { vehicle_number });
};

export const customerRegisterStep1Service = (data) => {
  return POST("/superusers/createUser", data);
};

export const customerRegisterVerifyOtp = (data) => {
  return POST("/superusers/veryfiotp", data);
};

export const customerRegisterChangeMobile = (data) => {
  return POST("/superusers/changeUserMobile", data);
};

export const customerRegisterResendOtp = (user_id) => {
  return POST("/superusers/sendsms", { user_id });
};

export const customerRegisterFinalUserCreate = (data) => {
  return POST("/superusers/finalUserCreate", data);
};

export const customerUpdateMembershipStatus = (is_premium, user_id) => {
  return POST("/payment/updateCustomerMembershipStatus", {
    is_premium,
    user_id,
  });
};

export const customerEditProfile = (data) => {
  return POST("/superusers/editProfile", data);
};
