import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getUserIdFromLocal } from "../../../../context/AppGlobalContext";
import CustomerHoc from "../../../../Hoc/CustomerHoc";
import { userByUserIdService } from "../../../../services/all.service";
import {
  cancelSubscription,
  customerUpdateMembershipStatus,
  getCustomerAllCards,
  getFreeSubscriptionService,
  getSubscriptionData,
  removeSubscriptionService,
  removeVehicle
} from "../../../../services/customer.service";
import "./peofileView.scoped.css";
import { centToDollar } from "../../../../helpers/stripe";
import { MainLoader } from "../../../common";
import { useDispatch, useSelector } from "react-redux";
import { History } from "../../../../helpers/history";
import { hideLoader, showLoader } from "../../../../redux/reducers/LoaderSlice";
import { addVehicleRedux } from "../../../../redux/reducers/CustomerVehicleSlice";
import { getUserTypeByRole } from "../../../../helpers/Constant";

const CustomerProfileView = () => {
  const userId = getUserIdFromLocal();
  const [userData, setUserData] = useState({});
  const [defaultCard, setDefaultCard] = useState({});
  const [subscriptions, setSubscriptions] = useState([]);
  const [freeSubscriptions, setFreeSubscriptions] = useState([]);
  const [removeVehicleSelectedVehicle, setRemoveVehicleSelectedVehicle] = useState(null);
  const dispatch = useDispatch();

  const getProfileData = async () => {
    try {
      const res = await userByUserIdService(userId);
      if (res?.status === 1 && !_.isEmpty(res?.data)) {
        setUserData(res?.data[0]);
      }
      console.log(res, "res");
    } catch (error) { }
  };

  const getDefaultCard = async () => {
    const res = await getCustomerAllCards();
    if (
      !_.isEmpty(res?.cardDetails) &&
      // !_.isEmpty(res?.cardDetails?.cardList) &&
      !_.isEmpty(res?.cardDetails?.defaultSource) &&
      _.isArray(res?.cardDetails?.paymentMethods?.data)
    ) {
      const defaultCard = res?.cardDetails?.paymentMethods?.data.filter(
        (item) => item?.id === res?.cardDetails?.defaultSource
      );
      if (!_.isEmpty(defaultCard)) {
        setDefaultCard(defaultCard[0]);
      }
    }
    return res;
  };

  const getCustomerSubscription = async () => {
    const { subscriptions } = await getSubscriptionData();

    if (subscriptions?.data) {
      setSubscriptions(subscriptions.data);
    }

    return subscriptions;
  };

  const handleCancelSubscription = async (subscriptionId, vehicle_number, order_number, cb) => {
    try {
      const cancel = await cancelSubscription(subscriptionId, vehicle_number, order_number);
      if (!_.isEmpty(cancel?.subscription)) {
        // dispatch(showLoader());
        // const cardData = await getDefaultCard();
        // const subs = await getCustomerSubscription();
        // if (!_.isEmpty(cardData?.cardDetails) && subs?.subscriptions) {
        //   dispatch(hideLoader());
        // }
        cb();
        getProfileData();
      }

      return cancel;
    } catch (error) { }
  };

  const updateCustomerMembershipStatus = async (subscriptions) => {
    let is_premium = 0;
    if (subscriptions.length > 0) {
      is_premium = 1;
    }
    const update = await customerUpdateMembershipStatus(is_premium, userId);

    return update?.status;
  };

  const getFreeSubscription = async () => {
    const res = await getFreeSubscriptionService(userId);

    setFreeSubscriptions(res?.data);
    return res?.data;
  };

  const removeRequestedVehicle = async () => {
    if (removeVehicleSelectedVehicle?.subscriptionsId) {
      await handleCancelSubscription(removeVehicleSelectedVehicle.subscriptionsId, removeVehicleSelectedVehicle.vehicleNumber, removeVehicleSelectedVehicle.invoiceNumber, () => {
        removeVehicleAction(
          removeVehicleSelectedVehicle.invoiceNumber,
          removeVehicleSelectedVehicle.vehicleNumber
        );
      });
      setRemoveVehicleSelectedVehicle(null);
    } else if (removeVehicleSelectedVehicle?.orderId) {
      await removeVehicleAction(
        removeVehicleSelectedVehicle.orderId,
        removeVehicleSelectedVehicle.vehicleNumber
      );
      setRemoveVehicleSelectedVehicle(null);
    }


  }

  const removeVehicleAction = async (orderId, vehicle_number) => {
    try {
      const res = await removeVehicle(orderId, vehicle_number);

      if (res?.status === 1) {
        dispatch(showLoader());
        const cardData = await getDefaultCard();
        const subs = await getCustomerSubscription();
        const freeSubs = await getFreeSubscription();
        if (!_.isEmpty(cardData?.cardDetails) && subs && freeSubs) {
          dispatch(hideLoader());
        }
      }
    } catch (error) {
      console.log(error)
    }
  };

  const handleUpgradePremium = (data) => {
    dispatch(addVehicleRedux(data));
    History.push("/customer/subscription/plan");
  };

  const componentInit = async () => {
    try {
      dispatch(showLoader());
      await getProfileData();
      await getDefaultCard();
      await getCustomerSubscription();
      await getFreeSubscription();
      dispatch(hideLoader());
    } catch (error) { }
  };

  useEffect(() => {
    componentInit();
  }, []);
  return (
    <>
      <div>
        <div className="container">
          {!_.isEmpty(userData) && (
            <div className="card" style={{ marginTop: "85px" }}>
              <div className="top" />
              <div className="image">
                <img
                  className="img1"
                  src={
                    userData?.user_photo ||
                    "/assets/customerImg/Default Profile Picture.svg"
                  }
                />
              </div>
              <Link to="/customer/profile/edit">
                <img
                  className="img12"
                  src="/assets/customerImg/noun_Pencil_3028277 1.png"
                />
              </Link>
              <div className="title">
                <div>{`${userData?.user_first_name} ${userData?.user_last_name
                  }`}</div>
                <p>{userData?.user_email}</p>
              </div>
              <div className="info">
                <div className="info_data">
                  <div className="data">
                    <h4>Contact Number</h4>
                    <p>{`${userData?.user_country_code} ${userData?.user_mobile
                      }`}</p>
                  </div>
                  <div id="vertical" />
                  <div className="data">
                    <h4>Password</h4>
                    <p>*** **** ****</p>
                  </div>
                </div>
              </div>
              {/* <div className="info">
                <div className="info_data">
                  <div className="data">
                    <h4>Account Type</h4>
                    <p>{`${getUserTypeByRole(userData?.user_group_id)}`}</p>
                  </div>
                  <div id="vertical" />
                  <div className="data">
                    <h4>Membership</h4>
                    <p>{userData?.is_premium === 1 ? "Premium" : "Free"}</p>
                  </div>
                </div>
              </div> */}
              <div style={{ margin: "10px 0px 0px 23px" }}>
                <p>Date Joined</p>
                <p>{moment(userData?.user_joinig_date).format("LL")}</p>
              </div>
              {!_.isEmpty(defaultCard?.card) && (
                <div style={{ margin: "18px 0px 0px 23px" }}>
                  <p>Default Payment Method</p>
                  <p>{`${_.capitalize(defaultCard?.card?.brand)} ... ${defaultCard?.card?.last4
                    }`}</p>
                </div>
              )}
            </div>
          )}
        </div>
        <br />
        <br />
        {subscriptions.map((item) => {
          return (
            <>
              <div className="container">
                <div className="card">
                  <div style={{ textAlign: "center" }}>
                    {!_.isEmpty(item?.plan?.product?.images) && (
                      <img
                        className="img1"
                        src={item?.plan?.product?.images[0]}
                        alt="ima"
                      />
                    )}
                  </div>
                  <div className="title1">
                    <div>Type of Membership</div>
                    <p className="p12">{item?.plan?.product?.name}</p>
                  </div>

                  <Link to={`/remove/subscription/${item?.metadata?.vehicle_name}/${item?.id}/${item?.plan?.product?.id}/${item?.latest_invoice?.number}`} style={{
                    textAlign: "center",
                    color: "red",
                    cursor: "pointer",
                    marginBottom: "25px"
                  }}>
                    Transfer/Cancel Membership
                  </Link>
                  <div className="info">
                    {/* <div className="info_data">
                      <div className="data">
                        <p>Status</p>
                        <h4>{_.capitalize(item?.status)}</h4>
                      </div>
                      <div id="vertical1" />
                      <div className="data">
                        <p>Amount</p>
                        <h4>${centToDollar(item?.plan?.amount_decimal)}</h4>
                      </div>
                    </div> */}
                    <div className="info_data">
                      <div className="data">
                        <p>Vehicle Plate Number</p>
                        <h4 style={{ fontSize: '18px', fontWeight: '520'}}>{item?.metadata?.vehicle_name}</h4>
                      </div>
                      <div id="vertical1" />
                      <div className="data">
                        <p>Vehicle Make/Model</p>
                        <h4 style={{ fontSize: '18px'}}>
                          {item?.metadata?.vehicle_make}/
                          {item?.metadata?.vehicle_model}
                        </h4>
                      </div>
                    </div>
                    <div className="info_data">
                      <div className="data">
                        <p>Member Since</p>
                        <h4 style={{ fontSize: '18px'}}>
                          {moment.unix(item?.current_period_start).format("LL")}
                        </h4>
                      </div>
                      <div className="data">
                        <p>Member Expiry</p>
                        <h4 style={{ fontSize: '18px'}}>
                          {moment.unix(item?.current_period_end).format("LL")}
                        </h4>
                      </div>
                    </div>
                  </div>
                  {item?.status === "active" && (
                    <>

                      <div
                        style={{
                          textAlign: "center",
                          color: "red",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setRemoveVehicleSelectedVehicle({
                            subscriptionsId: item?.id,
                            invoiceNumber: item?.latest_invoice?.number,
                            vehicleNumber: item?.metadata?.vehicle_name,
                          });

                        }}
                      >
                        Remove vehicle
                      </div>
                      <br />
                    </>
                  )}
                </div>
              </div>
              <br />
              <br />
            </>
          );
        })}

        {/* <div className="container">
          <div className="card">
            <div style={{ textAlign: "center" }}>
              <img className="img1" src="/assets/customerImg/Group 618.svg" />
            </div>
            <div className="title1">
              <div>Type of Membership</div>
              <p className="p12">Bike Premium</p>
            </div>
            <div className="info">
              <div className="info_data">
                <div className="data">
                  <p>Vehicle Plate Number</p>
                  <h4>FBD 9912 U</h4>
                </div>
                <div id="vertical1" />
                <div className="data">
                  <p>vehicle Make/Model</p>
                  <h4>Suzuki Hayabusa</h4>
                </div>
              </div>
              <div className="info_data">
                <div className="data">
                  <p>Member Since</p>
                  <h4>21 Dec 2020</h4>
                </div>
                <div className="data">
                  <p>Member Expiry</p>
                  <h4>20 Dec 2021</h4>
                </div>
              </div>
            </div>
            <div style={{ textAlign: "center", color: "red" }}>
              Remove/cancel vehicle
            </div>
          </div>
        </div> */}
        <br />
        <br />
        {/* <div class="container">
      <div class="card">



          <div class="title">
              <div>Type of Membership</div>
              <p>Car Premium</p>
          </div>


          <div class="info">
              <div class="info_data">
            
                 <div class="data">
                    <h4>Contact Number</h4>
                    <p>91234567</p>
                 </div>

                 
              <div id="vertical"></div>

                 <div class="data">
                    <h4>Password</h4>
                    <p>*** **** ****</p>
                 </div>
              </div>
            </div>



      </div>
  </div><br><br> */}
        {freeSubscriptions.map((item) => {
          return (
            <>
              <div className="container">
                <div className="card1">
                  <div className="title1">
                    <div>Type of Membership</div>
                    <p className="p12">Free Member</p>
                  </div>
                  <div className="info_data">
                    <div className="data">
                      <p>Vehicle Plate Number</p>
                      <h4>{item?.mfl_user_vehicle_number}</h4>
                    </div>
                    <div id="vertical" />
                    <div className="data">
                      <p>Vehicle Make/Model</p>
                      <h4>
                        {item?.mfl_vehicle_make}/${item?.mfl_vehicle_model}
                      </h4>
                    </div>
                  </div>
                  <div className="btn1" style={{cursor:"pointer"}}>
                    {/* <div class="div1">Upgrade To Premium</div> */}
                    {/* <Link to="/customer/subscription/plan"> */}
                    <div
                      className="div1"
                      onClick={() => {
                        const data = {
                          vehicle_name: item?.mfl_user_vehicle_number,
                          vehicle_make: item?.mfl_vehicle_make,
                          vehicle_model: item?.mfl_vehicle_model,
                        };
                        handleUpgradePremium(data);
                      }}
                    >
                      Upgrade To Premium
                    </div>
                    {/* </Link> */}
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                      color: "red",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setRemoveVehicleSelectedVehicle({
                        orderId: item?.mfl_order_id,
                        vehicleNumber: item?.mfl_vehicle_number
                      });
                    }}
                  >
                    Remove vehicle
                  </div>
                  <br />
                </div>
              </div>
              <br />
              <br />
            </>
          );
        })}

        {/* <a href="#"  style="text-decoration: none;">  */}
        <div className="d-flex justify-content-center">
        <div
          className="card p-2 mt-3 textdata justify-content-center"
          style={{
            backgroundColor: "#fff",
            color: "#000",
            fontSize: 15,
            borderRadius: 10,
            margin: "2vh",
            width:"370px",
          }}
          id="premium1"
        >
          <div className="row mt-2">
            <div
              className="col-8 text-center"
              style={{ margin: "auto" }}
              onClick={() => {
                History.push("/customer/vehicle/add");
              }}
            >
              <div style={{cursor:"pointer"}}>+ Add more vehicles</div>
            </div>
          </div>
        </div>
        </div>
      </div>
      {removeVehicleSelectedVehicle ? <div style={{ position: 'fixed', background: '#ffffff80', top: 0, bottom: 0, right: 0, left: 0, zIndex: 99, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div style={{ borderRadius: 4, background: '#fff', overflow: 'hidden', fontFamily: 'Raleway' }}>
          <div style={{ padding: 16 }}>
            <h4 style={{ color: '#000' }}>
              Are you sure you want to remove your vehicle?
            </h4>
            <ul style={{ color: '#000', padding: 0 }}>
              <ol style={{ padding: 0 }}>
                Your Vehicle  <b>{removeVehicleSelectedVehicle?.vehicleNumber}</b> will no longer be available in the application and premium membership will be void, if any.
              </ol>
              <ol style={{ padding: 0 }}>
                If you wish to transfer membership press <b>Back</b>.
              </ol>
              <ol style={{ padding: 0 }}>
                Please note that memberships are non-refundable.
              </ol>
            </ul>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <button id="removeVehicleCancel" className="borderRadiusZero" style={{ textTransform: "unset !important" }} onClick={() => { setRemoveVehicleSelectedVehicle(null) }}>Back</button>
            <button id="removeVehicleBtn" className="borderRadiusZero" style={{ textTransform: "unset !important" }} onClick={() => { removeRequestedVehicle() }}>Yes, Cancel</button>
          </div>

        </div>
      </div> : null}
    </>
  );
};

export default CustomerHoc(CustomerProfileView);
