import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useSelector } from "react-redux";
import { addCustomerNewCard } from "../../../services/customer.service";
import FormError from "../../common/Form/FormError";
import "./premiumpay.scoped.css";

const schema = yup.object().shape({
  cardNumber: yup
    .string()
    .min(16)
    .max(16)
    .required()
    .label("Card Number"),
  cardExpiry: yup
    .string()
    .min(5)
    .max(5)
    .required()
    .label("Card Expiry"),
  cvv: yup
    .string()
    .min(3)
    .max(3)
    .required()
    .label("Cvv"),
  cardHolderName: yup
    .string()
    .min(2)
    .required()
    .label("Card Holder Name"),
});

const AddCardForm = ({ handleAddCard, submitBtnRef }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const user = useSelector((state) => state.user.data);
  const allowOnlyNumber = (value) => {
    return value.replace(/[^0-9]/g, "");
  };

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "cardExpiry" && type === "change") {
        let expiryDateData = value[name];

        if (expiryDateData.length === 2) {
          expiryDateData = expiryDateData + "/";
          setValue(name, expiryDateData);
        } else {
          if (expiryDateData.length === 3 && expiryDateData.charAt(2) === "/")
            expiryDateData = expiryDateData.replace("/", "");
          setValue(name, expiryDateData);
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);
  return (
    <form
      style={{ padding: 10 }}
      id="foo"
      onSubmit={handleSubmit(handleAddCard)}
    >
      <div className="floating-label">
        <input
          placeholder="Card number"
          className="floating-input user"
          type="tel"
          pattern="[0-9.]+"
          id="card_number"
          style={{ background: "#fff", color: "#000", opacity: 1 }}
          // max={5}
          maxLength={16}
          title="Card number can only contain numeric values"
          {...register("cardNumber")}
        />
        {/* <span className="highlight" />
      <label
        className="label2"
        style={{ color: "rgb(255, 255, 255)" }}
      />
      <span
        id="errorcardnumber"
        style={{ display: "none", color: "crimson" }}
      >
        Enter numbers only
      </span> */}
        <FormError msg={errors.cardNumber?.message} />
      </div>
      <div id="error_card" />
      <div style={{ display: "flex" }}>
        <div
          className="floating-label"
          style={{ width: "50%", marginRight: 10 }}
        >
          <input
            placeholder="mm/yy"
            className="floating-input user"
            type="tel"
            style={{ background: "#fff", color: "#000", opacity: 1 }}
            id="txtDate"
            maxLength={5}
            pattern="[0-9][0-9]/[0-9][0-9]"
            title="Card expiry can only contain numeric values"
            {...register("cardExpiry")}
          />
          <i
            className="bi bi-calendar-range date-picker"
            style={{
              marginLeft: "-29px",
              cursor: "pointer",
              color: "#000",
            }}
          />
          {/* <span className="highlight" />
        <label style={{ color: "#000", fontSize: 12 }}>
          Expiration date
        </label>
        <span
          id="errorexpire"
          style={{ display: "none", color: "crimson" }}
        >
          Enter numbers and forward slash only
        </span> */}
          <FormError msg={errors.cardExpiry?.message} />
        </div>
        <div
          className="floating-label"
          style={{ width: "50%", marginLeft: 10 }}
        >
          <input
            placeholder="CVV"
            className="floating-input user"
            type="password"
            style={{ background: "#fff", color: "#000", opacity: 1 }}
            maxLength={3}
            pattern="[0-9.]+"
            title="Card cvv can only contain numeric values"
            id="cvv"
            {...register("cvv")}
          />
          <i
            className="bi bi-credit-card"
            style={{
              marginLeft: "-29px",
              cursor: "pointer",
              color: "#000",
            }}
          />
          {/* <span className="highlight" />
        <label style={{ color: "#000", fontSize: 12 }}>CVV</label>
        <span
          id="error_cvv"
          style={{ display: "none", color: "crimson" }}
        >
          Enter numbers only
        </span> */}
          <br />
          <FormError msg={errors.cvv?.message} />
        </div>
      </div>
      <div className="floating-label">
        <input
          placeholder="Card holder name"
          className="floating-input user"
          type="text"
          style={{ background: "#fff", color: "#000", opacity: 1 }}
          id="card_name"
          // pattern="[a-zA-Z]+"
          {...register("cardHolderName")}
          // title="Card holder name can contain only alphabet Ex. A-Z"
        />
        <i
          className="bi bi-person"
          style={{
            marginLeft: "-29px",
            cursor: "pointer",
            color: "#000",
          }}
        />
        {/* <span className="highlight" />
      <label style={{ color: "#000" }}>Name</label>
      <span
        id="errorcard_name"
        style={{ display: "none", color: "crimson" }}
      >
        Enter characters only
      </span> */}
        <FormError msg={errors.cardHolderName?.message} />
      </div>
      <input
        ref={submitBtnRef}
        className="d-none"
        type="submit"
        value="Add Card"
      />
    </form>
  );
};

export default AddCardForm;
