import { getUserIdFromLocal } from "../../context/AppGlobalContext";
import { handleLogout } from "../../helpers/helper";
import { History } from "../../helpers/history";
import "./customer.scoped.css";

const CustomerHoc = (WrappedComponent) => {
  const Hoc = (props) => {
    const userId = getUserIdFromLocal();
    return (
      <div className="bodyContainer">
        <div className="inlineContainer">
          <div
            style={{ margin: "32px 0 0 39px", cursor: "pointer" }}
            onClick={() => {
              History.push("/customer/dashboard");
            }}
          >
            <img
              src="/assets/customerImg/Etow icon.png"
              width={150}
              height={50}
              alt="logo"
            />
          </div>
          <div>
            {userId && (
              <>
                <button
                  id="logoutBtn"
                  onClick={() => {
                    handleLogout();
                  }}
                >
                  Logout
                </button>
                {/* <img
                  src="/assets/customerImg/Etow icon.png"
                  width={150}
                  height={50}
                  alt="logo"
                /> */}
              </>
            )}
          </div>
        </div>

        <WrappedComponent {...props} />
      </div>
    );
  };
  return Hoc;
};
export default CustomerHoc;
