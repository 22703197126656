import React from "react";

const FormError = ({ msg, ...rest }) => {
  return (
    <small className="text-danger text-left" style={{fontSize:'12px', paddingLeft:'10px',   position: "relative",
    top: "0rem"}} {...rest}>
      {msg}
    </small>
  );
};

export default FormError;
