import { AppGlobalProvider } from "./context/AppGlobalContext";
import "./App.css";
import RenderRoutes from "./routes/RenderRoutes";

function App() {
  return (
    <AppGlobalProvider>
      <RenderRoutes />
    </AppGlobalProvider>
  );
}

export default App;
