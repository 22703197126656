import React, { useEffect, useRef, useState } from "react";
import CustomerHoc from "../../../../Hoc/CustomerHoc";
import "./profileEdit.scoped.css";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import FormError from "../../../common/Form/FormError";
import {
  addCustomerNewCard,
  customerEditProfile,
  getCustomerAllCards,
} from "../../../../services/customer.service";
import { useDispatch, useSelector } from "react-redux";
import { History } from "../../../../helpers/history";
import {
  uploadService,
  userByUserIdService,
} from "../../../../services/all.service";
import _ from "lodash";
import AddCardForm from "../../Payment/AddCardForm";
import CustomerCardList from "../../Payment/CustomerCardList";
import { MainLoader } from "../../../common";
import { hideLoader, showLoader } from "../../../../redux/reducers/LoaderSlice";
import { toast } from "react-toastify";

const schema = yup.object().shape({
  first_name: yup
    .string()
    .required()
    .label("First Name"),
  last_name: yup
    .string()
    .required()
    .label("Last Name"),
  contact: yup
    .string()
    .required()
    .label("Contact Number"),
  email: yup
    .string()
    .email()
    .required()
    .label("Email"),
});

const CustomerProfileEdit = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const user = useSelector((state) => state.user.data);
  const [userData, setUserData] = useState({});
  const [isAddCardShow, setIsAddCardShow] = useState(false);
  const addCardSubmitBtnRef = useRef(null);
  const profileSubmitBtnRef = useRef(null);
  const [selectedCard, setSelectedCard] = useState("default");
  const [cardList, setCardList] = useState([]);
  const dispatch = useDispatch();
  const [companyLogo, setCompanyLogo] = useState(false);
  const companyLogoRef = useRef(null);

  const getCardImageBasedOnType = (cardType) => {
    let imgPath;
    const smallCardType = cardType.toLowerCase();
    switch (smallCardType) {
      case "visa":
        imgPath = "/assets/customerImg/visa (1) 1.svg";
        break;
      case "mastercard":
        imgPath = "/assets/customerImg/mastercard 2.svg";
        break;

      default:
        imgPath = "/assets/customerImg/card1.svg";
        break;
    }
    return imgPath;
  };

  const getProfileData = async () => {
    try {
      const res = await userByUserIdService(user?.user_id);
      if (res?.status === 1 && !_.isEmpty(res?.data)) {
        setUserData(res?.data[0]);
      }
      console.log(res, "res");
    } catch (error) {}
  };

  const getAddedCards = async () => {
    try {
      const res = await getCustomerAllCards();

      if (
        _.isObject(res?.cardDetails) &&
        !_.isEmpty(res?.cardDetails) &&
        // _.isArray(res?.cardDetails?.cardList) &&
        _.isArray(res?.cardDetails?.paymentMethods?.data)
      ) {
        const allPaymentMethod = res?.cardDetails?.paymentMethods?.data.map(
          (item) => {
            if (item?.id === res?.cardDetails?.defaultSource) {
              item["default"] = true;
              setSelectedCard(item?.id);
            } else {
              item["default"] = false;
            }
            item["logo"] = getCardImageBasedOnType(item?.card?.brand);
            return item;
          }
        );

        setCardList(allPaymentMethod);
        return allPaymentMethod;
      }
    } catch (error) {}
  };

  const handleAddCard = async (formValue) => {
    try {
      const cardExpiry = formValue.cardExpiry;
      const data = {
        cardNumber: formValue?.cardNumber,
        cardExpMonth: cardExpiry[0] + cardExpiry[1],
        cardExpYear: cardExpiry[3] + cardExpiry[4],
        cardCVC: formValue?.cvv,
        cardName: formValue?.cardHolderName,
        // country: "india",
        // postal_code: "110035",
        email: user?.email_address,
        isDefault: 1,
      };
      const res = await addCustomerNewCard(data);
      if (res?.cardId?.id) {
        const data = await getAddedCards();
        if (_.isArray(data)) {
          setSelectedCard(res?.cardId?.id);
          handleUpdateProfile(res?.cardId?.id);
          reset();
          setIsAddCardShow(false);
        }
      }
      return res?.cardId?.id;
    } catch (error) {}
  };

  const handleUpdateProfile = async (selectedCard) => {
    try {
      if (selectedCard === "default" || selectedCard.length === 0) {
        toast.error("Please select card");
        return;
      }

      const data = getValues();
      const reqData = {
        first_name: data?.first_name,
        last_name: data?.last_name,
        contact_number: data?.contact,
        email: data?.email,
        user_id: user?.user_id,
        cardId: selectedCard,
        isDefault: 1,
        user_profile_pic: companyLogo ? companyLogo : userData?.user_photo,
        // ...(companyLogo ? { user_profile_pic: companyLogo } : {}),
      };
      const update = await customerEditProfile(reqData);
      if (update?.status === 1) {
        History.dispatchUserData({ email_address: reqData.email });
        History.push("/customer/dashboard");
      }
    } catch (error) {}
  };

  const handleUpdate = async (data) => {
    try {
      if (selectedCard === "AddCard") {
        addCardSubmitBtnRef?.current.click();
      } else {
        handleUpdateProfile(selectedCard);
      }
    } catch (error) {}
  };

  useEffect(() => {
    // dispatch(showLoader());
    getAddedCards();
    getProfileData();
  }, []);

  // if (selectedCard !== "default") {
  //   dispatch(hideLoader());
  // }

  const handleCompanyLogo = async (file) => {
    try {
      const res = await uploadService(file[0]);
      console.log(res, "res");
      if (res?.status === 1) {
        setCompanyLogo(res?.absolute_path);
      }
    } catch (error) {}
  };

  console.log(companyLogo, "companyLogo");
  return (
    <div className="container">
      <div className="card py-5">
        <div className="top" />
        <div className="image1">
          <img
            onClick={() => {
              companyLogoRef.current.click();
            }}
            className="img1"
            src={
              companyLogo
                ? companyLogo
                : userData?.user_photo
                ? userData?.user_photo
                : "/assets/customerImg/Default Profile Picture.svg"
            }
            alt="profile_logo"
          />
          <input
            type="file"
            className="d-none"
            ref={companyLogoRef}
            onChange={(e) => {
              handleCompanyLogo(e.target.files);
            }}
          />
        </div>
        {!_.isEmpty(userData) && (
          <form onSubmit={handleSubmit(handleUpdate)}>
            <div className="wrapper px-3">
              <div className="title">First Name</div>
              <div className="input-data">
                <input
                  type="text"
                  placeholder="Dew"
                  defaultValue={userData?.user_first_name}
                  {...register("first_name")}
                />
                <FormError msg={errors.first_name?.message} />
              </div>
              <div className="title">Last Name</div>
              <div className="input-data">
                <input
                  type="text"
                  placeholder="Betty"
                  defaultValue={userData?.user_last_name}
                  {...register("last_name")}
                />
                <FormError msg={errors.last_name?.message} />
              </div>
              <div className="title">Email</div>
              <div className="input-data">
                <input
                  type="email"
                  placeholder="fdewbetty@gmail.com"
                  defaultValue={userData?.user_email}
                  {...register("email")}
                  maxLength={12}
                />
                <FormError msg={errors.email?.message} />
              </div>
              <div className="title">Contact</div>
              <div className="input-data">
                <input
                  type="tel"
                  placeholder="94279834"
                  defaultValue={userData?.user_mobile}
                  {...register("contact")}
                  maxLength={12}
                />
                <FormError msg={errors.contact?.message} />
              </div>
              <div className="title">Payment Method</div>
              <div className="input-data mt-5">
                <CustomerCardList
                  setSelectedCard={setSelectedCard}
                  selectedCard={selectedCard}
                  setIsAddCardShow={setIsAddCardShow}
                  isAddCardShow={isAddCardShow}
                  cardList={cardList}
                />
              </div>
              <div className="d-none">
                <button
                  id="updateFormBtn"
                  data-v-70f943e5=""
                  ref={profileSubmitBtnRef}
                >
                  Update
                </button>
              </div>
            </div>
          </form>
        )}
        <div className="px-4">
          {isAddCardShow && (
            <AddCardForm
              handleAddCard={handleAddCard}
              submitBtnRef={addCardSubmitBtnRef}
            />
          )}
          <div
            onClick={() => {
              profileSubmitBtnRef.current.click();
            }}
          >
            <button id="updateFormBtn" data-v-70f943e5="">
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerHoc(CustomerProfileEdit);
