import {
  createValidationObj,
  passwordRegExp,
  phoneRegExp,
} from "../Validation/validation.const";

const CreateUserAccountFormSchema = [
  {
    type: "text",
    name: `company_name`,
    label: `Company Name`,
    inputWrapperClass: "col-12",
    validate: true,
    validationsProps: {
      validationType: "string",
      validations: [
        ...createValidationObj("required", ["Company Name is required"]),
      ],
    },
  },

  {
    type: "text",
    name: `email_address`,
    label: `Email Address`,
    inputWrapperClass: "col-12",
    validate: true,
    validationsProps: {
      validationType: "string",
      validations: [
        ...createValidationObj("required", ["Email Address is required"]),
        ...createValidationObj("email", ["Enter Valid Email Address"]),
      ],
    },
  },

  {
    type: "password",
    name: `password`,
    label: `Password`,
    inputWrapperClass: "col-12",
    validate: true,
    validationsProps: {
      validationType: "string",
      validations: [
        ...createValidationObj("required", ["Password is required"]),
        ...createValidationObj("matches", [
          passwordRegExp,
          "Password should be 8 character, one capital letters, one special symbol and one number",
        ]),
      ],
    },
  },

  {
    type: "number",
    name: `admiinpin`,
    label: `6 Digit Pin`,
    inputWrapperClass: "col-12",
    validate: true,
    validationsProps: {
      validationType: "string",
      validations: [
        ...createValidationObj("required", ["6 Digit Pin is required"]),
        ...createValidationObj("min", [6, "Must be exactly 6 digits"]),
        ...createValidationObj("max", [6, "Must be exactly 6 digits"]),
      ],
    },
  },

  {
    type: "select",
    name: `country_code`,
    label: `Country Code`,
    inputWrapperClass: "col-12",
    validate: true,
    validationsProps: {
      validationType: "string",
      validations: [
        ...createValidationObj("required", ["Country Code is required"]),
      ],
    },
  },

  {
    type: "number",
    name: `contact_number`,
    label: `Mobile Number`,
    inputWrapperClass: "col-12",
    validate: true,
    validationsProps: {
      validationType: "string",
      validations: [
        ...createValidationObj("required", ["Mobile Number is required"]),
        ...createValidationObj("matches", [
          phoneRegExp,
          "Please enter valid phone no",
        ]),
      ],
    },
  },
];

export default CreateUserAccountFormSchema;
