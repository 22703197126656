import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import AuthRoute from "../helpers/Authroute";
import { History } from "../helpers/history";
import RoleBasedRoute from "../helpers/RoleBasedRoute";
import { NotFoundPage } from "../pages";
import { MainRoutes } from "./MainRoutes";
const SetHistoryInstance = () => {
  History.historyInstance = useHistory();
  History.dispatchInstance = useDispatch();
  return null;
};
const RenderRoutes = () => {
  console.log(MainRoutes, "returnComp");

  return (
    <BrowserRouter>
      <SetHistoryInstance />
      <Switch>
        {MainRoutes.map(
          ({ Component, path, routeCompType, ...rest }, index) => {
            let returnComp = null;
            switch (routeCompType) {
              case "AuthRoute":
                returnComp = (
                  <AuthRoute
                    key={index}
                    path={path}
                    exact
                    component={Component}
                    routeType={rest?.routeType ? rest?.routeType : ""}
                  />
                );
                break;
              case "RoleBasedRoute":
                returnComp = (
                  <RoleBasedRoute
                    key={index}
                    path={path}
                    component={Component}
                    role={rest?.role}
                    exact
                  />
                );
                break;

              default:
                returnComp = (
                  <Route key={index} exact path={path} component={Component} />
                );
                break;
            }

            return returnComp;
          }
        )}

        <Route component={NotFoundPage} />
      </Switch>
    </BrowserRouter>
  );
};

export default RenderRoutes;
