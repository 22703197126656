import axios from "axios";
import { convertJsonToFormData } from "../helpers/helper";
import { getEnvVariable } from "../environment/index";
import {
  getUserAccessTokenFromLocal,
  setUserInLocalStorage,
} from "../context/AppGlobalContext";
import { toast } from "react-toastify";
import { History } from "../helpers/history";
import { hideLoader, showLoader } from "../redux/reducers/LoaderSlice";

// import { hideLoader, showLoader } from "../Redux/Reducers/LoaderSlice";

// server
// axios.defaults.baseURL = getEnvVariable().base_api_url;

// local
axios.defaults.baseURL = getEnvVariable().local_api_url;

/**
 * Global request function for handling all HTTP calls
 * @param method
 * @param url
 * @param options {params: <for query params>, payload: <for data to be sent>'}
 */

const request = (method, url, options = { params: {}, payload: {} }) => {
  const request = {
    url,
    method,
    params: options.params,
    data: options.payload,
  };
  return new Promise((resolve, reject) => {
    if (method !== "GET") {
      History.dispatchInstance(showLoader());
    }

    axios
      .request(request)
      .then((res) => {
        resolve(res);
        if (method !== "GET") {
          toast.success(res?.message);
          History.dispatchInstance(hideLoader());
        }
      })
      .catch((err) => {
        reject(err);
        toast.error(err.message);
        History.dispatchInstance(hideLoader());
      });
  });
};

export const POST = (path, payload) => {
  return request("POST", path, { payload: payload });
};

export const Upload = (path, payload) => {
  return request("POST", path, { payload: convertJsonToFormData(payload) });
};

export const GET = (path, params) => {
  return request("GET", path, { params });
};

export const GETALL = (path, filters) => {
  return request("GET", path, { params: filters });
};

export const PUT = (path, payload) => {
  return request("PUT", path, { payload: payload });
};

export const PATCH = (path, payload) => {
  return request("PATCH", path, { payload: payload });
};

export const DELETE = (path) => {
  return request("DELETE", path);
};

axios.interceptors.request.use((request) => {
  // Setting up headers
  const token = getUserAccessTokenFromLocal();
  let headers = {};
  if (token) {
    // headers.authorization = `Bearer ${token}`;
    headers["auth_token"] = `${token}`;
  }
  request.headers = headers;
  return request;
});

/**
 * RESPONSE INTERCEPTOR
 */
axios.interceptors.response.use(
  (response) => {
    // Do something with response data
    return response.data;
  },
  async (error) => {
    console.log(error.response, "error");
    const finalError = error.response?.data?.message
      ? error.response?.data
      : error;
    return Promise.reject(finalError);
  }
);
