import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import "./formContainer.scoped.css";
import { createYupSchema } from "./Validation/Schema";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FormError from "./FormError";
import countryCodeArray from "../../../helpers/countryCode";

// The following component is an example of your existing Input Component
const Input = ({
  label,
  register,
  required,
  name,
  errorMsg,
  type,
  ...rest
}) => (
  <>
    <label htmlFor="explicit-label-name">
      <div className="div4">{label}</div>
    </label>
    <input
      type={type || "text"}
      id="explicit-label-name"
      {...register(name, { required })}
      className="input12"
      {...rest}
    />
    {errorMsg && <FormError msg={errorMsg} />}
  </>
);

// you can use React.forwardRef to pass the ref too
const Select = ({ label, register, required, name, errorMsg, ...rest }) => {
  return (
    <>
      <label htmlFor="explicit-label-name">
        <div className="div4">{label}</div>
      </label>
      <select
        className="floating-select user"
        defaultValue={"65"}
        placeholder={rest?.placeholder}
        {...register(name)}
      >
        {/* <option disabled> Gender</option> */}
        {countryCodeArray.map((item) => {
          return (
            <option value={item?.code}>{`${item?.name} (+${
              item?.code
            })`}</option>
          );
        })}
      </select>

      {errorMsg && <FormError msg={errorMsg} />}
    </>
  );
};

const Form = ({
  fields = [],
  defaultValue,
  formTitle,
  formSubTitle,
  otherFields,
  btnTitle,
  submitBtn,
  onSubmit,
}) => {
  const handleFormValidations = (formfields) => {
    const finalFields = formfields;
    const yepSchema = finalFields.reduce(createYupSchema, {});
    const validateSchema = Yup.object().shape(yepSchema);
    return validateSchema;
  };
  const formOptions = {
    resolver: yupResolver(handleFormValidations(fields)),
    defaultValues: useMemo(() => {
      return defaultValue;
    }, [defaultValue]),
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(formOptions);

  const renderFields = () => {
    return fields.map((item) => {
      if (item?.type === "select") {
        return (
          <Select
            kye={item?.name}
            label={item?.label}
            name={item?.name}
            register={register}
            required
            error={errors}
            errorMsg={errors[(item?.name)]?.message}
            {...item}
          />
        );
      }
      return (
        <Input
          kye={item?.name}
          label={item?.label}
          name={item?.name}
          register={register}
          required
          error={errors}
          type={item?.type}
          errorMsg={errors[(item?.name)]?.message}
          id={item?.id}
        />
      );
    });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div id="div2" className="card-body">
        <div id="div3">
          <h1>{formTitle}</h1>
          <h5>{formSubTitle}</h5>
        </div>
        {renderFields()}
        {otherFields}
      </div>

      <div>
        {submitBtn ? (
          submitBtn
        ) : (
          <button type="submit" className="btnn1" style={{ cursor: "pointer" }}>
            {btnTitle}
          </button>
        )}
      </div>
    </form>
  );
};

export default Form;
