import React from "react";
import { useForm } from "react-hook-form";
import CustomerHoc from "../../../Hoc/CustomerHoc";
import FormError from "../../common/Form/FormError";

import "./custLogin.scoped.css";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useHistory } from "react-router-dom";
import { passwordRegExp } from "../../common/Form/Validation/validation.const";
import _ from "lodash";
import { resetPasswordService } from "../../../services/all.service";
import { History } from "../../../helpers/history";
import { UserRoles } from "../../../helpers/Constant";

const schema = yup.object().shape({
  password: yup
    .string()
    .required()
    .matches(
      passwordRegExp,
      "Password should be 8 character, one capital letters, one special symbol and one number"
    )
    .label("Password"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const formatSearchStr = (search) => {
  const result = _.split(search, "&").map((pair) => {
    let pairData = _.split(pair, "=");
    pairData[0] = pairData[0].replace(/[^a-zA-Z ]/g, "");
    return pairData;
  });
  return Object.fromEntries(result);
};

const ResetPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const {
    location: { search: urlParams },
  } = useHistory();
  const handleResetPassword = async (data) => {
    try {
      const strData = formatSearchStr(urlParams);
      if (strData?.etkn) {
        const reqData = {
          password: data?.password,
          email_address: strData?.eml,
          token: strData?.etkn,
        };
        const res = await resetPasswordService(reqData);
        if (res?.status === 1) {
          if (res?.user_role_id === UserRoles.Admin) {
            History.push("/superadmin");
          } else {
            History.push("/");
          }
        }
      }
    } catch (error) {
      History.push("/forgot-password");
    }
    console.log(data, "data");
  };

  console.log(formatSearchStr(urlParams), "errors");
  return (
    <div className="login-page">
      <div className="form">
        <div style={{ margin: "-30px 0px 50px -1px" }}>
          <div className="title">Reset password</div> <hr />
        </div>
        {/* <Form fields={loginFields} /> */}
        <form
          className="login-form"
          onSubmit={handleSubmit(handleResetPassword)}
        >
          <label className="text-dark float-left">Password</label>
          <input
            type="password"
            {...register("password")}
            // required
            placeholder="Password"
          />
          {errors?.password?.message && (
            <div className="float-left mb-2">
              <FormError msg={errors?.password?.message} />
            </div>
          )}

          <label className="text-dark float-left d-block w-100 text-left">
            Confirm Password
          </label>
          <input
            type="password"
            {...register("confirmPassword")}
            // required
            placeholder="Confirm Password"
          />
          {errors?.confirmPassword?.message && (
            <div className="float-left mb-2">
              <FormError msg={errors?.confirmPassword?.message} />
            </div>
          )}

          <button style={{ textTransform: "unset !important" }}>Reset</button>
          {/* <p className="message">
                <Link to="/customer/register">You don't have account?</Link>
            </p> */}
        </form>
      </div>
    </div>
  );
};

export default CustomerHoc(ResetPassword);
