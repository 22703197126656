import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  DealPublish,
  EditGetDeals,
  EditPreview,
  EditedDealPublish,
  getAllInvoices,
  getAllTrips,
} from "../../../../services/all.service";
import { appendScript } from "../../account/viewAccount";
import AdminSidebar from "../../AdminSidebar";
import "./mobilenewcarosel.scoped.css";
import {
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { History } from "../../../../helpers/history";
import { createBreadCrumb, slugToTitle } from "../../../../helpers/helper";

const Mobilenewcarosel = ({ listData = ["test"] }) => {
  const history = useHistory();
  const [invoiceData, setInvoiceData] = useState([]);
  const stateid = useLocation();

  useEffect(() => {
    if (invoiceData.length > 0) {
      appendScript(
        "https://cdn.datatables.net/1.10.9/js/jquery.dataTables.min.js"
      );
      appendScript("/assets/js/record3.js");
    }
  }, [invoiceData]);

  const [formdata, setformdata] = useState({
    mfl_deals_about_details: "adsf",
    mfl_deals_address_1: "ffdasdfsa",
    mfl_deals_address_2: "asdf",
    mfl_deals_claim_count: 0,
    mfl_deals_created_on: "2024-05-03T10:42:12.000Z",
    mfl_deals_email: "asdfaj@gmaicl.ocm",
    mfl_deals_images_url_1:
      "http://192.169.1.114:3001/deals_images/deals/tripfiles-0_1714732932533-download.jfif",
    mfl_deals_images_url_2:
      "http://192.169.1.114:3001/deals_images/deals/tripfiles-0_1714732932597-MainAfter.webp",
    mfl_deals_images_url_3:
      "http://192.169.1.114:3001/deals_images/deals/tripfiles-0_1714732932618-download.jfif",
    mfl_deals_is_active: 1,
    mfl_deals_listing_details: "asdf",
    mfl_deals_phone_no: "345678654321",
    mfl_deals_promotion_details: "asdf",
    mfl_deals_service: "asdf",
    mfl_deals_tags: { "2": "top", "4": "top", "3": "top" },
    mfl_deals_title: "afdads",
  });

  useEffect(() => {
    const EditDetails = async () => {
      if (stateid.state != undefined) {
        try {
          let jsond = { deal_id: stateid.state.id };
          console.log("image_url_1", jsond);

          const response =
            stateid.state.type == "edit"
              ? await EditPreview(jsond)
              : await EditGetDeals(jsond);
          console.log(response, "dataaafasdfafasfaf");
          if ((response.status = 1)) {
            let demo_data = JSON.parse(response.data[0].mfl_is_edit_data);
            // stateid.state.type == "edit" ? setformdata(response.data[0].mfl_is_edit_data) : setformdata(response.data[0])
            stateid.state.type == "edit"
              ? setformdata(demo_data)
              : setformdata(response.data[0]);
          }
        } catch (error) {
          // console.log(error, 'Error')
          if (error.response != undefined) {
            console.log("fail", error);
          } else {
            console.log(error);
          }
        }
      }
    };
    EditDetails();
  }, []);

  console.log(formdata);

  const Publishandler = async () => {
    try {
      let jsond = { deal_id: stateid.state.id };

      const response =
        stateid.state.type == "edit"
          ? await EditedDealPublish(jsond)
          : await DealPublish(jsond);
      console.log(response);
      if ((response.status = 1)) {
        // setformdata(response.data[0])
        // history.push("/dashboard/marketplace")
        window.location.href = "/dashboard/marketplace";
      }
    } catch (error) {
      // console.log(error, 'Error')
      if (error.response != undefined) {
        console.log("fail", error);
      } else {
        console.log(error);
      }
    }
  };

  return (
    <AdminSidebar
      title={createBreadCrumb({
        currentPageTitle: `Publish Deal`,
        title: "Add Deal",
        href: "/dashboard/deals",
      })}
      childrenContainerClass="container-fluid flex-grow-1 container-p-y"
    >
      <div className="container">
        <div className="card-deck mb-3 text-center">
          <div className="card mb-4 box-shadow">
            <div className="card-body overflow-auto">
              <div className="row">
                <div id="div2">Current Page Preview</div>
                <div className="actionBtns">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() =>
                      History.push({
                        pathname: "/dashboard/deals",
                        state: { id: stateid.state.id },
                      })
                    }
                  >
                    Edit
                  </button>
                  <div className="">
                    <button
                      type="button"
                      className="btn btn-success"
                      onClick={Publishandler}
                    >
                      Publish
                    </button>
                  </div>
                </div>
              </div>
              <br />
              <div className="previewBlock">
                <div className="">
                  <div className="imagePreivewBlock">
                    <div className="mobileCardView">
                      <div className="CardViewImgBlock">
                        <img
                          src={formdata.mfl_deals_images_url_1}
                          alt="First slide"
                          className="d-block w-100"
                        />
                        <div className="CardViewText">
                          <h5>{formdata.mfl_deals_title}</h5>
                          <h6>{formdata.mfl_deals_service}</h6>
                          <p>{formdata.mfl_deals_about_details}</p>
                          {/* add like imge or emoji */}

                          <div className="likeBlock">
                            <img
                              src="../../../assets/img/hearticon.png"
                              alt="like"
                              className="likeIcon"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mobileCardViewlg">
                      <div className="mobileCardViewlgContent">
                        <div className="CaroselBlock">
                          <div
                            id="carouselExampleIndicators"
                            className="carousel slide"
                            data-ride="carousel"
                          >
                            <ol className="carousel-indicators">
                              <li
                                data-target="#carouselExampleIndicators"
                                data-slide-to="0"
                                className="active"
                              />
                              {formdata.mfl_deals_images_url_2 != null &&
                                formdata.mfl_deals_images_url_2 !==
                                  "undefined" && (
                                  <li
                                    data-target="#carouselExampleIndicators"
                                    data-slide-to="1"
                                  />
                                )}
                              {console.log(
                                formdata.mfl_deals_images_url_3,
                                "undefined"
                              )}
                              {formdata.mfl_deals_images_url_3 != null &&
                                formdata.mfl_deals_images_url_3 !=
                                  "undefined" && (
                                  <li
                                    data-target="#carouselExampleIndicators"
                                    data-slide-to="2"
                                  />
                                )}
                            </ol>
                            <div className="carousel-inner">
                              <div className="carousel-item active">
                                <img
                                  className="d-block w-100"
                                  src={formdata.mfl_deals_images_url_1}
                                  alt="First slide"
                                />
                              </div>
                              {formdata.mfl_deals_images_url_2 != null &&
                                formdata.mfl_deals_images_url_2 !==
                                  "undefined" && (
                                  <div className="carousel-item">
                                    <img
                                      className="d-block w-100"
                                      src={formdata.mfl_deals_images_url_2}
                                      alt="Second slide"
                                    />
                                  </div>
                                )}
                              {formdata.mfl_deals_images_url_3 != null &&
                                formdata.mfl_deals_images_url_3 !=
                                  "undefined" && (
                                  <div className="carousel-item">
                                    <img
                                      className="d-block w-100"
                                      src={formdata.mfl_deals_images_url_3}
                                      alt="Third slide"
                                    />
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="MobilecontentPart">
                          <div className="contentPartHeading">
                            <h5>{formdata.mfl_deals_title}</h5>
                            <div className="likeBlock">
                              <img
                                src="../../../assets/img/hearticon.png"
                                alt="like"
                                className="likeIcon"
                              />
                            </div>
                          </div>
                          <div className="contentPartLocation justify-content-start pl-4">
                            <img src="../../../assets/img/maps.png" />
                            <p>
                              {formdata.mfl_deals_address_1},<br />
                              {formdata.mfl_deals_address_2}
                            </p>
                          </div>
                          <div className="taglines justify-content-start row pl-4 h-auto">
                            {formdata.mfl_deals_tags != null &&
                              formdata.mfl_deals_tags != undefined &&
                              typeof formdata.mfl_deals_tags !== "string" &&
                              Object.values(formdata.mfl_deals_tags).map(
                                (data) => (
                                  <div className="tagline">
                                    <p>{Object.values(data)}</p>
                                  </div>
                                )
                              )}
                            {formdata.mfl_deals_tags != null &&
                              formdata.mfl_deals_tags != undefined &&
                              typeof formdata.mfl_deals_tags === "string" &&
                              Object.values(
                                JSON.parse(formdata.mfl_deals_tags)
                              ).map((data) => (
                                <div className="tagline">
                                  <p>{Object.values(data)}</p>
                                </div>
                              ))}
                          </div>

                          <div className="contentPartDescription">
                            <div className="contentPartDivtion">
                              <div className="contentPartDivtionHeading1">
                                <h4>Promotions</h4>
                              </div>
                              <div className="contentPartDivtionHeading">
                                <h4>About</h4>
                                <div className="homeIconActivebar2" />
                              </div>
                            </div>
                          </div>

                          <div className="contentPartFeatures justify-content-start pl-2 pr-4">
                            <div className="contentPartFeaturesHeading ">
                              <p>
                                {formdata.mfl_deals_promotion_details != null &&
                                  formdata.mfl_deals_promotion_details !=
                                    null &&
                                  formdata.mfl_deals_promotion_details.split(
                                    ",,"
                                  )[0]}
                              </p>
                              <ul>
                                {formdata.mfl_deals_promotion_details != null &&
                                  formdata.mfl_deals_promotion_details !=
                                    null &&
                                  formdata.mfl_deals_promotion_details
                                    .split(",,")
                                    .slice(1)
                                    .map((data) => <li>{data}</li>)}
                              </ul>
                            </div>
                          </div>

                          <button className=" promobutton">Claim Promo</button>
                        </div>
                        <div className="MobilecontentPartIconsFooter">
                          <div className="MobilecontentPartIcons">
                            <img src="../../../assets/img/home.png" />
                            <img src="../../../assets/img/invoice_icon.png" />
                            <div className="homeIcon">
                              <img src="../../../assets/img/shop_icon.png" />
                              <img
                                className="homeIconActive"
                                src="../../../assets/img/Ellipse.png"
                              />
                            </div>
                            <div className="homeIconActivebar" />
                            <img src="../../../assets/img/user_icon.png" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="statsCardContainer">
                  <div className="statsCard">
                    <h5>Current Promotion</h5>
                    <h3>3</h3>
                  </div>
                  <div className="statsCard">
                    <h5>Current Promotions</h5>
                    <h3>426</h3>
                  </div>
                  <div className="statsCard">
                    <h5>Current Promotion</h5>
                    <h3>3</h3>
                  </div>
                  <div className="statsCard">
                    <h5>Current Promotion</h5>
                    <h3>3</h3>
                  </div>
                  <div className="statsCard">
                    <h5>Current Promotion</h5>
                    <h3>3</h3>
                  </div>
                </div>
              </div>
              <br />
            </div>
          </div>
        </div>
      </div>
    </AdminSidebar>
  );
};

export default Mobilenewcarosel;
