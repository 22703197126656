import React from "react";
import PremiumPayment from "./PremiumPayment";
const premiumPrice = 50;
const BikePremium = () => {
  return (
    <PremiumPayment
      planName="Bike Premium"
      planPrice={premiumPrice}
      planDescription=" 3 Tows Live Tracking Real-Time Updates Accident Claim Guide
    Multiple Vehicle Integration"
      planImage="/assets/customerImg//dd.JPG"
    />
  );
};

export default BikePremium;
