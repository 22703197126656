import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  accountType,
  createBreadCrumb,
  slugToTitle,
} from "../../../../helpers/helper";
import { accountListService, deleteUser } from "../../../../services/all.service";
import moment from "moment";

import AdminSidebar from "../../AdminSidebar";

import "./viewAccount.scoped.css";
import { Link } from "react-router-dom";
import {ToastContainer, toast } from "react-toastify";
// import "./dataTable.js";
const { FreeUser, PremiumUser } = accountType;

function CustomToast({ closeToast, id }) {
  const handleYes = async() => {
      const response=await deleteUser(id);
      if (response.status == 1) {
         toast.success(response.message)
       }
    closeToast(); 
  };

  const handleNo = () => {
    closeToast(); 
  };

  return (
    <div>
      <p>Do you want to delete this user and all his data?</p>
      <button onClick={handleYes} className="btn btn-danger">Yes</button>
      <button onClick={handleNo} className="btn btn-light ml-2">No</button>
    </div>
  );
}

export const appendScript = (scriptToAppend) => {
  const script = document.createElement("script");
  script.src = scriptToAppend;
  script.async = false;
  const attr = document.querySelector(`script[src="${scriptToAppend}"]`);
  if (!attr) {
    document.body.appendChild(script);
  } else {
    document.body.removeChild(attr);
    document.body.appendChild(script);
  }
};

const ViewAccount = () => {
  const { accountType } = useParams();
  const [listData, setListData] = useState([]);

  const getList = async () => {
    try {
      const res = await accountListService(accountType);
      if (Array.isArray(res?.data)) {
        setListData(res?.data);
      }
    } catch (error) {}
  };


  const notify=(id)=>{
    toast(<CustomToast id={id} />);
  }

  useEffect(() => {
    console.log(listData, "listDatazzzxs");

    if (listData.length > 0) {
      appendScript(
        "https://cdn.datatables.net/1.10.9/js/jquery.dataTables.min.js"
      );
      appendScript("/assets/js/record3.js");
    }
  }, [listData, accountType]);
  useEffect(() => {
    getList();
  }, []);

  console.log(moment(moment.unix(1695633568).format()).format("LL"));
  return (
    <AdminSidebar
      title={createBreadCrumb({
        currentPageTitle: `${
          accountType !== FreeUser
            ? slugToTitle(accountType).replace("User", "")
            : `${slugToTitle(accountType)}`
        } Accounts`,
        title: "Accounts",
        href: "/dashboard/accounts",
      })}
    >
      <div className="container">
        <div className="card-deck mb-3 text-center">
          <div className="card mb-4 box-shadow">
            <div className="card-body">
              <div id="div1">
                <div id="div2">
                  <span>
                    {accountType === PremiumUser ? (
                      <img
                        className="img-box"
                        id="img11"
                        src="/assets/img/diamond 2.png"
                        alt="towcar"
                      />
                    ) : accountType === FreeUser ? (
                      <img
                        className="img-box"
                        id="img11"
                        src="/assets/img/2. tickets.png"
                        alt="towcar"
                      />
                    ) : (
                      <img
                        className="img-box"
                        id="img11"
                        src="/assets/img/towcar.png"
                        alt="towcar"
                      />
                    )}
                  </span>
                  &nbsp;&nbsp;
                  {accountType !== FreeUser
                    ? slugToTitle(accountType).replace("User", "")
                    : slugToTitle(accountType)}{" "}
                  Accounts
                </div>
              </div>
              <br />
              <br />
              <table id="example" cellSpacing={0} width="100%">
                <thead>
                  <tr>
                    <th className="red">No</th>

                    <th>Login-in details</th>
                    <th>Name/Contact</th>
                    {/* <th>Contact</th> */}
                    <th>Date joined</th>
                    <th>Model</th>
                    <th>Make</th>
                    <th>Expiry</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {listData.map((item) => {
                    return (
                      <tr>
                        <td>
                          <span>
                            <img
                              className="img-box"
                              src="/assets/img/5.png"
                              alt="img5"
                            />
                          </span>
                          Tiger Nixon
                        </td>
                        <td className="">
                          <img
                            className="img-box mr-2 float-left"
                            src={
                              item?.user_photo
                                ? item?.user_photo
                                : "/assets/img/noImage.jpg"
                            }
                            alt="img5"
                            style={{
                              width: "15%",
                              borderRadius: "50%",
                            }}
                          />

                          <Link
                            to={`/dashboard/accounts/${accountType}/${
                              item?.user_id
                            }`}
                            className="float-left"
                          >
                            {" "}
                            {item?.user_email}{" "}
                          </Link>
                        </td>
                        <td>{`${item?.user_first_name} ${
                          item?.user_last_name
                        }/${item?.user_country_code} ${
                          item?.user_mobile
                        }`}</td>
                        {/* <td>{`${item?.user_country_code} ${
                          item?.user_mobile
                        } `}</td> */}
                        <td>
                          {moment(
                            item?.user_joinig_date
                          ).format("LL")}
                        </td>
                        <td>{item?.vehicle_model}</td>
                        <td>{item?.vehicle_make}</td>
                        <td>
                          {item?.user_expiry_date
                            ? moment(
                                item?.user_expiry_date
                              ).format("LL")
                            : "-"}
                        </td>
                        <td> <img
                        onClick={()=>{notify(item?.user_id)}}
                        className="img-box"
                        style={{width:".9rem",cursor:"pointer"}}
                        id="img11"
                        src="/assets/img/deleteIcon.png"
                        alt="towcar"
                      /></td>
                        {/* <td>2011/04/25</td> */}
                        {/* <td>$320,800</td> */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </AdminSidebar>
  );
};

export default ViewAccount;
