import React, { useEffect, useRef } from "react";
import { useState } from "react";
import {
  addCustomerNewCard,
  getCustomerAllCards,
} from "../../../services/customer.service";
import "./premiumpay.scoped.css";
import _ from "lodash";

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FormError from "../../common/Form/FormError";
import CustomerHoc from "../../../Hoc/CustomerHoc";
import { useDispatch, useSelector } from "react-redux";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { Redirect, withRouter } from "react-router-dom";
import { uniqueId } from "../../../helpers/helper";
import { MainLoader } from "../../common";
import { centToDollar } from "../../../helpers/stripe";
import { hideLoader, showLoader } from "../../../redux/reducers/LoaderSlice";
import { toast } from "react-toastify";
import AddCardForm from "./AddCardForm";
import CustomerCardList from "./CustomerCardList";

const schema = yup.object().shape({
  cardNumber: yup
    .string()
    .min(16)
    .max(16)
    .required()
    .label("Card Number"),
  cardExpiry: yup
    .string()
    .min(5)
    .max(5)
    .required()
    .label("Card Expiry"),
  cvv: yup
    .string()
    .min(3)
    .max(3)
    .required()
    .label("Cvv"),
  cardHolderName: yup
    .string()
    .min(2)
    .required()
    .label("Card Holder Name"),
});
const discountPrice = 0;

const PremiumPayment = ({
  planName,
  planPrice,
  planDescription,
  planImage,
  recurringTime = "year",
  location,
}) => {
  const finalPrice = centToDollar(planPrice);
  const [isAddCardShow, setIsAddCardShow] = useState(false);
  const [cardList, setCardList] = useState([]);
  const [selectedCard, setSelectedCard] = useState("default");
  const finalCheckoutPrice = finalPrice - discountPrice;
  const addCardSubmitBtnRef = useRef(null);
  const user = useSelector((state) => state.user.data);
  const [paymentIntent, setPaymentIntent] = useState();
  const [clientSecret] = useState(location.state.clientSecret);
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const getCardImageBasedOnType = (cardType) => {
    let imgPath;
    const smallCardType = cardType.toLowerCase();
    switch (smallCardType) {
      case "visa":
        imgPath = "/assets/customerImg/visa (1) 1.svg";
        break;
      case "mastercard":
        imgPath = "/assets/customerImg/mastercard 2.svg";
        break;

      default:
        imgPath = "/assets/customerImg/card1.svg";
        break;
    }
    return imgPath;
  };
  const getAddedCards = async () => {
    try {
      const res = await getCustomerAllCards();

      if (
        !_.isEmpty(res?.cardDetails) &&
        _.isArray(res?.cardDetails?.paymentMethods?.data)
      ) {
        // const allCards = res?.cardDetails?.cardList.map((item) => {
        //   if (item?.cardId === res?.cardDetails?.defaultSource) {
        //     item["default"] = true;
        //     setSelectedCard(item?.cardId);
        //   } else {
        //     item["default"] = false;
        //   }
        //   item["logo"] = getCardImageBasedOnType(item?.cardType);
        //   return item;
        // });

        console.log(
          res,
          res?.cardDetails?.paymentMethods?.data,
          "allPaymentMethod"
        );

        const allPaymentMethod = res?.cardDetails?.paymentMethods?.data.map(
          (item) => {
            if (item?.id === res?.cardDetails?.defaultSource) {
              item["default"] = true;
              setSelectedCard(item?.id);
            } else {
              item["default"] = false;
            }
            item["logo"] = getCardImageBasedOnType(item?.card?.brand);
            return item;
          }
        );

        console.log(allPaymentMethod, "allPaymentMethod");

        setCardList(allPaymentMethod);
        return allPaymentMethod;
      }
    } catch (error) {}
  };

  const handleAddCard = async (formValue) => {
    try {
      const cardExpiry = formValue.cardExpiry;
      const data = {
        cardNumber: formValue?.cardNumber,
        cardExpMonth: cardExpiry[0] + cardExpiry[1],
        cardExpYear: cardExpiry[3] + cardExpiry[4],
        cardCVC: formValue?.cvv,
        cardName: formValue?.cardHolderName,
        // country: "india",
        // postal_code: "110035",
        email: user?.email_address,
        isDefault: 0,
      };
      const res = await addCustomerNewCard(data);
      if (res?.cardId?.id) {
        const data = await getAddedCards();
        if (_.isArray(data)) {
          setSelectedCard(res?.cardId?.id);
          reset();
          setIsAddCardShow(false);
          confirmPayment(res?.cardId?.id);
        }
      }
      return res?.cardId?.id;
    } catch (error) {}
  };

  const confirmPayment = async (paymentMethodId) => {
    if (paymentMethodId === "default") {
      toast.error("Please select card");
      return;
    }
    dispatch(showLoader());
    let { error, paymentIntent } = await stripe.confirmCardPayment(
      clientSecret,
      {
        payment_method: paymentMethodId,
      }
    );
    dispatch(hideLoader());
    if (error) {
      toast.error(error?.message);
      // show error and collect new card details.
      return;
    }

    setPaymentIntent(paymentIntent);
  };

  const handleSubmitConfirm = async (e) => {
    e.preventDefault();
    if (selectedCard === "AddCard") {
      addCardSubmitBtnRef?.current.click();
    } else {
      confirmPayment(selectedCard);
    }
  };

  useEffect(() => {
    getAddedCards();
  }, []);

  if (!stripe || !elements) {
    return <></>;
  }

  if (paymentIntent && paymentIntent.status === "succeeded") {
    return <Redirect to={{ pathname: "/customer/paid/summary" }} />;
  }

  if (!planName || !planPrice) {
    return <MainLoader />;
  }

  return (
    <>
      <div>
        {/* <div style={{ margin: "32px 0 0 39px" }}>
          <img
            src="/assets/customerImg/Etow icon.png"
            width={150}
            height={50}
            alt="logo"
          />
        </div> */}
        <div className="login-page">
          <h6 style={{ textAlign: "center", color: "coral" }} className="mt-5">
            1. Type Of Membership
          </h6>
          <div className="container">
            <div className="card222">
              <div style={{ textAlign: "center" }}>
                <img className="img1" src={planImage} />
              </div>
              <div className="title1">
                <br />
                <p className="p12">{planName}</p>
              </div>
              <div className="text1">${finalPrice}</div>
              <div
                style={{
                  textAlign: "center",
                  color: "#5e5757",
                  fontSize: 19,
                  //   lineHeight: 25,
                }}
              >
                per {recurringTime}
              </div>
              <div className="text12">{planDescription}</div>
            </div>
          </div>
          <br />
          <br />
        </div>
        <div className="login-page">
          <h6 style={{ textAlign: "center", color: "coral" }} className="mt-5">
            2. Select Payment Method
          </h6>

          <CustomerCardList
            setSelectedCard={setSelectedCard}
            selectedCard={selectedCard}
            setIsAddCardShow={setIsAddCardShow}
            isAddCardShow={isAddCardShow}
            cardList={cardList}
          />

          {/* {_.isArray(cardList) &&
            cardList.map((item) => {
              return (
                <React.Fragment key={item?.id}>
                  <div
                    className="col"
                    onClick={() => {
                      setSelectedCard(item?.id);
                    }}
                  >
                    <div
                      className={`card text-center h-100 py-5 ${
                        selectedCard === item?.id ? "active-card" : ""
                      }`}
                    >
                      <form className="send">
                        <div
                          style={{ textAlign: "left", margin: "-43px 68px" }}
                        >
                          <img
                            src={item?.logo}
                            width={150}
                            height={50}
                            style={{ margin: "14px -12px 22px -35px" }}
                            alt={item?.cardType}
                          />
                          <span className="span1">
                            {_.capitalize(item?.card?.brand)} ***{" "}
                            {item?.card?.last4}
                          </span>
                        </div>
                      </form>
                    </div>
                  </div>
                  <br />
                </React.Fragment>
              );
            })}

          <div
            className="col"
            onClick={() => {
              setSelectedCard(!isAddCardShow ? "AddCard" : "");
            }}
          >
            <div
              className={`card text-center h-100 py-5 ${
                selectedCard === "AddCard" ? "active-card" : ""
              }`}
              onClick={() => {
                setIsAddCardShow(!isAddCardShow);
              }}
            >
              <form className="send">
                <div style={{ textAlign: "left", margin: "-43px 68px" }}>
                  <img
                    src="/assets/customerImg/card1.svg"
                    width={150}
                    height={50}
                    alt="card1"
                    style={{ margin: "14px -12px 22px -35px" }}
                  />
                  <span className="span1">Add credit or debit card</span>
                </div>
              </form>
            </div>
          </div> */}
        </div>
        <br />
        {isAddCardShow && (
          <AddCardForm
            handleAddCard={handleAddCard}
            submitBtnRef={addCardSubmitBtnRef}
          />
          // <form
          //   style={{ padding: 10 }}
          //   id="foo"
          //   onSubmit={handleSubmit(handleAddCard)}
          // >
          //   <div className="floating-label">
          //     <input
          //       placeholder="Card number"
          //       className="floating-input user"
          //       type="tel"
          //       id="card_number"
          //       style={{ background: "#fff", color: "#000", opacity: 1 }}
          //       max={5}
          //       maxLength={16}
          //       {...register("cardNumber")}
          //     />
          //     {/* <span className="highlight" />
          //     <label
          //       className="label2"
          //       style={{ color: "rgb(255, 255, 255)" }}
          //     />
          //     <span
          //       id="errorcardnumber"
          //       style={{ display: "none", color: "crimson" }}
          //     >
          //       Enter numbers only
          //     </span> */}
          //     <FormError msg={errors.cardNumber?.message} />
          //   </div>
          //   <div id="error_card" />
          //   <div style={{ display: "flex" }}>
          //     <div
          //       className="floating-label"
          //       style={{ width: "50%", marginRight: 10 }}
          //     >
          //       <input
          //         placeholder="mm/yy"
          //         className="floating-input user"
          //         type="tel"
          //         style={{ background: "#fff", color: "#000", opacity: 1 }}
          //         id="txtDate"
          //         maxLength={4}
          //         {...register("cardExpiry")}
          //       />
          //       <i
          //         className="bi bi-calendar-range date-picker"
          //         style={{
          //           marginLeft: "-29px",
          //           cursor: "pointer",
          //           color: "#000",
          //         }}
          //       />
          //       {/* <span className="highlight" />
          //       <label style={{ color: "#000", fontSize: 12 }}>
          //         Expiration date
          //       </label>
          //       <span
          //         id="errorexpire"
          //         style={{ display: "none", color: "crimson" }}
          //       >
          //         Enter numbers and forward slash only
          //       </span> */}
          //       <FormError msg={errors.cardExpiry?.message} />
          //     </div>
          //     <div
          //       className="floating-label"
          //       style={{ width: "50%", marginLeft: 10 }}
          //     >
          //       <input
          //         placeholder="CVV"
          //         className="floating-input user"
          //         type="password"
          //         style={{ background: "#fff", color: "#000", opacity: 1 }}
          //         maxLength={3}
          //         id="cvv"
          //         {...register("cvv")}
          //       />
          //       <i
          //         className="bi bi-credit-card"
          //         style={{
          //           marginLeft: "-29px",
          //           cursor: "pointer",
          //           color: "#000",
          //         }}
          //       />
          //       {/* <span className="highlight" />
          //       <label style={{ color: "#000", fontSize: 12 }}>CVV</label>
          //       <span
          //         id="error_cvv"
          //         style={{ display: "none", color: "crimson" }}
          //       >
          //         Enter numbers only
          //       </span> */}
          //       <br />
          //       <FormError msg={errors.cvv?.message} />
          //     </div>
          //   </div>
          //   <div className="floating-label">
          //     <input
          //       placeholder="Card holder name"
          //       className="floating-input user"
          //       type="text"
          //       style={{ background: "#fff", color: "#000", opacity: 1 }}
          //       id="card_name"
          //       {...register("cardHolderName")}
          //     />
          //     <i
          //       className="bi bi-person"
          //       style={{
          //         marginLeft: "-29px",
          //         cursor: "pointer",
          //         color: "#000",
          //       }}
          //     />
          //     {/* <span className="highlight" />
          //     <label style={{ color: "#000" }}>Name</label>
          //     <span
          //       id="errorcard_name"
          //       style={{ display: "none", color: "crimson" }}
          //     >
          //       Enter characters only
          //     </span> */}
          //     <FormError msg={errors.cardHolderName?.message} />
          //   </div>
          //   <input
          //     ref={addCardSubmitBtnRef}
          //     className="d-none"
          //     type="submit"
          //     value="Add Card"
          //   />
          // </form>
        )}

        <h6 style={{ textAlign: "center", color: "coral" }} className="mt-5">
          5. Order Summary
        </h6>
        <div style={{ padding: 10 }}>
          <div className="input-group" style={{ flexWrap: "nowrap" }}>
            <input
              type="number"
              className="form-control "
              placeholder="Add A Promo Code"
              aria-label="Add A Promo Code"
              aria-describedby="promo_apply"
              id="discountInput"
              style={{
                backgroundColor: "#fff",
                color: "#000",
                opacity: 1,
                margin: 0,
                paddingTop: 30,
                border: "none",
                borderRadius: "10px 0 0 10px ",
              }}
            />
            <div className="input-group-append">
              <span
                className="input-group-text"
                id="promo_apply"
                style={{
                  color: "black",
                  backgroundColor: "coral",
                  border: "none",
                  borderRadius: "0px 10px 10px 0px",
                }}
              >
                <button style={{ marginTop: 0, padding: 0 }}>Apply</button>
              </span>
            </div>
          </div>
        </div>
        <div style={{ padding: 10 }}>
          <div
            className="card15 p-2 mt-3"
            style={{
              backgroundColor: "#fff",
              color: "#000",
              fontSize: 15,
              padding: 10,
            }}
          >
            <div style={{ fontSize: 15 }}>
              eTow Car/Bike Premium Yearly Subscription
            </div>
            <div className="row mt-2">
              <div className="col">Subtotal</div>
              <div className="col text-right pr-4">${finalPrice}</div>
            </div>
            <div className="row" style={{ color: "coral" }}>
              <div className="col">Coupon Discount</div>
              <div className="col text-right pr-4">-${discountPrice}</div>
            </div>
            <div className="row mt-4">
              <div className="col">
                <b>Total</b>
              </div>
              <div className="col text-right pr-4">
                <b>${finalCheckoutPrice}</b>
              </div>
            </div>
          </div>
          <div className="mt-5">
            By continuing, you agree to eTow's Terms and Conditions and Privacy
            Policy.
          </div>
          <div style={{ overflow: "auto", marginTop: 20, marginBottom: 30 }}>
            <div>
              <button id="nextBtn" onClick={handleSubmitConfirm}>
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerHoc(withRouter(PremiumPayment));
