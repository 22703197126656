import * as Yup from "yup";

export function createYupSchema(schema, config) {
  const { name, validate = false, ...restConfig } = config;
  if (validate) {
    const {
      validationsProps: { validationType },
      validationsProps: { validations } = [],
    } = restConfig;
    if (!Yup[validationType]) {
      return schema;
    }
    let validator = Yup[validationType]();
    validations.forEach((validation) => {
      const { params, type } = validation;
      if (!validator[type]) {
        return;
      }
      validator = validator[type](...params);
    });
    schema[name] = validator;
  }
  return schema;
}
