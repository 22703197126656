import React from "react";
import { useForm } from "react-hook-form";

import CustomerHoc from "../../../Hoc/CustomerHoc";

import FormError from "../../common/Form/FormError";

import "./custLogin.scoped.css";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { forgotPasswordService } from "../../../services/all.service";

const schema = yup.object().shape({
  email_address: yup
    .string()
    .email()
    .required()
    .label("Email"),
});

const ForgotPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleForgot = async (data) => {
    console.log(data, "forgot");
    try {
      const password = await forgotPasswordService(data?.email_address);
      if (password?.status === 1) {
        reset();
      }
      console.log(password, "password");
    } catch (error) {}
  };
  console.log(errors, "errors");
  return (
    <div className="login-page">
      <div className="form">
        <div style={{ margin: "-30px 0px 50px -1px" }}>
          <div className="title">Forgot password</div> <hr />
        </div>
        {/* <Form fields={loginFields} /> */}
        <form className="login-form" onSubmit={handleSubmit(handleForgot)}>
          <label>Email</label>
          <input
            type="email"
            {...register("email_address")}
            // required
            placeholder="Email"
          />
          {errors?.email_address?.message && (
            <div className="float-left mb-2">
              <FormError msg={errors?.email_address?.message} />
            </div>
          )}

          <button style={{ textTransform: "unset !important" }}>Forgot</button>
          {/* <p className="message">
                <Link to="/customer/register">You don't have account?</Link>
            </p> */}
        </form>
      </div>
    </div>
  );
};

export default CustomerHoc(ForgotPassword);
