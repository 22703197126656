import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  accountType,
  createBreadCrumb,
  slugToTitle,
} from "../../../../helpers/helper";
import { userByUserIdService, deleteuser } from "../../../../services/all.service";
import AdminSidebar from "../../AdminSidebar";
import "./userInfo.scoped.css";
const { FreeUser, PremiumUser } = accountType;

const UserInfo = () => {
  const { userId, accountType } = useParams();
  const [userData, setUserData] = useState({});
  console.log(PremiumUser, "PremiumUser");
  const getUserDetails = async () => {
    try {
      const res = await userByUserIdService(
        userId,
        accountType === PremiumUser ? true : false
      );
      if (Array.isArray(res?.data) && res?.data[0]) {
        setUserData(res?.data[0]);
      }
    } catch (error) {}
  };

  const delete_user = async () => {
    try {
      const res = await deleteuser(
        userId,
        accountType === PremiumUser ? true : false
      );

      window.location.href = "/dashboard/accounts/view/free-user";

    } catch (error) {}
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  console.log(userData, "userData");
  const userName = `${
    userData?.user_first_name ? userData?.user_first_name : ""
  } ${userData?.user_last_name ? userData?.user_last_name : ""}`;
  return (
    <AdminSidebar
      // title={`Account > ${slugToTitle(accountType)} > ${userName} Account`}
      title={createBreadCrumb({
        currentPageTitle: `Account Profile`,
        linkData: [
          { title: "Accounts", href: "/dashboard/accounts" },
          {
            title: `${slugToTitle(accountType).replace("User", "")} Accounts`,
            href: `/dashboard/accounts/view/${accountType}`,
          },
        ],
      })}
    >
      <div className="container">
        <div className="card-deck mb-3 text-center">
          <div className="card mb-4 box-shadow">
            <div className="card-body">
              <div id="div1">
                <img
                  src={
                    userData?.user_photo
                      ? userData?.user_photo
                      : "/assets/img/noImage.jpg"
                  }
                  alt={userData?.user_emai}
                  width="100"
                  height="100"
                  style={{ borderRadius: "10px" }}
                />
                <div id="div2" className="pt-0">
                  <div>
                    <span className="text1">Name:</span>
                    <span className="text2">{userName}</span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <span>Log-in details:</span>
                    <span className="text2">{userData?.user_email}</span>
                  </div>
                  <div>
                    <span className="text1">Contact:</span>
                    <span className="text2">{`${userData?.user_country_code ||
                      ""} ${userData?.user_mobile}`}</span>
                  </div>
                  <div>
                    <span className="text1">Date Joined:</span>
                    <span className="text2">
                      {moment(userData?.user_joinig_date).format("LL")}
                    </span>
                  </div>
                  {accountType !== FreeUser && (
                    <div>
                      <span className="text1">Expiry:</span>
                      {userData?.user_subscription_expiry_date ? (
                        <span className="text2">
                          {/* 83 Day/s{" "} */}
                          {moment(userData?.user_subscription_expiry_date).diff(
                            moment(),
                            "days"
                          )}{" "}
                          Day/s{" "}
                          {moment(
                            userData?.user_subscription_expiry_date
                          ).format("ll")}
                        </span>
                      ) : (
                        "-"
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div id="div3">
                <span>
                  {accountType === PremiumUser ? (
                    <img
                      className="img-box"
                      id="img2"
                      src="/assets/img/diamond 2.png"
                      alt="towcar"
                    />
                  ) : accountType === FreeUser ? (
                    <img
                      className="img-box"
                      id="img2"
                      src="/assets/img/2. tickets.png"
                      alt="towcar"
                    />
                  ) : (
                    <img
                      className="img-box"
                      id="img2"
                      src="/assets/img/towcar.png"
                      alt="towcar"
                    />
                  )}
                </span>
                {/* <img id="img2" src="/assets/img/diamond 2.svg" /> */}
              </div>
              <div id="div4">
                <button
                  type="button"
                  className="btn btn-lg btn-block btn-primary"
                  onClick={delete_user}
                >
                  Delete Account
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminSidebar>
  );
};

export default UserInfo;
