import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getAllInvoices, getAllTrips } from "../../../../services/all.service";
import { appendScript } from "../../account/viewAccount";
import AdminSidebar from "../../AdminSidebar";
import "./viewnewcarosel.scoped.css";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import axios from 'axios';
import { toast } from "react-toastify";
import { History } from "../../../../helpers/history";
import { createBreadCrumb } from "../../../../helpers/helper";
const url = process.env.REACT_APP_BACKEND_URL;

const Viewnewcarosel = () => {
  const [invoiceData, setInvoiceData] = useState([]);
  const banner_id=useLocation()
  const [carousel,setcarousel]=useState([])
  const history=useHistory()

  useEffect(() => {
    if (invoiceData.length > 0) {
      appendScript(
        "https://cdn.datatables.net/1.10.9/js/jquery.dataTables.min.js"
      );
      appendScript("/assets/js/record3.js");
    }
  }, [invoiceData]);


  useEffect( () => {
    const Editdetails=async()=>{
    if (banner_id.state != undefined) {
      console.log('editable', banner_id.state.id)
      try {

        let jsond = { id: banner_id.state.id }
        console.log("image_url_1", jsond)
        let endpoint=banner_id.state.type!=undefined?"/superusers/get_banner_by_id_edit_preview":"/superusers/get_banner_for_preview"
        const response = await axios.post(url + endpoint, jsond);
        // let demodata=JSON.parse(response.data[0].)
        if (response.status = 1 && banner_id.state.type==undefined) {
        //  let demodata=JSON.parse(response.data[0])
        let demodata = response.data[0]
         setcarousel([demodata.mfl_banner_url])
        }
        else{
          let demodata=JSON.parse(response.data[0].mfl_is_edit_data)
          setcarousel([demodata.mfl_banner_url])
          
        }

      } catch (error) {
        // console.log(error, 'Error')
        if (error.response != undefined) {
          console.log('fail', error)

        }
        else {
          console.log(error)
        }
      }
    }}
    Editdetails()

  }, []);


  const Publishandler=async()=>{
    try {

      let jsond = { banner_id: banner_id.state.id }
      let endpoint=banner_id.state.type==undefined?"/superusers/publish_banner":"/superusers/update_banner_on_preview"
      const response = await axios.post(url + endpoint, jsond);
  
      if (response.status == 1) {
       console.log(response)
        toast.success('Banner Published')
        window.location.replace("/dashboard/marketplace")
      }

    } catch (error) {
      // console.log(error, 'Error')
      if (error.response != undefined) {
        console.log('fail', error)

      }
      else {
        console.log(error)
      }
    }
  }



  return (
    <AdminSidebar
    title={createBreadCrumb({
      currentPageTitle: `Publish Carousel`,
      title: "Add Carousel",
      href: "/dashboard/deals/addnewcarosel",
    })}
      childrenContainerClass="container-fluid flex-grow-1 container-p-y"
    >
      <div className="container">
        <div className="card-deck mb-3 text-center">
          <div className="card mb-4 box-shadow">
            <div className="card-body overflow-auto">
              <div className="row">
                <div id="div2">Carousel Banner Preview</div>
                <div className="actionBtns">
                  <button type="button" className="btn btn-primary" onClick={()=>History.push({pathname:"/dashboard/deals/addnewcarosel",state:{id:banner_id.state.id}})}>
                    Edit
                  </button>
                  <div className="">
                    <button type="button" onClick={()=>Publishandler()} className="btn btn-success">
                      Publish
                    </button>
                  </div>
                </div>
              </div>
              <br />
              <div className="previewBlock">
                <div className="">
                  <div className="imagePreivewBlock">
                    {/* Bootstrap Carousel */}
                    <div
                      id="carouselExampleIndicators"
                      className="carousel slide"
                      data-ride="carousel"
                    >
                      <ol className="carousel-indicators">
                      {carousel.map((data,i)=><li
                          data-target="#carouselExampleIndicators"
                          data-slide-to={i}
                          className="active"
                        />)}
                      </ol>
                      <div className="carousel-inner">
                        {carousel.map((data)=><div className="carousel-item active">
                          <img
                            className="d-block w-100"
                            style={{height: "13rem"}}
                            src={data}
                            alt="First slide"
                          />
                        </div>)}
                        {/* <div className="carousel-item">
                          <img
                            className="d-block w-100"
                            src="../../../assets/img/banner.jpg"
                            alt="Second slide"
                          />
                        </div>
                        <div className="carousel-item">
                          <img
                            className="d-block w-100"
                            src="../../../assets/img/banner.jpg"
                            alt="Third slide"
                          />
                        </div> */}
                      </div>
                    </div>
                    {/* End of Bootstrap Carousel */}
                  </div>
                </div>
                <div className="statsCardContainer">
                  <div className="statsCard">
                    <h5>Current Promotion</h5>
                    <h3>3</h3>
                  </div>
                  <div className="statsCard">
                    <h5>Current Promotions</h5>
                    <h3>426</h3>
                  </div>
                  <div className="statsCard">
                    <h5>Current Promotion</h5>
                    <h3>3</h3>
                  </div>
                  <div className="statsCard">
                    <h5>Current Promotion</h5>
                    <h3>3</h3>
                  </div>
                  <div className="statsCard">
                    <h5>Current Promotion</h5>
                    <h3>3</h3>
                  </div>
                </div>
              </div>
              <br />
            </div>
          </div>
        </div>
      </div>
    </AdminSidebar>
  );
};

export default Viewnewcarosel;
