import * as yup from "yup";
import { passwordRegExp } from "../../common/Form/Validation/validation.const";

export const step1Schema = yup.object().shape({
  contact_number: yup
    .string()
    .required()
    .min(8)
    .max(8)
    .label("Contact Number"),
  country_code: yup
    .string()
    .required()
    .label("Country Code"),
});

export const step1Schema2 = yup.object().shape({
  first: yup
    .string()
    .required()
    .label(""),
  second: yup
    .string()
    .required()
    .label(""),
  third: yup
    .string()
    .required()
    .label(""),
  fourth: yup
    .string()
    .required()
    .label(""),
  fifth: yup
    .string()
    .required()
    .label(""),
  sixth: yup
    .string()
    .required()
    .label(""),
});

export const step1Schema3 = yup.object().shape({
  first_name: yup
    .string()
    .required()
    .label("First Name"),
  last_name: yup
    .string()
    .required()
    .label("Last Name"),
  dob: yup
    .string()
    .required()
    .label("Date of birth"),
  gender: yup
    .string()
    .required()
    .label("Gender"),
  email: yup
    .string()
    .email()
    .required()
    .label("Email"),
  password: yup
    .string()
    .required()
    .matches(
      passwordRegExp,
      "Password should be 8 character, one capital letters, one special symbol and one number"
    )
    .label("Password"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
  vehicle_name: yup
    .string()
    .required()
    .label("Vehicle plate number"),
  vehicle_make: yup
    .string()
    .required()
    .label("Make"),
  vehicle_model: yup
    .string()
    .required()
    .label("Model"),
  cardNumber: yup
    .string()
    .min(16)
    .max(16)
    .required()
    .label("Card Number"),
  cardExpiry: yup
    .string()
    .min(5)
    .max(5)
    .required()
    .label("Card Expiry"),
  cvv: yup
    .string()
    .min(3)
    .max(3)
    .required()
    .label("Cvv"),
  cardHolderName: yup
    .string()
    .min(2)
    .required()
    .label("Card Holder Name"),
});

export const step1Schema3ForFreeUser = yup.object().shape({
  first_name: yup
    .string()
    .required()
    .label("First Name"),
  last_name: yup
    .string()
    .required()
    .label("Last Name"),
  dob: yup
    .string()
    .required()
    .label("Date of birth"),
  gender: yup
    .string()
    .required()
    .label("Gender"),
  email: yup
    .string()
    .email()
    .required()
    .label("Email"),
  password: yup
    .string()
    .required()
    .matches(
      passwordRegExp,
      "Password should be 8 character, one capital letters, one special symbol and one number"
    )
    .label("Password"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
  vehicle_name: yup
    .string()
    .required()
    .label("Vehicle plate number"),
  vehicle_make: yup
    .string()
    .required()
    .label("Make"),
  vehicle_model: yup
    .string()
    .required()
    .label("Model"),
  // cardNumber: yup
  //   .string()
  //   .min(16)
  //   .max(16)
  //   .label("Card Number"),
  // cardExpiry: yup
  //   .string()
  //   .min(4)
  //   .max(4)
  //   .label("Card Expiry"),
  // cvv: yup
  //   .string()
  //   .min(3)
  //   .max(3)
  //   .label("Cvv"),
  // cardHolderName: yup
  //   .string()
  //   .min(2)
  //   .label("Card Holder Name"),
});
