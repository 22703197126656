import React, { useState } from "react";
import Logo from "./img/logo.svg";
import { useForm } from "react-hook-form";
import "./login.scoped.css";
import { loginService } from "../../../services/all.service";
import { useDispatch, useSelector } from "react-redux";
import { History } from "../../../helpers/history";
import { loggedInUser } from "../../../redux/reducers/UserSlice";
import { Link } from "react-router-dom";

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const handleLogin = async (data) => {
    const res = await loginService({ ...data, logon_type: "SU" });
    if (res?.status === 1) {
      // History.push("/dashboard");
      window.location.href = "/dashboard";
      dispatch(loggedInUser(res));
    }
  };

  return (
    <div className="loginComponent">
      <div className="form" onSubmit={handleSubmit(handleLogin)}>
        <div>
          <img src={Logo} alt="logo" />
        </div>
        <div>
          <h4 className="etow-img" id="h4">
            ATA(SG) PTE.LTD
          </h4>
        </div>
        <div>
          <h4 className="etow-img">Admin Panel</h4>
        </div>
        <form
          style={{ boxSizing: "content-box", WebkitBoxSizing: "content-box" }}
        >
          <div>
            <label className="lab1">USER ID</label>
            <input
              type="text"
              {...register("email_address")}
              placeholder="ID"
              required
            />
          </div>
          <div>
            <label className="lab1">PASSWORD</label>

            <input
              type={passwordVisible ? "text" : "password"}
              id="id_password"
              placeholder="Password"
              required
              {...register("password")}
            />
            <span>
              <i
                className={`fas ${passwordVisible ? "fa-eye-slash" : "fa-eye"}`}
                id="togglePassword"
                onClick={() => {
                  setPasswordVisible(!passwordVisible);
                }}
              />
            </span>
            <Link to="/forgot-password">
              <p>Forgot password?</p>
            </Link>
          </div>
          <button>Log In</button>
        </form>
      </div>
    </div>
  );
};

export default Login;
