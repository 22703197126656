import { Redirect, Route, useHistory } from "react-router-dom";
import React, { useEffect } from "react";
import { getUserIdFromLocal } from "../context/AppGlobalContext";
import { handleLogout } from "./helper";
import { UserRoles } from "./Constant";
import { useSelector } from "react-redux";

const AuthRoute = ({ component: Component, routeType, ...rest }) => {
  const userId = getUserIdFromLocal();
  const authorized = userId ? true : false;
  const { role_id } = useSelector((state) => state.user.data);
  const { goBack, ...history } = useHistory();
  console.log(userId, routeType, authorized, "userId");
  useEffect(() => {
    if (routeType === "" && !authorized) {
      handleLogout();
    }
  }, []);

  // if (
  //   authorized &&
  //   rest?.path === "/customer/register" &&
  //   history.location.pathname === rest?.path
  // ) {
  //   goBack();
  // }

  if (routeType === "NoAuth") {
    return (
      <Route
        {...rest}
        render={(props) =>
          authorized === false ? (
            <Component {...props} />
          ) : rest?.path === "/customer/register" ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname:
                  role_id === UserRoles.Admin
                    ? "/dashboard"
                    : "/customer/dashboard",
                state: { from: props.location },
              }}
            />
          )
        }
      />
    );
  } else {
    return (
      <Route
        {...rest}
        render={(props) =>
          authorized === true ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: role_id === UserRoles.Admin ? "/superadmin" : "/",
                state: { from: props.location },
              }}
            />
          )
        }
      />
    );
  }
};

export default AuthRoute;
