export const createValidationObj = (type, param) => {
  return [
    {
      type: type,
      params: [...param],
    },
  ];
};

export const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

export const PreDefineValidations = (type) => {};

export const checkImageRequired = (message) => {
  const checkMinImage = createValidationObj("test", [
    "fileSize",
    message,
    (value) => {
      return value.length > 0;
    },
  ]);

  const checkMaxImage = createValidationObj("test", [
    "fileSize",
    "Maximum 3 file is allowed",
    (value) => {
      return value.length <= 3;
    },
  ]);
  const returnData = {
    validationType: "mixed",
    validations: [...checkMinImage, ...checkMaxImage],
  };
  return returnData;
};
