import { configureStore } from "@reduxjs/toolkit";
import {
  CustomerVehicleReducer,
  LoaderReducer,
  TempUserReducer,
  UserReducer,
} from "./reducers";
import marketplaceReducer from "./reducers/Marketplace";
import GetcategoryReducer from "./reducers/GetCategory";
import getCarouselReducer from "./reducers/carousel";

export const store = configureStore({
  reducer: {
    user: UserReducer,
    loader: LoaderReducer,
    customerVehicle: CustomerVehicleReducer,
    tempUser: TempUserReducer,
    marketplace:marketplaceReducer,
    getcategory:GetcategoryReducer,
    getcarousel:getCarouselReducer,
  },
});
