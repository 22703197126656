import React, { useRef, useState } from "react";
import AdminSidebar from "../AdminSidebar";
import { FormContainer } from "../../common";
import "../../common/Form/formContainer.scoped.css";
import { useParams } from "react-router-dom";
import { createBreadCrumb, slugToTitle } from "../../../helpers/helper";
import { useForm } from "react-hook-form";
import {
  addAccountService,
  uploadService,
} from "../../../services/all.service";
import { toast } from "react-toastify";
import { History } from "../../../helpers/history";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Form from "../../common/Form";
import CreateUserAccountFormSchema from "../../common/Form/Schema/CreateUserAccountFormSchema";
import countryCodeArray from "../../../helpers/countryCode";

const AddAccount = () => {
  const { accountType } = useParams();
  const companyLogoRef = useRef(null);
  const { register, handleSubmit } = useForm();
  const [companyLogo, setCompanyLogo] = useState(false);

  const handleAddAccount = async (inputData) => {
    try {
      if (companyLogo) {
        const res = await addAccountService({
          ...inputData,
          company_logo: companyLogo,
        });
        if (res?.status === 1) {
        }
        History.push(`/dashboard/accounts/view/${accountType}`);
      } else {
        toast.error("Company Logo Required!");
      }
    } catch (error) {}
  };

  const handleCompanyLogo = async (file) => {
    try {
      const res = await uploadService(file[0]);
      console.log(res, "res");
      if (res?.status === 1) {
        setCompanyLogo(res?.absolute_path);
      }
    } catch (error) {}
  };

  console.log(companyLogo, "companyLogo");
  return (
    <AdminSidebar
      title={createBreadCrumb({
        currentPageTitle: `Add ${slugToTitle(accountType)} Account`,
        title: "Accounts",
        href: "/dashboard/accounts",
      })}
    >
      <div>
        <br />

        <div id="div1" className="container">
          <div className="card-deck mb-3 text-center">
            <div className="card mb-4 box-shadow">
              {/* <form onSubmit={handleSubmit(handleAddAccount)}> */}
              <Form
                fields={CreateUserAccountFormSchema}
                formTitle="Create New Account"
                formSubTitle="eTow Partner Account"
                btnTitle="Create Account"
                onSubmit={handleAddAccount}
                otherFields={
                  <>
                    <label htmlFor="explicit-label-name">
                      <div className="div4">Company Logo</div>
                    </label>
                    {/* <div class="input12"> */}
                    <div
                      className="card12"
                      onClick={() => {
                        companyLogoRef.current.click();
                      }}
                    >
                      <div>
                        <button type="button" className="btn12">
                          Select Files
                        </button>
                      </div>
                      <div id="div5">
                        <span>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <img
                            className="img-box"
                            id="img2"
                            src="/assets/img/cloud_upload.png"
                            alt="cloud_upload"
                          />
                        </span>
                        &nbsp;&nbsp;Drag files here...
                      </div>
                    </div>
                    <input
                      type="file"
                      className="d-none"
                      ref={companyLogoRef}
                      onChange={(e) => {
                        handleCompanyLogo(e.target.files);
                      }}
                    />
                    {/* {companyLogo && (
                      <img
                        src={companyLogo}
                        alt="companyLogo"
                        className="img mt-2"
                        style={{ width: "50%" }}
                      />
                    )} */}
                  </>
                }
              />
              {/* <div id="div2" className="card-body">
                <div id="div3">
                  <h1>Create New Account</h1>
                  <h5>eTow Partner Account</h5>
                </div>

                <label htmlFor="explicit-label-name">
                  <div className="div4">Company Name</div>
                </label>
                <input
                  required
                  type="text"
                  id="explicit-label-name"
                  {...register("company_name")}
                  className="input12"
                />

                <label htmlFor="explicit-label-name">
                  <div className="div4">Email Address</div>
                </label>
                <input
                  required
                  type="email"
                  id="explicit-label-name"
                  className="input12"
                  {...register("email_address")}
                />
                <label htmlFor="explicit-label-name">
                  <div className="div4">Password</div>
                </label>
                <input
                  required
                  type="password"
                  id="explicit-label-name"
                  className="input12"
                  {...register("password")}
                />
                <label htmlFor="explicit-label-name">
                  <div className="div4">6 Digit Pin</div>{" "}
                </label>
                <input
                  required
                  type="number"
                  id="explicit-label-name"
                  {...register("admiinpin")}
                  className="input12"
                />

                <label htmlFor="explicit-label-name">
                  <div className="div4">Country Code</div>{" "}
                </label>
              
                <input
                  required
                  type="number"
                  id="explicit-label-name"
                  {...register("country_code")}
                  className="input12"
                />
                <label htmlFor="explicit-label-name">
                  <div className="div4">Mobile Number</div>{" "}
                </label>
                <input
                  required
                  type="text"
                  id="explicit-label-name"
                  {...register("contact_number")}
                  className="input12"
                  maxlength="10"
                  pattern="\d{10}"
                  title="Please enter 10 digits mobile number"
                />
                <label htmlFor="explicit-label-name">
                  <div className="div4">Company Logo</div>
                </label>
          
                <div
                  className="card12"
                  onClick={() => {
                    companyLogoRef.current.click();
                  }}
                >
                  <div>
                    <button type="button" className="btn12">
                      Select Files
                    </button>
                  </div>
                  <div id="div5">
                    <span>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <img
                        className="img-box"
                        id="img2"
                        src="/assets/img/cloud_upload.png"
                        alt="cloud_upload"
                      />
                    </span>
                    &nbsp;&nbsp;Drag files here...
                  </div>
                </div>
                <input
                  type="file"
                  className="d-none"
                  ref={companyLogoRef}
                  onChange={(e) => {
                    handleCompanyLogo(e.target.files);
                  }}
                />
                {/* {companyLogo && (
                      <img
                        src={companyLogo}
                        alt="companyLogo"
                        className="img mt-2"
                        style={{ width: "50%" }}
                      />
                    )} 
              </div>
              <br />
              <div>
                <button
                  type="submit"
                  className="btnn1"
                  style={{ cursor: "pointer" }}
                >
                  Create Account
                </button>
              </div> */}
              {/* </form> */}

              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
    </AdminSidebar>
  );
};

export default AddAccount;
