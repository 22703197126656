import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isShowLoader: false,
};

export const LoaderSlice = createSlice({
  name: "Loader",
  initialState,
  reducers: {
    showLoader: (state) => {
      state.isShowLoader = true;
    },
    hideLoader: (state) => {
      state.isShowLoader = false;
    },
  },
});

export const { showLoader, hideLoader } = LoaderSlice.actions;

export default LoaderSlice.reducer;
