import { ForgotPassword, ResetPassword } from "../components/customer";
import {
  Account,
  AddAccount,
  AddModel,
  MakeModel,
  Overview,
  Pricing,
  PricingPlan,
  Promotion,
  Records,
  Setting,
  UserInfo,
  ViewAccount,
  MarketPlace,
  Deals,
  Addnewcarosel,
  Viewnewcarosel,
  Mobilenewcarosel,
} from "../components/dashboard";
import { Login } from "../components/public";
import { UserRoles } from "../helpers/Constant";
import { Icon, InlineIcon } from "@iconify/react";
import alertCircle from "@iconify/icons-ion/alert-circle";

export const authUserRoutes = [
  {
    path: "/dashboard",
    Component: Overview,
    title: "Overview",
    routeCompType: "RoleBasedRoute",
    iconClass: "ant-design:pie-chart-outlined",
    role: UserRoles.Admin,
  },

  {
    path: "/dashboard/accounts",
    Component: Account,
    title: "Accounts",
    routeCompType: "RoleBasedRoute",

    iconClass: "mdi:accounts-group-outline",
    role: UserRoles.Admin,
  },
  {
    path: "/dashboard/records",
    Component: Records,
    title: "Records",
    routeCompType: "RoleBasedRoute",
    userRole: 1,
    iconClass: "ant-design:container-outlined",
    role: UserRoles.Admin,
  },

  {
    path: "/dashboard/pricing",
    Component: Pricing,
    title: "Pricing",
    routeCompType: "RoleBasedRoute",
    userRole: 1,
    iconClass: "pepicons-pop:dollar",
    role: UserRoles.Admin,
  },

  {
    path: "/dashboard/make-model",
    Component: MakeModel,
    title: "Make/Model",
    routeCompType: "RoleBasedRoute",
    userRole: 1,
    iconClass: "mdi:car",
    role: UserRoles.Admin,
  },

  {
    path: "/dashboard/promotion",
    Component: Promotion,
    title: "Promotions",
    routeCompType: "RoleBasedRoute",
    userRole: 1,
    iconClass: "ep:promotion",
    role: UserRoles.Admin,
  },
  {
    path: "/dashboard/marketplace",
    Component: MarketPlace,
    title: "Deals",
    routeCompType: "RoleBasedRoute",
    userRole: 1,
    iconClass: "mdi:deal-outline",
    role: UserRoles.Admin,
  },
  //  {
  //    path: "/dashboard/marketplace/deals",
  //    Component: Deals,
  //    title: "Add new Deals",
  //    routeCompType: "RoleBasedRoute",
  //    userRole: 1,
  //    iconClass: "icon-grid",
  //    role: UserRoles.Admin,
  //  },
];

export const otherRoutes = [
  {
    path: "/superadmin",
    Component: Login,
    title: "Dashboard",
    routeCompType: "AuthRoute",
    iconClass: "fas fa-chart-line",
    routeType: "NoAuth",
  },

  {
    path: "/forgot-password",
    Component: ForgotPassword,
    title: "Dashboard",
    routeCompType: "AuthRoute",
    iconClass: "fas fa-chart-line",
    routeType: "NoAuth",
  },

  {
    path: "/reset-password",
    Component: ResetPassword,
    title: "Dashboard",
    routeCompType: "AuthRoute",
    iconClass: "fas fa-chart-line",
    routeType: "NoAuth",
  },

  {
    path: "/dashboard/accounts/add/:accountType",
    Component: AddAccount,
    title: "Dashboard",
    routeCompType: "RoleBasedRoute",
    iconClass: "fas fa-chart-line",
    role: UserRoles.Admin,
  },

  {
    path: "/dashboard/accounts/view/:accountType",
    Component: ViewAccount,
    title: "Dashboard",
    routeCompType: "RoleBasedRoute",
    iconClass: "fas fa-chart-line",
    role: UserRoles.Admin,
  },
  {
    path: "/dashboard/make-model/:wheelerType",
    Component: AddModel,
    title: "Dashboard",
    routeCompType: "RoleBasedRoute",
    iconClass: "fas fa-chart-line",
    role: UserRoles.Admin,
  },

  {
    path: "/dashboard/pricing/:pricingType",
    Component: PricingPlan,
    title: "Dashboard",
    routeCompType: "RoleBasedRoute",
    iconClass: "fas fa-chart-line",
    role: UserRoles.Admin,
  },

  {
    path: "/dashboard/setting",
    Component: Setting,
    title: "Dashboard",
    routeCompType: "RoleBasedRoute",
    iconClass: "fas fa-chart-line",
    role: UserRoles.Admin,
  },
  {
    path: "/dashboard/accounts/:accountType/:userId",
    Component: UserInfo,
    title: "Dashboard",
    routeCompType: "RoleBasedRoute",
    iconClass: "fas fa-chart-line",
    role: UserRoles.Admin,
  },
  {
    path: "/dashboard/deals/addnewcarosel",
    Component: Addnewcarosel,
    title: "Dashboard",
    routeCompType: "RoleBasedRoute",
    iconClass: "fas fa-chart-line",
    role: UserRoles.Admin,
  },
  {
    path: "/dashboard/deals/viewnewcarosel",
    Component: Viewnewcarosel,
    title: "Dashboard",
    routeCompType: "RoleBasedRoute",
    iconClass: "fas fa-chart-line",
    role: UserRoles.Admin,
  },
  {
    path: "/dashboard/deals/mobilenewcarosel",
    Component: Mobilenewcarosel,
    title: "Dashboard",
    routeCompType: "RoleBasedRoute",
    iconClass: "fas fa-chart-line",
    role: UserRoles.Admin,
  },
  {
    path: "/dashboard/deals/",
    Component: Deals,
    title: "Dashboard",
    routeCompType: "RoleBasedRoute",
    iconClass: "fas fa-chart-line",
    role: UserRoles.Admin,
  },
];

export const AuthRoutes = [...authUserRoutes, ...otherRoutes];
