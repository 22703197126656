import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { getUserIdFromLocal } from "../../../../context/AppGlobalContext";
import {
  checkVehicleTypeUsingVehicleName,
  redirectWhenCustomerVehicleNotExist,
} from "../../../../helpers/helper";
import { History } from "../../../../helpers/history";
import { centToDollar } from "../../../../helpers/stripe";
import CustomerHoc from "../../../../Hoc/CustomerHoc";
import { userByUserIdService } from "../../../../services/all.service";
import {
  createSubscriptionService,
  getAllPlanService,
  getCustomerAllCards,
} from "../../../../services/customer.service";
import "./planList.scoped.css";

const PlanList = () => {
  const [planList, setPlanList] = useState([]);
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [currentPlanId, setCurrentPlanId] = useState(false);
  const customerVehicle = useSelector((state) => state.customerVehicle.data);

  const getImageSource = (productName) => {
    if (productName === "Bike Premium") {
      return "/assets/customerImg/card_premium.JPG";
    } else if (productName === "Car Premium") {
      return "/assets/customerImg/bike_premium.JPG";
    }
    return "/assets/customerImg/default.jpg";
  };

  const getPlanList = async () => {
    try {
      const res = await getAllPlanService();
      if (_.isArray(res?.data?.data)) {
        const newData = [];
        res?.data?.data.forEach((item) => {
          const planName = item?.product?.name.toLowerCase();
          const vehicleType = checkVehicleTypeUsingVehicleName(
            customerVehicle?.vehicle_name
          );
          if (
            (planName.includes("bike") && vehicleType === 2) ||
            (planName.includes("car") && vehicleType === 4)
          ) {
            newData.push(item);
          }
        });
        setPlanList(newData);
      }
    } catch (error) {
      console.error("Error fetching plan list:", error);
    }
  };

  useEffect(() => {
    getPlanList();
  }, []);

  const createSubscription = async (priceId) => {
    try {
      const { subscriptionId, clientSecret } = await createSubscriptionService(
        priceId,
        customerVehicle
      );
      setSubscriptionData({ subscriptionId, clientSecret });
      setCurrentPlanId(priceId);
    } catch (error) {
      console.error("Error creating subscription:", error);
    }
  };

  if (subscriptionData && currentPlanId) {
    return (
      <Redirect
        to={{
          pathname: `/customer/buy/subscription/${currentPlanId}`,
          state: subscriptionData,
        }}
      />
    );
  }

  redirectWhenCustomerVehicleNotExist(customerVehicle);

  return (
    <div className="mt-5">
      {planList.map((item) => (
        <div className="container mb-5" key={item.id}>
          <div className="card1">
            <div className="title1">
              <div>Type of Membership</div>
              <div className="text-center w-100">
              <div className="p12">
                {item?.product?.name} 
                
                
              </div>
              <strong><span style={{ fontSize: '23px' }}>${centToDollar(item?.unit_amount_decimal)} / {item.recurring.interval=='month'?"mth":"yr"}</span></strong>
                
                
                </div>
                <img
                  src={getImageSource(item?.product?.name)}
                  alt={item?.product?.name}
                  style={{ width: "180px", height: "150px" }}
                />
            </div>
            <div className="info_data">{/* Additional content */}</div>
            {item?.product?.description && (
              <div style={{ margin: "10px 0px 0px 23px" }}>
                <p>Description</p>

                <p>{item?.product?.description}</p>
              </div>
            )}

            <div className="btn1">
              <div
                className="div1"
                onClick={() => {
                  setCurrentPlanId(item?.id);
                  createSubscription(item?.id);
                }}
              >
                Select
              </div>
            </div>
            <br />
          </div>
        </div>
      ))}

      {/* Displaying Free Membership Card */}
      <div className="container mb-5">
        <div className="card1">
          <div className="title1">
            <div>Type of Membership</div>
            <p className="p12">
              Free Membership ($ 0)

            </p>
          </div>
          <div className="info_data">
            {/* Additional content */}
          </div>
          <div style={{ margin: "10px 0px 0px 23px" }}>
            <p>Description</p>
            <p>Start with our free plan</p>
          </div>

          <div className="btn1">
            <div
              className="div1"
              onClick={() => {
                History.push("/customer/free/summary");
              }}
            >
              Select
            </div>
          </div>
          <br />
        </div>
      </div>
    </div>
  );
};

export default CustomerHoc(PlanList);
